import {
  Box,
  Collapse,
  FormLabel,
  IconButton,
  MenuItem,
  Toolbar,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import { NavigationItem } from 'app/shared-components/layout/navbar/NavBar';
import { useNavigationState } from 'app/store/navigationSlice';
import theme from 'app/theme';
import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function makeId(id: string) {
  return `nav_${id}`;
}

type NavButtonProps = NavigationItem & {
  currentPage: string | null;
  drawerOpen: boolean;
};

function NavButton({
  id,
  href,
  label,
  IconComponent,
  currentPage,
  drawerOpen,
  tabs,
  hasAccess,
}: NavButtonProps): JSX.Element {
  const navigate = useNavigate();
  const isActive = currentPage?.startsWith(href);
  const anchorEl = React.useRef<null | HTMLElement>(null);

  const navigationTabs = useMemo(() => {
    return tabs?.filter((t) => t.hasAccess || t.hasAccess === undefined);
  }, [tabs]);

  // Styled Tooltip from MUI Tooltip API
  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      opacity: 0,
      display: tabs === undefined || !navigationTabs?.length ? 'none' : 'block',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  return (
    <>
      {(hasAccess || hasAccess === undefined) && (
        <Box
          className={drawerOpen ? 'drawerOpen' : undefined}
          ref={anchorEl}
          sx={{
            display: hasAccess || hasAccess === undefined ? 'flex' : 'none',
            flexDirection: 'column',
            alignItems: 'center',
            height: 84,
            '& label': {
              opacity: 0,
            },
            '&:hover label': {
              opacity: 1,
            },
            '&.drawerOpen:hover label': {
              opacity: 0,
            },
          }}
        >
          <LightTooltip
            title={
              <>
                {navigationTabs?.map((item) => (
                  <MenuItem
                    key={item.href}
                    onClick={() => {
                      navigate(item.href);
                    }}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </>
            }
            placement="right"
          >
            <IconButton
              id={makeId(id)}
              aria-label={label}
              size="medium"
              sx={{
                color: isActive ? 'white' : 'primary.main',
                backgroundColor: isActive ? 'primary.main' : 'inherit',
                '&:hover': {
                  color: isActive ? 'white' : 'primary.main',
                  backgroundColor: isActive ? 'primary.main' : theme.palette.grey[100],
                },
                mx: 1,
                p: 2,
                transition: 'color 300ms, background-color 300ms',
              }}
              onClick={() => {
                navigate(href);
              }}
            >
              <IconComponent />
            </IconButton>
          </LightTooltip>
          <FormLabel
            htmlFor={makeId(id)}
            sx={{
              cursor: 'pointer',
              transition: 'opacity 300ms',
              textAlign: 'center',
              lineHeight: '1em',
            }}
          >
            <Typography variant="xsmall">{label}</Typography>
          </FormLabel>
        </Box>
      )}
    </>
  );
}

function NavLabel({ id, label, hasAccess }: NavigationItem): JSX.Element {
  return (
    <Box>
      {(hasAccess || hasAccess === undefined) && (
        <FormLabel
          sx={{
            height: 84,
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            display: hasAccess || hasAccess === undefined ? 'flex' : 'none',
          }}
          htmlFor={makeId(id)}
        >
          <Typography variant="large">{label}</Typography>
        </FormLabel>
      )}
    </Box>
  );
}

interface DesktopNavBarProps {
  navigationElements: Array<NavigationItem>;
}

function DesktopNavBar({ navigationElements }: DesktopNavBarProps): JSX.Element {
  const { drawerOpen } = useNavigationState();
  const { pathname } = useLocation();

  return (
    <Box
      component="nav"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        mt: 9,
      }}
    >
      <Toolbar disableGutters sx={{ flexDirection: 'column', width: 72, mt: 2 }}>
        {navigationElements.map((item: NavigationItem) => (
          <React.Fragment key={item.id}>
            <NavButton currentPage={pathname} drawerOpen={drawerOpen} {...item} />
          </React.Fragment>
        ))}
      </Toolbar>
      <Collapse in={drawerOpen} orientation="horizontal">
        <Toolbar
          disableGutters
          sx={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            mt: 4,
            mr: 8,
          }}
        >
          {navigationElements.map((item) => (
            <NavLabel key={item.id} {...item} />
          ))}
        </Toolbar>
      </Collapse>
    </Box>
  );
}

export default DesktopNavBar;
