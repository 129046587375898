import { Box, FormControl, MenuItem, Select, Tooltip } from '@mui/material';
import { selectUserCompanies, setSelectedPaidolId } from 'app/store/userCompaniesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { resetPaymentCardsSlice } from 'app/pages/store/paymentCardsSlice';
function CompanySwitcher({ sx = {} }) {
  const { isUserOnboarded, paidolUsers, selectedPaidolId } = useSelector(selectUserCompanies);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    dispatch(setSelectedPaidolId(event.target.value));
    dispatch(resetPaymentCardsSlice());
  };

  if (paidolUsers.length === 1) {
    return null;
  }

  return (
    <Box sx={sx}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        open={selectedPaidolId === ''}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title="Please select a company"
        arrow
      >
        <div>
          <FormControl fullWidth size="small">
            {isUserOnboarded && (
              <Select
                sx={{ backgroundColor: 'white' }}
                displayEmpty
                variant="outlined"
                labelId="demo-simple-select-label"
                // label="Company"
                id="demo-simple-select"
                value={selectedPaidolId}
                onChange={handleChange}
                defaultValue=""
              >
                {!selectedPaidolId && (
                  <MenuItem key="empty value" value="">
                    <em>Select Company</em>
                  </MenuItem>
                )}
                {paidolUsers.map((paidolUser) => (
                  <MenuItem key={paidolUser.paidol.id} value={paidolUser.paidol.id}>
                    {paidolUser.paidol.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </div>
      </Tooltip>
    </Box>
  );
}

export default CompanySwitcher;
