import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { RootState } from 'app/store/rootReducer';
import {
  ListBudgetSpendByMonthAndCategoryByAmountQuery,
  ListBudgetSpendByMonthAndMerchantByAmountQuery,
} from 'API';
import {
  listBudgetSpendByMonthAndMerchantByAmount,
  listBudgetSpendByMonthAndCategoryByAmount,
} from 'graphql/queries';
import moment from 'moment';

export interface TopMerchantsWidgetState {
  merchants: any[];
  merchantCategories: Array<{
    paidolId: string;
    authPaidolId: string;
    yearAndMonth: string;
    category: string;
    amount?: number | null;
    transactionCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
}

export const initialState: TopMerchantsWidgetState = {
  merchants: [],
  merchantCategories: [],
};

export const getTopMerchants = createAsyncThunk(
  'cards/topMerchantsWidget/getTopMerchants',
  async (paidolId: string) => {
    return (
      API.graphql(
        graphqlOperation(listBudgetSpendByMonthAndMerchantByAmount, {
          paidolId,
          yearAndMonthAmount: {
            beginsWith: {
              yearAndMonth: moment().utc().format('YYYY-MM'),
            },
          },
          limit: 5,
          sortDirection: 'DESC',
        })
      ) as Promise<GraphQLResult<ListBudgetSpendByMonthAndMerchantByAmountQuery>>
    ).then((results) => results?.data?.listBudgetSpendByMonthAndMerchantByAmount?.items || []);
  }
);

export const getTopMerchantCategories = createAsyncThunk(
  'cards/topMerchantsWidget/getTopMerchantCategories',
  async (paidolId: string) => {
    return (
      API.graphql(
        graphqlOperation(listBudgetSpendByMonthAndCategoryByAmount, {
          paidolId,
          yearAndMonthAmount: {
            beginsWith: {
              yearAndMonth: moment().utc().format('YYYY-MM'),
            },
          },
          limit: 5,
          sortDirection: 'DESC',
        })
      ) as Promise<GraphQLResult<ListBudgetSpendByMonthAndCategoryByAmountQuery>>
    ).then((results) => results?.data?.listBudgetSpendByMonthAndCategoryByAmount?.items || []);
  }
);

const topMerchantsWidgetSlice = createSlice({
  name: 'cards/topMerchantsWidget',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTopMerchants.fulfilled, (state, action) => {
      state.merchants = action.payload;
    });
    builder.addCase(getTopMerchantCategories.fulfilled, (state, action) => {
      state.merchantCategories = action.payload;
    });
  },
});

export const selectTopMerchantsWidgetSlice = (state: RootState): TopMerchantsWidgetState =>
  state?.cards?.topMerchantsWidget ?? initialState;

export default topMerchantsWidgetSlice.reducer;
