import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormControl, InputLabel, MenuItem, Select, TextField, useTheme } from '@mui/material';
import { Role } from 'API';
import { addUser } from 'app/pages/team/store/usersSlice';
import ButtonContainer from 'app/shared-components/button-container/button-container';
import { useAppDispatch, useAppSelector } from 'app/store';
import { selectUserCompanies } from 'app/store/userCompaniesSlice';
import { useCallback, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isErrorLike } from 'util/serializeError';
import * as yup from 'yup';

/**
 * Form Validation Schema
 **/
const schema = yup.object({
  email: yup.string().required('You must enter a email').email('You must enter a valid email'),
  roles: yup.string().required(),
});

type FormValues = yup.InferType<typeof schema>;
interface AddUserProps {
  onClose?: () => void;
  renderButtons: (formProps: {
    isLoading: boolean;
    isValid: boolean;
    addPaidolUser: () => void;
  }) => React.ReactNode;
}

function AddUserForm({ onClose, renderButtons }: AddUserProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      roles: Role.CONTRIBUTOR,
    },
    resolver: yupResolver(schema),
  });
  const { control, formState, getValues, setError } = methods;
  const { errors, isValid } = formState;
  const [isLoading, setIsLoading] = useState(false);

  const { selectedPaidolId: selectedCompany } = useAppSelector(selectUserCompanies);

  const addPaidolUser = useCallback(() => {
    setIsLoading(true);
    dispatch(addUser({ ...getValues(), selectedCompany })).then((res) => {
      setIsLoading(false);
      if (isErrorLike(res?.payload)) {
        setError('email', { type: 'manual', message: res?.payload?.message });
      } else if (onClose) {
        onClose();
      }
    });
  }, [dispatch, getValues, onClose, selectedCompany, setError]);

  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '20px',
          border: '2px solid',
          borderColor: theme.palette.primary.lighter,
          borderRadius: '16px',
          minWidth: '238px',
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="email"
                error={!!errors.email}
                helperText={errors?.email?.message}
                label="Email"
                id="email"
                variant="outlined"
                required
                fullWidth
              />
            )}
          />
        </Box>
        <Controller
          name="roles"
          control={control}
          render={({ field }) => (
            <FormControl>
              <InputLabel id="role-label">{t('role')}</InputLabel>
              <Select {...field} id="roles" labelId="role-label" label="Role" fullWidth>
                <MenuItem value={Role.CONTRIBUTOR}>{t('contributor')}</MenuItem>
                <MenuItem value={Role.ADMINISTRATOR}>{t('administrator')}</MenuItem>
                <MenuItem value={Role.CARDHOLDER}>{t('cardholder')}</MenuItem>
              </Select>
            </FormControl>
          )}
        />
      </Box>
      <ButtonContainer>{renderButtons({ isLoading, isValid, addPaidolUser })}</ButtonContainer>
    </FormProvider>
  );
}

export default AddUserForm;
