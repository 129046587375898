import { createSvgIcon } from '@mui/material';

const PayablesIcon = createSvgIcon(
  <path
    d="M18.5946857,7.2 L11.3571429,0 L4.11963857,7.2 L8.14285714,7.2 L8.14285714,10.8 L14.5714286,10.8 L14.5714286,7.2 L18.5946857,7.2 Z M3,21.6 C3,22.92552 4.15126714,24 5.57142857,24 L17.1428571,24 C18.5630571,24 19.7142857,22.92552 19.7142857,21.6 L19.7142857,20.4 L17.1428571,20.4 C15.7226571,20.4 14.5714286,19.32552 14.5714286,18 C14.5714286,16.67448 15.7226571,15.6 17.1428571,15.6 L19.7142857,15.6 L19.7142857,14.4 C19.7142857,13.07448 18.5630571,12 17.1428571,12 L5.57142857,12 C4.15126714,12 3,13.07448 3,14.4 L3,21.6 Z M17.1428571,19.2 C16.4327571,19.2 15.8571429,18.66276 15.8571429,18 C15.8571429,17.33724 16.4327571,16.8 17.1428571,16.8 L21,16.8 L21,19.2 L17.1428571,19.2 Z"
    id="Shape"
  ></path>,
  'Payables'
);

export default PayablesIcon;
