import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Grid, Paper, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import {
  selectReviewOnboardSlice,
  issueFinancialAccountForApplication,
  selectIssueFinancialAccountForApplicationLoading,
} from 'app/pages/store/reviewOnboardSlice';
import { useAppSelector, useAppDispatch } from 'app/store';
import { ReactComponent as PCLady } from 'assets/PurchaseCardLady.svg';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

export interface PermissionProps {
  onSubmitApplication: () => void;
  isButtonDisabled: boolean;
}

function Permission(): JSX.Element {
  const dispatch = useAppDispatch();
  const { integration } = useAppSelector(selectReviewOnboardSlice);
  const isWaitingOnApplication = Boolean(!integration?.isApplicationAccepted);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const onSubmitFinancialAccountForApplication = () => {
    if (!integration) return;

    const { accountHolderCardProductApplicationId: applicationId, legalBusinessName: name } = integration;

    if (applicationId && name) {
      setIsButtonDisabled(true);
      dispatch(
        issueFinancialAccountForApplication({
          integration,
          applicationId,
          name,
        })
      )
        .then(() => {
          enqueueSnackbar('Financial account issued successfully', { variant: 'success' });
        })
        .catch((err) => {
          enqueueSnackbar('An error has ocurred: ' + err, { variant: 'error' });
          setIsButtonDisabled(false);
        });
    }
  };

  return (
    <Box>
      <Typography
        variant="h1"
        sx={{
          mt: 2,
          mb: 1,
          color: theme.palette.primary.main,
          mx: 2,
        }}
      >
        Permission to open a financial account
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={5}>
          <Box
            sx={{
              paddingTop: 20,
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            }}
          >
            <PCLady />
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Box
            sx={{
              paddingTop: 20,
              flexDirection: 'column',
            }}
          >
            <Paper
              elevation={4}
              style={{
                padding: 12,
                display: 'stack',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                width: '300px',
              }}
            >
              {isWaitingOnApplication && <PlaceholderText />}
              {!isWaitingOnApplication && (
                <ApplicationForm
                  onSubmitApplication={onSubmitFinancialAccountForApplication}
                  isButtonDisabled={isButtonDisabled}
                />
              )}
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

function PlaceholderText(): JSX.Element {
  const theme = useTheme();

  return (
    <>
      <Typography
        variant="h3"
        sx={{
          m: 2,
          color: theme.palette.primary.main,
        }}
      >
        We're working on it!
      </Typography>
      <Box
        sx={{
          m: 2,
        }}
      >
        <Typography variant="large">
          We're reviewing your application. Please check back later for updates.
        </Typography>
      </Box>
    </>
  );
}

function ApplicationForm({ onSubmitApplication, isButtonDisabled }: PermissionProps): JSX.Element {
  const isLoading = useAppSelector(selectIssueFinancialAccountForApplicationLoading);
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Typography
        variant="h3"
        sx={{
          m: 2,
          color: theme.palette.primary.main,
        }}
      >
        Purchase Cards
      </Typography>
      <Box
        sx={{
          m: 2,
        }}
      >
        <Typography variant="large">
          In order to begin creating purchase cards, we need your permission to open a financial account in
          your name.
        </Typography>
      </Box>
      <Box sx={{ m: 2 }}>
        <Typography variant="large">Is that okay?</Typography>
      </Box>
      <Box
        sx={{
          m: 2,
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
        }}
      >
        <LoadingButton
          disabled={isButtonDisabled}
          loading={isLoading}
          variant="contained"
          onClick={() => {
            onSubmitApplication();
          }}
        >
          {t('openFinancialAccount')}
        </LoadingButton>
      </Box>
    </>
  );
}

export default Permission;
