import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import defaultColors from 'app/theme/defaultColors';

export const StyledContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  background: defaultColors.lightGrayColor,
  minWidth: '100%',
  minHeight: '194px',

  '& > *': {
    flex: 1,
    marginLeft: '24px',
  },
});
