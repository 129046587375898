import { GraphQLResult } from '@aws-amplify/api-graphql';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { FeatureFlag, ListFeatureFlagsQuery } from 'API';
import type { RootState } from 'app/store/rootReducer';
import { API, graphqlOperation } from 'aws-amplify';
import { listFeatureFlags } from 'graphql/queries';
import logger from 'util/logging';

export type FeatureFlagsValue = boolean | 'speedster' | 'desktop' | 'mobile';
export type FeatureFlagsDict = Record<string, FeatureFlagsValue>;

export interface FeatureFlagsState {
  areFeatureFlagsLoaded: boolean;
  flags: { [key: string]: any };
}

export const initialState: FeatureFlagsState = {
  areFeatureFlagsLoaded: false,
  flags: {},
};

export const getRemoteFlags = createAsyncThunk<FeatureFlag[], string>(
  'featureFlags/getRemoteFlags',
  async (email) => {
    return (
      API.graphql(
        graphqlOperation(listFeatureFlags, {
          email,
        })
      ) as Promise<GraphQLResult<ListFeatureFlagsQuery>>
    ).then((response) => {
      return (response?.data?.listFeatureFlags ?? []).map((item) => ({
        ...item,
        value: item.value === 'string' ? JSON.parse(item.value) : item.value,
      }));
    });
  }
);

export const parseConfigValue = (value: string | boolean): FeatureFlagsValue => {
  switch (value) {
    case 'true':
    case 'TRUE':
    case true:
      return true;
    case 'false':
    case 'FALSE':
    case false:
      return false;
    case 'speedster':
    case 'desktop':
    case 'mobile':
      return value;
    default:
      logger().error(`Unknown feature flag value: ${value}; assuming FALSE`);
      return false;
  }
};

const featureFlagsSlice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {
    setFlagsLoaded(state, action) {
      state.areFeatureFlagsLoaded = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRemoteFlags.fulfilled, (state, action) => {
      action.payload.forEach((item) => {
        const parsedValue = typeof item.value === 'string' ? JSON.parse(item.value) : item.value;
        state.flags[item.name] = parsedValue;
      });
    });
  },
});

export const { setFlagsLoaded } = featureFlagsSlice.actions;

export const selectFeatureFlags = (state: RootState) => state.featureFlags as FeatureFlagsState;

export default featureFlagsSlice.reducer;
