import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import { HighnoteProductType, Role } from 'API';
import { selectReviewOnboardSlice } from 'app/pages/store/reviewOnboardSlice';
import { useIsEnabled } from 'app/shared-components/feature-flags/FeatureFlagProvider';
import PayablesIcon from 'app/shared-components/icons/PayablesIcon';
import { NavigationItem } from 'app/shared-components/layout/navbar/NavBar';
import { useAppSelector } from 'app/store';
import { selectUserCompanies } from 'app/store/userCompaniesSlice';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useNavigationMenu = (filterById?: string) => {
  const {
    'cardsFlags.showPaymentCards': showPaymentCards,
    'reimbursementsFlags.showReimbursements': showReimbursements,
    'walletFlags.isWalletEnabled': showWallet,
    'firstRunStateFlags.isHomeTabEnabled': showHomeTab,
  } = useIsEnabled([
    { featureName: 'cardsFlags', flagName: 'showPaymentCards' },
    { featureName: 'reimbursementsFlags', flagName: 'showReimbursements' },
    { featureName: 'walletFlags', flagName: 'isWalletEnabled' },
    { featureName: 'firstRunStateFlags', flagName: 'isHomeTabEnabled' },
  ]);

  const { selectedPaidol, userRole } = useAppSelector(selectUserCompanies);
  const isReimbursementsEnabled = !!selectedPaidol?.reimbursementSettings?.isEnabled;

  const { isOnboardedToPcards, integration } = useAppSelector(selectReviewOnboardSlice);
  const isCardholder = Role.CARDHOLDER === userRole;
  const userIsAdministrator = Role.ADMINISTRATOR === userRole;

  const { t } = useTranslation();

  const isConstructionType = useMemo(
    () => selectedPaidol?.isConstructionType ?? false,
    [selectedPaidol?.isConstructionType]
  );

  const navigationElements: Array<NavigationItem> = useMemo(
    () =>
      [
        {
          id: 'overview',
          label: t('home'),
          href: '/overview',
          IconComponent: TrackChangesIcon,
          hasAccess: Boolean(!!selectedPaidol?.enablePCards && showPaymentCards && isOnboardedToPcards),
          tabs: [
            {
              label: t('welcome'),
              href: '/overview',
              hasAccess: Boolean(userIsAdministrator && showHomeTab),
            },
            {
              label: t('insights'),
              href: '/overview/insights',
              hasAccess: userIsAdministrator,
            },
            {
              label: t('transactions'),
              href: '/overview/transactions',
              hasAccess: userIsAdministrator,
            },
            {
              label: t('myTransactions'),
              href: '/overview/my-transactions',
              hasAccess: isCardholder,
            },
            {
              label: t('reimbursements'),
              href: '/overview/reimbursements',
              hasAccess: Boolean(showReimbursements && isOnboardedToPcards && userIsAdministrator),
            },
            {
              label: t('myReimbursements'),
              href: '/overview/my-reimbursements',
              hasAccess: Boolean(
                showReimbursements && isOnboardedToPcards && isCardholder && isReimbursementsEnabled
              ),
            },
          ],
        },
        {
          id: 'cards',
          label: t('cards'),
          href: '/cards',
          IconComponent: CreditCardIcon,
          hasAccess:
            (showPaymentCards &&
              isOnboardedToPcards &&
              !!selectedPaidol?.enablePCards &&
              userIsAdministrator) ||
            !userIsAdministrator,
          tabs: [
            {
              label: t('cardManagement'),
              href: '/cards/payment-cards',
              hasAccess: userIsAdministrator,
            },
            {
              label: t('myCards'),
              href: '/cards/my-cards',
              hasAccess: isCardholder,
            },
            {
              label: t('cardTypes'),
              href: '/cards/spend-rules',
              hasAccess: userIsAdministrator,
            },
            {
              label: t('manageCredit'),
              href: '/cards/credit',
              hasAccess:
                userIsAdministrator && integration?.highnoteProduct?.type === HighnoteProductType.CREDIT,
            },
            {
              label: t('debitFunding'),
              href: '/cards/funding',
              hasAccess:
                userIsAdministrator && integration?.highnoteProduct?.type === HighnoteProductType.DEBIT,
            },
          ],
        },
        {
          id: 'payables',
          label: t('payables'),
          href: '/payables',
          IconComponent: PayablesIcon,
          hasAccess: Boolean(
            !isOnboardedToPcards &&
              !!selectedPaidol?.enablePayables &&
              !isCardholder &&
              !!integration?.externalFinancialBankAccountId
          ),
          tabs: [
            { label: t('payable'), href: '/payables' },
            { label: t('paymentFileUpload'), href: '/payables/file-upload' },
          ],
        },
        {
          id: 'team',
          label: 'Organization',
          href: '/team',
          IconComponent: PeopleIcon,
          hasAccess: Boolean(isOnboardedToPcards),
          tabs: [
            { label: t('teamMembers'), href: '/team/members' },
            { label: t('groups'), href: '/team/groups', hasAccess: userIsAdministrator },
            { label: 'Speedchat', href: '/team/speedchat', hasAccess: isCardholder },
          ],
        },
        {
          id: 'wallet',
          label: 'Wallet',
          href: '/wallet',
          IconComponent: AccountBalanceWalletIcon,
          hasAccess: Boolean(!isCardholder && isOnboardedToPcards && showWallet),
          tabs: [{ label: 'Speedchain account', href: '/wallet/speedchain-account' }],
        },
        {
          id: 'settings',
          label: t('settings'),
          href: '/settings',
          IconComponent: SettingsIcon,
          hasAccess: Boolean(userIsAdministrator && isOnboardedToPcards),
          tabs: [
            {
              label: t('App Settings'),
              href: '/settings/app-settings',
              hasAccess: userIsAdministrator,
            },
            {
              label: t('connections'),
              href: '/settings/connectivity',
              hasAccess: isConstructionType,
            },
            {
              label: 'Job Codes',
              href: '/settings/job-codes',
              hasAccess: !isConstructionType && selectedPaidol?.jobCodeSettings?.isEnabled,
            },
          ],
        },
        /* We will hide the Style menu option for now */
        // {
        //   id: 'style',
        //   label: 'Style Guide',
        //   href: '/style',
        //   IconComponent: StyleIcon,
        //   hasAccess: Boolean(showStyleGuide && userIsAdministrator && isOnboardedToPcards),
        // },
      ].filter((f) => f.hasAccess || f.hasAccess === undefined),
    [
      t,
      selectedPaidol?.enablePCards,
      selectedPaidol?.enablePayables,
      selectedPaidol?.jobCodeSettings?.isEnabled,
      showPaymentCards,
      isOnboardedToPcards,
      userIsAdministrator,
      isCardholder,
      showReimbursements,
      isReimbursementsEnabled,
      integration?.highnoteProduct?.type,
      integration?.externalFinancialBankAccountId,
      showWallet,
      showHomeTab,
      isConstructionType,
    ]
  );

  const filteredTabs = useMemo(
    () =>
      filterById
        ? navigationElements
            .find((f) => filterById === f.id)
            ?.tabs?.filter((f) => f.hasAccess || f.hasAccess === undefined) || []
        : [],
    [filterById, navigationElements]
  );

  return { navigationElements, filteredTabs };
};
