import * as yup from 'yup';
import { Paper, Typography, TextField, InputAdornment } from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/system';
import useIsMobile from 'app/hooks/useIsMobile';
import ResponsiveButtonContainer from 'app/shared-components/layout/ResponsiveButtonContainer';
import ProgressButton from 'app/shared-components/ui/ProgressButton';
import { HNRecurringAchTransferFrequencyCode } from 'API';
import { useTranslation } from 'react-i18next';

const schema = yup.object({
  initialAmount: yup.number().min(1).required(),
  allowAutomaticFunding: yup.boolean(),
  autoTransferAmount: yup.number().when('allowAutomaticFunding', (allowAutomaticFunding, schema) => {
    return allowAutomaticFunding ? schema.min(1).required() : schema;
  }),
  fundingTrigger: yup
    .mixed<HNRecurringAchTransferFrequencyCode>()
    .oneOf(Object.values(HNRecurringAchTransferFrequencyCode))
    .when('allowAutomaticFunding', (allowAutomaticFunding, schema) => {
      return allowAutomaticFunding ? schema.required() : schema;
    }),
});

export type FundingFormValues = yup.InferType<typeof schema>;

export interface FundingFormProps {
  onSubmit: SubmitHandler<FundingFormValues>;
  isWorking: boolean;
  disabled: boolean;
}

function FundingForm({ onSubmit, isWorking, disabled }: FundingFormProps): JSX.Element {
  const isMobile = useIsMobile();
  const methods = useForm<FundingFormValues>({
    mode: 'onChange',
    defaultValues: {
      initialAmount: 0,
      allowAutomaticFunding: false,
      autoTransferAmount: 0,
      fundingTrigger: HNRecurringAchTransferFrequencyCode.MONTHLY,
    },
    resolver: yupResolver(schema),
  });
  const { control, formState, watch, handleSubmit } = methods;
  const { errors } = formState;

  const { t } = useTranslation();
  //const allowAutomaticFunding = watch('allowAutomaticFunding');

  return (
    <Paper
      sx={{ my: { xs: 4, sm: 'auto' }, p: { sm: 4 }, width: { sm: 560 } }}
      elevation={isMobile ? 0 : undefined}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="large" sx={{ mt: 2, textAlign: 'center' }} color="primary.main">
          <strong>{t('setYourFundingLevels')}</strong>
        </Typography>
        <Typography sx={{ mt: 1, mb: 2 }}>{t('sameDayACH')}</Typography>
        <Box>
          <Controller
            name="initialAmount"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                type="number"
                error={!!errors.initialAmount}
                label={t('initialFundingAmount')}
                id="initialAmount"
                required
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
            )}
          />
        </Box>
        {/*  I left this part commented out so that we don't have to redo the work when we have automatic funding
               <Box sx={{ mt: 2 }}>
          <Controller
            name="allowAutomaticFunding"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Switch {...field} />}
                label="Allow automatic funding"
              />
            )}
          />
        </Box>
        <Collapse in={allowAutomaticFunding}>
          <Box sx={{ ml: { sm: 6 } }}>
            <Typography>
              If your financial account down not have enough funds, debit card
              transactions will be declined.
            </Typography>
            <Box
              sx={{
                mt: 2,
                display: 'grid',
                gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
                gap: 2,
              }}
            >
              <Controller
                name="autoTransferAmount"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    type="number"
                    error={!!errors.autoTransferAmount}
                    label="Auto transfer amount"
                    id="autoTransferAmount"
                    required
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Controller
                name="fundingTrigger"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id="fundingTrigger-label">
                      Funding trigger
                    </InputLabel>
                    <Select
                      labelId="fundingTrigger-label"
                      id="fundingTrigger"
                      label="Funding trigger"
                      {...field}
                      value={field.value ?? ''}
                      variant="outlined"
                      fullWidth
                    >
                      <MenuItem
                        value={HNRecurringAchTransferFrequencyCode.MONTHLY}
                      >
                        Monthly
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Box>
          </Box>
        </Collapse> */}
        <ResponsiveButtonContainer>
          <ProgressButton type="submit" loading={isWorking} disabled={disabled}>
            {t('save')}
          </ProgressButton>
        </ResponsiveButtonContainer>
      </form>
    </Paper>
  );
}

export default FundingForm;
