import { GetCardsPayload, HNCardFormFactor } from 'API';
import useSidesheetById from 'app/hooks/useSidesheetById';
import { getGroups, resetGroupsSlice, selectAllGroups } from 'app/pages/store/groupsSlice';
import { issuePaymentCardForFinancialAccount } from 'app/pages/store/issueCardSlice';
import { selectReviewOnboardSlice } from 'app/pages/store/reviewOnboardSlice';
import { selectAllSpendRules } from 'app/pages/store/spendRulesSlice';
import SideSheetContent from 'app/shared-components/sidesheets/SideSheetContent';
import SideSheetHeader from 'app/shared-components/sidesheets/SideSheetHeader';
import { SidesheetRoutes, useSidesheet } from 'app/shared-components/sidesheets/SidesheetProvider';
import { useAppDispatch, useAppSelector } from 'app/store';
import { selectUserCompanies } from 'app/store/userCompaniesSlice';
import { formatISO } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { handleHNErrors } from 'util/errorHandling';
import NewCardForm from './NewCardForm';
import { FormType, NewCardFormValues } from './newCardUtils';

export interface NewCardSheetProps {
  selectedPaymentCard?: GetCardsPayload;
  formType: FormType;
  loadPaymentCards?: () => Promise<void>;
  onUpdateCard?: () => void;
}

function NewCardSheet(): JSX.Element {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const sidesheet = useSidesheetById(SidesheetRoutes.NewCard);
  const params: NewCardSheetProps = sidesheet.params;

  const { formType, selectedPaymentCard, onUpdateCard, loadPaymentCards } = params;

  const { closeSidesheet } = useSidesheet();
  const { integration } = useAppSelector(selectReviewOnboardSlice);
  const { selectedPaidolId: selectedCompany } = useAppSelector(selectUserCompanies);
  const groups = useAppSelector(selectAllGroups);
  const merchantSpendRules = useAppSelector(selectAllSpendRules);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getGroups({ paidolId: selectedCompany }));

      return () => {
        dispatch(resetGroupsSlice());
      };
    }
  }, [dispatch, selectedCompany]);

  const handleClose = useCallback(() => {
    closeSidesheet(SidesheetRoutes.NewCard);
  }, [closeSidesheet]);

  const onSaveCard = useCallback(
    async (form: NewCardFormValues) => {
      if (!integration || !selectedCompany || !integration.financialAccountId) {
        enqueueSnackbar(t('unknownError'), { variant: 'error' });
        console.error('ERROR:', integration, selectedCompany);
        return;
      }

      setLoading(true);

      let hostname = window.location.origin;
      if (!window.location.origin) {
        const port = window.location.port ? `:${window.location.port}` : '';
        hostname = `${window.location.protocol}//${window.location.hostname}${port}`;
      }

      const isPhysical = form.cardType === HNCardFormFactor.PHYSICAL;
      const isNotUpdate = form.formType !== FormType.UpdateCard;

      dispatch(
        issuePaymentCardForFinancialAccount({
          input: {
            formType,
            selectedPaymentCardId: selectedPaymentCard?.id,
            financialAccountId: integration.financialAccountId,
            ...(integration.cardProfileId && { cardProfileId: integration.cardProfileId }),
            cardExpirationDate: formatISO(form.cardExpirationDate),
            cardType: form.cardType as HNCardFormFactor,
            email: form.email,
            ...(form.user?.id &&
              form.user?.name && {
                procoreEmployee: {
                  id: form.user.id,
                  name: form.user.name,
                  email_address: form.user.email_address,
                },
              }),
            paidolId: selectedCompany,
            hostname,
            cardName: form.cardName,
            cardGroupId: form.cardGroupId,
            maximumTransactionAmount: form.maximumTransactionAmount,
            monthlySpendLimit: form.monthlySpendLimit,
            merchantSpendRuleId: form.merchantSpendRuleId,
            requireCardCode: form.requireCardCode,
            requireAddress: form.requireAddress,
            ...(isPhysical &&
              isNotUpdate && {
                addressFirstName: form.addressFirstName
                  ? form.addressFirstName.trim()
                  : form.addressFirstName,
                addressLastName: form.addressLastName ? form.addressLastName.trim() : form.addressLastName,
                addressStreet1: form.addressStreet1 ? form.addressStreet1.trim() : form.addressStreet1,
                addressStreet2: form.addressStreet2 ? form.addressStreet2.trim() : form.addressStreet2,
                addressZip: form.addressZip ? form.addressZip.trim() : form.addressZip,
                addressCity: form.addressCity ? form.addressCity.trim() : form.addressCity,
                addressState: form.addressState,
                shippingMethod: form.shippingMethod !== 'NO_SHIPPING' ? form.shippingMethod : undefined,
                requireSignature: form.requireSignature,
              }),
          },
        })
      )
        .unwrap()
        .then((response) => {
          handleClose();
          // Refresh cards list. Timeout is necessary because HN is not retrieving updated data immediately
          loadPaymentCards && setTimeout(async () => await loadPaymentCards(), 1000);

          enqueueSnackbar(
            formType === 'ISSUE_CARD'
              ? 'Card has been successfully issued.'
              : formType === 'ORDER_PHYSICAL'
              ? 'Physical card has been successfully ordered.'
              : 'Changes to card have been saved.',
            {
              variant: 'success',
            }
          );
        })
        .catch((error) => {
          if (handleHNErrors(error, enqueueSnackbar, 'An error has occurred:')) return;

          enqueueSnackbar(t('unknownError'), { variant: 'error' });
          throw error;
        })
        .finally(() => {
          setLoading(false);
          onUpdateCard && onUpdateCard();
        });
    },
    [
      integration,
      selectedCompany,
      dispatch,
      formType,
      selectedPaymentCard?.id,
      enqueueSnackbar,
      handleClose,
      loadPaymentCards,
      onUpdateCard,
      t,
    ]
  );

  return (
    <>
      <SideSheetHeader
        title={
          formType === 'ISSUE_CARD'
            ? 'New card'
            : formType === 'UPDATE_CARD'
            ? 'Edit card'
            : 'Order physical card'
        }
        onClose={handleClose}
      />
      <SideSheetContent>
        <NewCardForm
          formType={formType}
          paymentCard={selectedPaymentCard}
          loading={loading}
          groups={groups}
          rules={merchantSpendRules}
          onSubmit={onSaveCard}
          onCancel={handleClose}
        />
      </SideSheetContent>
    </>
  );
}

export default NewCardSheet;
