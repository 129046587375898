import { Button, DialogContent, Typography } from '@mui/material';
import ResponsiveDialog from 'app/shared-components/layout/dialog/ResponsiveDialog';
import { ReactComponent as FundingLevelsIllustration } from 'assets/FundingLevels.svg';
import { Box } from '@mui/system';
import ResponsiveButtonContainer from 'app/shared-components/layout/ResponsiveButtonContainer';
import { useTranslation } from 'react-i18next';

export interface CompletionDialogProps {
  open: boolean;
  onDismiss: React.MouseEventHandler<HTMLButtonElement>;
}

function CompletionDialog({ open, onDismiss }: CompletionDialogProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <ResponsiveDialog open={open}>
      <DialogContent>
        <Box
          sx={{
            width: { sm: 460 },
            height: { sm: 345 },
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ mx: 'auto', mt: 2 }}>
            <FundingLevelsIllustration />
          </Box>
          <Box
            sx={{
              mt: 2,
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box sx={{ position: 'absolute' }}>
              <Box sx={{ mx: 4 }}>
                <Typography variant="h3" sx={{ mb: 2 }}>
                  <strong>{t('successfullySetFundingLevels')}</strong>
                </Typography>
                <Typography variant="large">{t('nextToPurchaseCardsOverview')}</Typography>
              </Box>
              <ResponsiveButtonContainer>
                <Button variant="text" onClick={onDismiss}>
                  {t('dismiss')}
                </Button>
              </ResponsiveButtonContainer>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </ResponsiveDialog>
  );
}

export default CompletionDialog;
