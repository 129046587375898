import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { RootState } from 'app/store/rootReducer';
import { GetBudgetQuery, EditBudgetMutation, GetBudgetSpendByMonthQuery } from 'API';
import { getBudgetSpendByMonth } from 'graphql/queries';

export interface BudgetWidgetState {
  budget: number;
  spent: number;
  panelOpen: boolean;
}

export const initialState: BudgetWidgetState = {
  budget: 0,
  spent: 0,
  panelOpen: false,
};

const editBudgetQuery = /* GraphQL */ `
  mutation EditBudget($input: EditBudgetInput!) {
    editBudget(input: $input) {
      paidolId
      budget {
        value
        currency
      }
    }
  }
`;

const getBudgetQuery = /* GraphQL */ `
  query GetBudget($paidolId: ID!) {
    getBudget(paidolId: $paidolId) {
      paidolId
      budget {
        value
        currency
      }
    }
  }
`;

export const getBudget = createAsyncThunk('cards/budgetWidget/getBudget', async (paidolId: string) => {
  return (
    API.graphql(
      graphqlOperation(getBudgetQuery, {
        paidolId,
      })
    ) as Promise<GraphQLResult<GetBudgetQuery>>
  ).then((results) => results?.data?.getBudget?.budget.value || 0);
});

export const getSpentBudget = createAsyncThunk('cards/budgetWidget/getSpentBudget', async (input: object) => {
  return (
    API.graphql(graphqlOperation(getBudgetSpendByMonth, { ...input })) as Promise<
      GraphQLResult<GetBudgetSpendByMonthQuery>
    >
  ).then((results) => results?.data?.getBudgetSpendByMonth?.amount || 0);
});

export const editBudget = createAsyncThunk('cards/budgetWidget/editBudget', async (input: object) => {
  return (
    API.graphql(graphqlOperation(editBudgetQuery, { input })) as Promise<GraphQLResult<EditBudgetMutation>>
  ).then((results) => results?.data);
});

const budgetWidgetSlice = createSlice({
  name: 'cards/budgetWidget',
  initialState,
  reducers: {
    setPanelOpen: (state, action) => {
      state.panelOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSpentBudget.fulfilled, (state, action) => {
      state.spent = action.payload;
    });
    builder.addCase(getBudget.fulfilled, (state, action) => {
      state.budget = action.payload;
    });
  },
});

export const { setPanelOpen } = budgetWidgetSlice.actions;

export const selectBudgetWidgetSlice = (state: RootState): BudgetWidgetState =>
  state?.cards?.budgetWidget ?? initialState;

export default budgetWidgetSlice.reducer;
