import { Box, Typography } from '@mui/material';
import ProgressDialog from 'app/pages/review/ProgressDialog';
import FinicityIntegrationPaidolContainer from 'app/shared-components/util/FinicityIntegration/FinicityIntegrationPaidolContainer';
import { useAppSelector } from 'app/store';
import { selectReviewOnboardSlice } from 'app/pages/store/reviewOnboardSlice';
import { selectUserCompanies } from 'app/store/userCompaniesSlice';
import { useTranslation } from 'react-i18next';

interface StepOneProps {
  isWorking: boolean;
  showProgressDialog: boolean;
  onDismissProgressDialog: React.MouseEventHandler<HTMLButtonElement>;
}

function StepOne({ isWorking, showProgressDialog, onDismissProgressDialog }: StepOneProps): JSX.Element {
  const { integration } = useAppSelector(selectReviewOnboardSlice);
  const { selectedPaidol: paidol } = useAppSelector(selectUserCompanies);
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: {
          sm: `url("${process.env.PUBLIC_URL}/assets/illustrations/Stool 1.svg") no-repeat fixed bottom right`,
        },
      }}
    >
      <Typography variant="h1" color="primary.main" sx={{ textAlign: 'center' }}>
        {t('twoQuickThingsBeforeWeBegin')}
      </Typography>
      <Typography variant="large" sx={{ mt: 2, textAlign: 'center' }} color="primary.main">
        <strong>{t('connectToYourBank')}</strong>
      </Typography>
      <Box
        sx={{
          marginTop: 'auto',
          marginBottom: 'auto',
        }}
      >
        <ProgressDialog open={showProgressDialog} isWorking={isWorking} onDismiss={onDismissProgressDialog} />
        {!isWorking && integration && paidol && (
          <FinicityIntegrationPaidolContainer
            integration={integration}
            paidol={paidol}
            hasConnectedFinancialAccount={Boolean(integration.externalFinancialBankAccountId)}
          />
        )}
      </Box>
    </Box>
  );
}

export default StepOne;
