import { Box, Button, Grid, Typography } from '@mui/material';
import { ConnectFundingAccountWidget } from 'app/pages/cards/funding/ConnectFundingAccountWidget';
import { useTranslation } from 'react-i18next';
import ContainerComponent from 'app/shared-components/container-component/container-component';

interface AddMemeberFormProps {
  handleNext: () => void;
}

const ConnectBankForm = ({ handleNext }: AddMemeberFormProps) => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="center" sx={{ width: '100%' }}>
      <Grid item xs={12}>
        <ContainerComponent sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography sx={{ maxWidth: '376px' }}>{t('connectAccountBannerMessage')}</Typography>
          <Box
            component={'img'}
            src={` ${process.env.PUBLIC_URL}/assets/illustrations/humans/human-upload.svg`}
            sx={{
              px: 2,
              maxHeight: '194px',
              maxWidth: '512px',
              width: 'auto',
              height: 'auto',
              objectFit: 'contain',
            }}
          />
        </ContainerComponent>
      </Grid>
      <Grid
        item
        xs={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: 3,
        }}
      >
        <ConnectFundingAccountWidget messageType="stepper" />

        <Button variant="text" onClick={handleNext} sx={{ mt: 5 }}>
          {t('illDoThisLater')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ConnectBankForm;
