import { StyledContainer } from './style';

type ButtonContainerProps = {
  children: React.ReactNode;
};

function ButtonContainer({ children, ...props }: Readonly<ButtonContainerProps>) {
  return <StyledContainer {...props}>{children}</StyledContainer>;
}

export default ButtonContainer;
