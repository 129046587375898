import { StyledContainer } from './style';

type ContainerProps = {
  children: React.ReactNode;
  sx?: Record<string, unknown>;
};

const ContainerComponent = ({ children, sx, ...props }: Readonly<ContainerProps>) => {
  return (
    <StyledContainer
      sx={{
        minHeight: '194px',
        ...sx,
      }}
      {...props}
    >
      {children}
    </StyledContainer>
  );
};

export default ContainerComponent;
