import { CardGroup, PaidolUser } from 'API';
import { getMonthlySpendFromMonthlyBudgets } from 'app/shared-components/util/utils';
import { format } from 'date-fns';
import { GroupCardType } from './GroupsCard/GroupCard';
import { stringToColor } from 'util/helpers';

export const mapToGroupCard = (
  cardGroups: CardGroup[],
  paidolUserPictureMap: Record<string, string>
): GroupCardType[] => {
  const mapped = cardGroups.map((cardGroup) => {
    const monthlyBudget = cardGroup.monthlyBudget?.value ?? 0;
    const monthlySpend = getMonthlySpendFromMonthlyBudgets(cardGroup.monthlyBudgetSpend?.items);

    const paidolUsers = cardGroup.paymentCards?.items
      .flatMap((paymentCard) =>
        paymentCard?.paidolUsers?.items.flatMap((paidolUser) => paidolUser?.paidolUser)
      )
      .filter((value) => value !== undefined) as PaidolUser[];

    const mapDistinctPaidolUsers: Record<string, PaidolUser> = paidolUsers.reduce((accumulator, value) => {
      return { ...accumulator, [value.id]: value };
    }, {});

    return {
      yearAndMonth: format(new Date(), 'yyyy-MM'),
      id: cardGroup.id,
      name: cardGroup.name,
      cards: cardGroup.paymentCards?.items.length ?? 0,
      users: Object.entries(mapDistinctPaidolUsers).map(([key, paidolUser]) => {
        let name = paidolUser.user?.first_name;
        if (name && paidolUser.user?.last_name) name += ` ${paidolUser.user?.last_name}`;

        return {
          paidolUserId: key,
          email: paidolUser.email || paidolUser.user?.email || '',
          name,
          picture: paidolUserPictureMap[key],
        };
      }),
      // messages: 2,
      isMonthlyBudgetUnlimited: cardGroup.isMonthlyBudgetUnlimited || false,
      spent: { currency: 'USD', value: monthlySpend },
      budget: cardGroup.isMonthlyBudgetUnlimited ? undefined : { currency: 'USD', value: monthlyBudget },
    };
  });

  return mapped;
};

export function stringAvatar(text: string) {
  // split by name or email
  let nameSplit = text.toUpperCase().split(' ');
  if (nameSplit.length < 2) nameSplit = text.toUpperCase().split('@');

  const first = nameSplit[0].length > 0 ? nameSplit[0][0] : undefined;
  const second = nameSplit[1].length > 0 ? nameSplit[1][0] : undefined;

  let children = first;
  if (first && second) children += second;

  return { sx: { bgcolor: stringToColor(text) }, children };
}

export const pluralizeWord = (amount: number, message: string) => {
  const text = amount > 1 ? message + 's' : message;
  return `${amount} ${text}`;
};
