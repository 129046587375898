import { GraphQLResult } from '@aws-amplify/api-graphql';
import { createAsyncThunk, createEntityAdapter, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  CardGroup,
  CreateCardGroupMutation,
  CreateCardGroupInput,
  UpdateCardGroupInput,
  UpdateCardGroupMutation,
  HighnotePaymentCard,
  SearchHighnotePaymentCardsQuery,
  UpdateHighnotePaymentCardInput,
  UpdateHighnotePaymentCardMutation,
} from 'API';
import { RootState } from 'app/store/rootReducer';
import { API, graphqlOperation } from 'aws-amplify';
import {
  GetGroupsForPaidolId,
  GetGroupsForPaidolIdQuery,
  SearchHighnotePaymentCards,
  createCardGroup,
  updateCardGroup,
} from './queries';
import { isNotNullOrUndefined } from 'util/typeGuards';
import { updateHighnotePaymentCard } from 'graphql/mutations';

const groupsEntityAdapter = createEntityAdapter<CardGroup>();

const initialState = groupsEntityAdapter.getInitialState({
  loading: false,
  editPending: false,
  nextToken: undefined as string | undefined,
  selectedGroupId: undefined as string | undefined,
  users: {},
});

export type GroupsState = typeof initialState;

export interface GetGroupsArgs {
  paidolId: string;
  nextToken?: string;
  limit?: number;
}

export const getUsers = createAsyncThunk('cards/groups/getUsers', async (paidolId: string) => {
  return (
    API.graphql(
      graphqlOperation(SearchHighnotePaymentCards, {
        filter: {
          cardGroupId: { exists: false },
          paidolId: { eq: paidolId },
        },
      })
    ) as Promise<GraphQLResult<SearchHighnotePaymentCardsQuery>>
  ).then((result) => result.data?.searchHighnotePaymentCards?.items as Array<HighnotePaymentCard>);
});

export const getGroups = createAsyncThunk(
  'cards/groups/getGroups',
  async ({ paidolId, nextToken, limit }: GetGroupsArgs) => {
    return (
      API.graphql(graphqlOperation(GetGroupsForPaidolId, { paidolId, nextToken, limit })) as Promise<
        GraphQLResult<GetGroupsForPaidolIdQuery>
      >
    ).then((results) => results.data?.listCardGroupsByPaidolId);
  }
);

export const newGroup = createAsyncThunk('cards/groups/newGroup', async (input: CreateCardGroupInput) => {
  return (
    API.graphql(graphqlOperation(createCardGroup, { input })) as Promise<
      GraphQLResult<CreateCardGroupMutation>
    >
  ).then((results) => results.data?.createCardGroup as CardGroup | undefined);
});

export const updateGroup = createAsyncThunk(
  'cards/groups/updateGroup',
  async (input: UpdateCardGroupInput) => {
    return (
      API.graphql(graphqlOperation(updateCardGroup, { input })) as Promise<
        GraphQLResult<UpdateCardGroupMutation>
      >
    ).then((results) => results.data?.updateCardGroup as CardGroup | undefined);
  }
);

export const updateHNPaymentCard = createAsyncThunk(
  'cards/groups/updateHighnotePaymentCard',
  async (input: UpdateHighnotePaymentCardInput) => {
    return (
      API.graphql(graphqlOperation(updateHighnotePaymentCard, { input })) as Promise<
        GraphQLResult<UpdateHighnotePaymentCardMutation>
      >
    ).then((results) => results.data?.updateHighnotePaymentCard as HighnotePaymentCard | undefined);
  }
);

const groupsSlice = createSlice({
  name: 'cards/groups',
  initialState,
  reducers: {
    resetGroupsSlice: () => initialState,
    setSelectedGroupId: (state, action) => {
      state.selectedGroupId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGroups.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getGroups.fulfilled, (state, action) => {
      if (action.payload?.items) {
        groupsEntityAdapter.addMany(state, action.payload.items);
      }
      state.nextToken = action.payload?.nextToken ?? undefined;
      state.loading = false;
    });

    builder.addCase(getGroups.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getUsers.fulfilled, (state, action) => {
      if (action.payload) {
        state.users = action.payload.reduce((prev, next) => {
          const users = next.paidolUsers?.items;
          if (users?.length) {
            prev[next?.paymentCardId] = users
              .map((u) => u?.paidolUser)
              .filter((u) => isNotNullOrUndefined(u))
              .map((u) => {
                if (u?.user) {
                  return `${u!.user!.first_name} ${u!.user!.last_name}`;
                } else {
                  return u?.email ?? 'Unknown';
                }
              });
          }
          return prev;
        }, {} as Record<string, Array<string>>);
      }
    });

    builder.addMatcher(isAnyOf(newGroup.pending, updateGroup.pending), (state) => {
      state.editPending = true;
    });

    builder.addMatcher(isAnyOf(newGroup.fulfilled, updateGroup.fulfilled), (state, action) => {
      if (action.payload) {
        groupsEntityAdapter.upsertOne(state, action.payload);
      }
      state.editPending = false;
    });

    builder.addMatcher(isAnyOf(newGroup.rejected, updateGroup.rejected), (state) => {
      state.editPending = false;
    });
  },
});

export const { resetGroupsSlice, setSelectedGroupId } = groupsSlice.actions;

export const selectGroupsSlice = (state: RootState) => state.cards?.groups ?? initialState;

export const { selectAll: selectAllGroups, selectById: selectGroupById } =
  groupsEntityAdapter.getSelectors(selectGroupsSlice);

export const selectSelectedGroup = (state: RootState) => {
  if (state.cards?.groups.selectedGroupId) {
    return selectGroupById(state, state.cards?.groups.selectedGroupId);
  }

  return undefined;
};

export default groupsSlice.reducer;
