import reducer from 'app/pages/store';
import {
  getCardProductApplication,
  getFinancialAccountIdThrottled,
  getIntegration,
  selectReviewOnboardSlice,
} from 'app/pages/store/reviewOnboardSlice';
import { useAppDispatch, useAppSelector } from 'app/store';
import { refreshUserSession } from 'app/store/authSlice';
import { selectUserCompanies } from 'app/store/userCompaniesSlice';
import withReducer from 'app/store/withReducer';
import { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';

const PurchaseCardsWrapper = ({ children }: PropsWithChildren): JSX.Element => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { selectedPaidolId, selectedPaidol } = useAppSelector(selectUserCompanies);
  const { integration, isOnboardedToPcards } = useAppSelector(selectReviewOnboardSlice);

  useDeepCompareEffect(() => {
    if (!integration) {
      dispatch(getIntegration(selectedPaidolId));
    } else {
      // Refresh API access tokens since they depend on PaidolHighnoteIntegration
      dispatch(refreshUserSession());
    }
  }, [dispatch, integration, selectedPaidolId]);

  useDeepCompareEffect(() => {
    // Check application status
    // TODO: listen for CARD_PRODUCT_APPLICATION_APPROVED in Highnote
    // webhook and update on the backend
    if (
      integration &&
      integration.accountHolderCardProductApplicationId &&
      !integration.isApplicationAccepted
    ) {
      dispatch(getCardProductApplication(integration));
    }
  }, [
    dispatch,
    integration,
    integration?.accountHolderCardProductApplicationId,
    integration?.isApplicationAccepted,
  ]);

  useDeepCompareEffect(() => {
    // Retrieve financial account ID
    // TODO: listen for ACCOUNT_STATUS_ACTIVE in Highnote
    // webhook and update on the backend
    if (integration && integration?.isApplicationAccepted && !integration.financialAccountId) {
      dispatch(getFinancialAccountIdThrottled(integration));
    }
  }, [dispatch, integration, integration?.isApplicationAccepted, integration?.financialAccountId]);

  if (selectedPaidol && !selectedPaidol.enablePCards) {
    return <Navigate to="/payables" replace />;
  }

  if (isOnboardedToPcards === false && !location.pathname.match(/^\/review/)) {
    return <Navigate to="/review" replace />;
  }

  return <>{children}</>;
};

export default withReducer('cards', reducer)(PurchaseCardsWrapper);
