import { useAppSelector } from 'app/store';
import type { RootState } from 'app/store/rootReducer';
import { createSlice } from '@reduxjs/toolkit';
import { signOut } from './authSlice';

export interface NavigationState {
  drawerOpen: boolean;
  currentPage: string | null;
}

const initialState: NavigationState = {
  drawerOpen: false,
  currentPage: null,
};

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    toggleDrawer(state) {
      state.drawerOpen = !state.drawerOpen;
    },
    closeDrawer(state) {
      state.drawerOpen = false;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signOut.fulfilled, () => {
      return initialState;
    });
  },
});

export const { toggleDrawer, closeDrawer, setCurrentPage } = navigationSlice.actions;
export const useNavigationState = () =>
  useAppSelector((state: RootState) => state.navigation as NavigationState);
export default navigationSlice.reducer;
