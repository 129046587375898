export type GetUserQuery = {
  getUser?: {
    __typename: 'User';
    id: string;
    username: string;
    email: string;
    first_name: string;
    last_name: string;
    picture?: string | null;
    is_speedster?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      address
      birthdate
      createdAt
      email
      first_name
      id
      is_speedster
      last_name
      phone_number
      phone_extension
      password_updated_at
      picture
      updatedAt
      username
    }
  }
`;

export type UpdateUserMutation = {
  updateUser?: {
    __typename: 'User';
    id: string;
    username: string;
    email: string;
    first_name: string;
    last_name: string;
    picture?: string | null;
    is_speedster?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      email
      first_name
      last_name
      picture
      createdAt
      updatedAt
      address
      birthdate
      phone_number
      phone_extension
      password_updated_at
    }
  }
`;
