import { Autocomplete, TextField } from '@mui/material';
import { GetProcoreProjectUsersPayload, GetProcoreProjectsPayload } from 'API';
import { useFetchProcoreAuthToken } from 'app/hooks/useFetchProcoreAuthToken';
import { getProjectUsers, selectProcoreSlice } from 'app/pages/store/procoreSlice';
import { useAppDispatch, useAppSelector } from 'app/store';
import { bool } from 'aws-sdk/clients/signer';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface ProcoreUserAutocompleteProps {
  label?: string;
  disabled?: bool;
  onUserChange?: (user: GetProcoreProjectUsersPayload | null) => void;
}

const ProcoreUserAutocomplete = ({
  label = 'User',
  onUserChange,
  disabled,
}: ProcoreUserAutocompleteProps) => {
  const dispatch = useAppDispatch();
  const { control, formState, watch, getValues } = useFormContext();
  const { errors } = formState;
  const watchProject: GetProcoreProjectsPayload | null | '' = watch('project', '');
  const { tokens, handleRequestError } = useFetchProcoreAuthToken(null);
  const { projectUsers } = useAppSelector(selectProcoreSlice);
  const [loading, setLoading] = useState(false);
  const value = getValues('user');

  const shouldDisabled = !watchProject || disabled;

  const handleUserSearch = useCallback(
    (search = '') => {
      if (watchProject) {
        setLoading(true);
        dispatch(
          getProjectUsers({
            auth: `Bearer ${tokens.accessToken}`,
            search,
            projectId: watchProject.id,
          })
        )
          .unwrap()
          .catch(handleRequestError)
          .finally(() => setLoading(false));
      }
    },
    [dispatch, tokens, watchProject, handleRequestError]
  );

  const handleUserSearchDebounced = debounce((search) => {
    handleUserSearch(search);
  }, 800);

  useEffect(() => {
    handleUserSearch();
  }, [handleUserSearch]);

  let errorMessage = '';

  if (errors?.user?.message) {
    errorMessage = String(errors?.user?.message);
  }

  return (
    <>
      <Controller
        name="user"
        control={control}
        render={({ field }) => {
          if (disabled && !!value) {
            return (
              <TextField
                variant="outlined"
                label={label}
                value={value.name}
                disabled
                required
                error={!shouldDisabled && !!errors.user}
                helperText={!shouldDisabled && errorMessage}
                fullWidth
              />
            );
          }
          return (
            <Autocomplete
              sx={{ backgroundColor: '#fff' }}
              onChange={(event, value) => {
                if (onUserChange) {
                  onUserChange(value);
                }
                field.onChange(value);
              }}
              options={projectUsers}
              loading={loading}
              getOptionLabel={(option) => option.name}
              disabled={shouldDisabled}
              filterOptions={(x) => x}
              onInputChange={(event, newInputValue) => {
                if (event.type === 'change') {
                  handleUserSearchDebounced(newInputValue);
                } else {
                  handleUserSearchDebounced('');
                }
              }}
              id="user-autocomplete-input"
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={label}
                  required
                  error={!shouldDisabled && !!errors.user}
                  helperText={!shouldDisabled && errorMessage}
                  fullWidth
                />
              )}
            />
          );
        }}
      />
    </>
  );
};

export default ProcoreUserAutocomplete;
