import type { GraphQLResult } from '@aws-amplify/api-graphql';
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import type {
  DeletePaidolUserMutation,
  PaidolUser,
  InviteUserMutation,
  UpdatePaidolUserMutation,
  Role,
} from 'API';
import type { RootState } from 'app/store/rootReducer';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { deletePaidolUser, inviteUser, updatePaidolUser } from 'graphql/mutations';
import logger from 'util/logging';
import serializeError, { ErrorLike } from 'util/serializeError';
import { isNotNullOrUndefined, isPaidolUser } from 'util/typeGuards';
import type { PaidolUserByPaidolIdQuery } from './usersSliceQueries';
import { paidolUserByPaidolId } from './usersSliceQueries';

const log = logger();

interface GetUsersArgs {
  rowsPerPage: number;
  nextToken: string | null;
  selectedCompany: string;
}

export const getUsers = createAsyncThunk<Array<PaidolUser> | void, GetUsersArgs, { rejectValue: ErrorLike }>(
  'team/users/getUsers',
  async ({ rowsPerPage, nextToken = null, selectedCompany }, { rejectWithValue, dispatch }) => {
    return (
      API.graphql(
        graphqlOperation(paidolUserByPaidolId, {
          paidol_id: selectedCompany,
          limit: rowsPerPage,
          nextToken,
        })
      ) as Promise<GraphQLResult<PaidolUserByPaidolIdQuery>>
    )
      .then((res) => {
        const paidolUsers =
          res?.data?.paidolUserByPaidolId?.items.filter(isNotNullOrUndefined).filter(isPaidolUser) || [];

        dispatch(setNextToken(res?.data?.paidolUserByPaidolId?.nextToken || null));
        dispatch(getUsersPictures(paidolUsers));

        return paidolUsers;
      })
      .catch((error) => {
        rejectWithValue(serializeError(error));
      });
  }
);

export const getUsersPictures = createAsyncThunk(
  'team/users/getUsersPictures',
  async (paidolUsers: Array<PaidolUser>) => {
    return Promise.all(
      paidolUsers.map((paidolUser) => {
        if (paidolUser.user && paidolUser.user.picture) {
          return Storage.get(paidolUser.user.picture, {
            level: 'public',
            expires: 86400, // 1 day
          }).then((url) => {
            return [paidolUser.user_id, url];
          });
        }
        return [paidolUser.user_id, undefined];
      })
    );
  }
);

export const removeUser = createAsyncThunk('team/users/removeUser', async (userId: string) => {
  const input = {
    id: userId,
  };
  return (
    API.graphql(graphqlOperation(deletePaidolUser, { input })) as Promise<
      GraphQLResult<DeletePaidolUserMutation>
    >
  )
    .then(() => {
      return userId;
    })
    .catch((error) => {
      log.error(error);
    });
});

interface InviteUserArgs {
  selectedCompany: string;
  email: string;
  roles: string;
}

export const addUser = createAsyncThunk<
  InviteUserMutation['inviteUser'],
  InviteUserArgs,
  { rejectValue: ErrorLike }
>('team/users/addUser', async (params: InviteUserArgs, { rejectWithValue }) => {
  const companyUser = {
    paidol_id: params.selectedCompany,
    email: params.email.toLowerCase(), // normalize email address
    roles: params.roles,
  };

  let hostname = window.location.origin;
  if (!window.location.origin) {
    hostname =
      window.location.protocol +
      '//' +
      window.location.hostname +
      (window.location.port ? ':' + window.location.port : '');
  }

  return (
    API.graphql(graphqlOperation(inviteUser, { input: companyUser, hostname })) as Promise<
      GraphQLResult<InviteUserMutation>
    >
  )
    .then((res) => {
      return res?.data?.inviteUser || rejectWithValue({ message: 'An unknown error occurred' } as ErrorLike);
    })
    .catch((error) => {
      return rejectWithValue(serializeError(error.errors[0]));
    });
});

interface UpdateUserArgs {
  id: string;
  roles: Role | null | undefined;
}

export const updateUser = createAsyncThunk(
  'team/users/updateUser',
  async (params: UpdateUserArgs, { rejectWithValue }) => {
    const input = {
      id: params.id,
      roles: params.roles,
    };

    return (
      API.graphql(graphqlOperation(updatePaidolUser, { input })) as Promise<
        GraphQLResult<UpdatePaidolUserMutation>
      >
    )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return rejectWithValue(serializeError(error.errors[0]));
      });
  }
);

const usersAdapter = createEntityAdapter<PaidolUser>({
  sortComparer: (a, b) => a?.roles?.localeCompare(b?.roles ?? '') ?? 0,
});

const initialState = usersAdapter.getInitialState({
  searchText: '',
  nextToken: null,
  pictureUrls: {} as Record<string, string>,
  panelOpen: false,
});

export type TeamUsersState = typeof initialState;

const usersSlice = createSlice({
  name: 'team/users',
  initialState,
  reducers: {
    setCompaniesSearchText: (state, action) => {
      state.searchText = action.payload;
      state.nextToken = null;
    },
    setNextToken: (state, action) => {
      state.nextToken = action.payload;
    },
    resetNextToken: (state) => {
      state.nextToken = null;
    },
    clearUsers: () => {
      return initialState;
    },
    setPanelOpen: (state, action) => {
      state.panelOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      if (action.payload) {
        usersAdapter.addMany(state, action.payload);
      }
    });

    builder.addCase(getUsersPictures.fulfilled, (state, action) => {
      if (action.payload) {
        action.payload.forEach(([user_id, url]) => {
          if (isNotNullOrUndefined(user_id) && isNotNullOrUndefined(url)) {
            state.pictureUrls[user_id] = url;
          }
        });
      }
    });

    builder.addCase(addUser.fulfilled, (state, action) => {
      if (action.payload) {
        usersAdapter.addOne(state, action.payload as PaidolUser);
      }
    });

    builder.addCase(removeUser.fulfilled, (state, action) => {
      if (action.payload) {
        usersAdapter.removeOne(state, action.payload);
      }
    });
  },
});

export const { setCompaniesSearchText, setNextToken, resetNextToken, clearUsers, setPanelOpen } =
  usersSlice.actions;

export const selectUsersSlice = (state: RootState): TeamUsersState => state?.team?.users || initialState;

export const { selectAll: selectUsers, selectById: selectProductById } =
  usersAdapter.getSelectors(selectUsersSlice);

export default usersSlice.reducer;
