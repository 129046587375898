import { Box, Typography } from '@mui/material';
import { HNPaymentCardClientTokenPermission } from 'API';
import useSidesheetById from 'app/hooks/useSidesheetById';
import { getHNBusinessAccountHolder } from 'app/pages/onboard/store';
import { selectReviewOnboardSlice } from 'app/pages/store/reviewOnboardSlice';
import {
  generatePaymentCardClientToken,
  getHighnotePaymentCard,
  reset,
  selectSpecificCardSlice,
} from 'app/pages/store/specificCardSlice';
import PinSDK from 'app/shared-components/PinSDK';
import SpecificCard from 'app/shared-components/SpecificCard';
import SideSheetContent from 'app/shared-components/sidesheets/SideSheetContent';
import SideSheetHeader from 'app/shared-components/sidesheets/SideSheetHeader';
import { SidesheetRoutes } from 'app/shared-components/sidesheets/SidesheetProvider';
import { BorderLinearProgress } from 'app/shared-components/ui/BorderLinearProgress';
import Currency from 'app/shared-components/util/Currency';
import Loading from 'app/shared-components/util/Loading';
import { useAppDispatch, useAppSelector } from 'app/store';
import { selectUserCompanies } from 'app/store/userCompaniesSlice';
import { selectUserProfile } from 'app/store/userProfileSlice';
import theme from 'app/theme';
import { ReactComponent as MasterCardLogo } from 'assets/master-card-logo.svg';
import { format } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface CardSheetProps {
  open: boolean;
  onClose: (openCardSheet: boolean) => void;
  paymentCardId?: string;
}

function ActivateCardSheet(): JSX.Element {
  const dispatch = useAppDispatch();
  const sidesheet = useSidesheetById(SidesheetRoutes.ActivateCard);
  const params: CardSheetProps = sidesheet.params;

  const { t } = useTranslation();
  const { profile } = useAppSelector(selectUserProfile);
  const { integration } = useAppSelector(selectReviewOnboardSlice);
  const { selectedPaidolId } = useAppSelector(selectUserCompanies);
  const { cardProduct, formFactor, balance, velocityRule } = useAppSelector(selectSpecificCardSlice);

  const [token, setToken] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [loading, setLoading] = useState(true);
  const [isPhysical, setIsPhysical] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const percentage = (total: number, remaing: number) => {
    return (remaing * 100) / total;
  };

  const handleSubmit = async () => {
    params.onClose(true);
  };

  const loadPaymentCard = useCallback(
    (paymentCardId: string, paidolId: string) =>
      dispatch(
        getHighnotePaymentCard({
          paymentCardId,
          paidolId,
          yearAndMonth: format(new Date(), 'yyyy-MM'),
        })
      )
        .unwrap()
        .then((response) => {
          const paymentCardDetails = response.paymentCardDetails;
          const highnotePaymentCard = response.highnotePaymentCard;

          const cardBelongsToCurrentUser = profile
            ? highnotePaymentCard?.paidolUsers?.items.find(
                (item) => item?.paidolUser?.user?.id === profile.id
              )
            : false;
          if (
            !cardBelongsToCurrentUser ||
            (paymentCardDetails?.status !== 'ACTIVATION_REQUIRED' && highnotePaymentCard?.hasPin)
          ) {
            return params.onClose(true);
          }

          if (paymentCardDetails?.formFactor === 'PHYSICAL') {
            setIsPhysical(true);
          }
        }),
    [dispatch, profile, params]
  );

  const loadPaymentCardClientToken = useCallback(
    (paymentCardId: string) =>
      dispatch(
        generatePaymentCardClientToken({
          paymentCardId: paymentCardId,
          permissions: [HNPaymentCardClientTokenPermission.READ_RESTRICTED_DETAILS],
        })
      )
        .unwrap()
        .then((response) => setToken(response)),
    [dispatch]
  );

  const loadAccountBusinessHolder = useCallback(
    (paidolId: string) =>
      dispatch(getHNBusinessAccountHolder(paidolId))
        .unwrap()
        .then((response) => {
          setZipcode(response?.businessProfile?.billingAddress?.postalCode || '');
        }),
    [dispatch]
  );

  useEffect(() => {
    const initialLoad = async () => {
      if (params.paymentCardId && selectedPaidolId) {
        setLoading(true);

        await loadPaymentCard(params.paymentCardId, selectedPaidolId);
        await loadPaymentCardClientToken(params.paymentCardId);
        await loadAccountBusinessHolder(integration?.businessAccountHolderId || '');

        setLoading(false);
      }
    };

    initialLoad();

    return () => {
      dispatch(reset());
    };
  }, [
    dispatch,
    loadPaymentCard,
    loadPaymentCardClientToken,
    params,
    selectedPaidolId,
    integration,
    loadAccountBusinessHolder,
  ]);

  return (
    <>
      <SideSheetHeader title={t('activateCard')} onClose={() => params.onClose(false)} />
      <SideSheetContent>
        {loading ? (
          <Loading />
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              gap: { xs: 3, sm: 2 },
              p: { xs: 0, sm: 2 },
              flexDirection: { xs: 'column', sm: 'column', md: 'row' }, // 'column' for xs, 'row' for larger sizes
              alignItems: 'center',
            }}
          >
            {/* Pin & message */}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h2" fontWeight="bold" mb={3}>
                {t('hello')} {profile?.first_name},
              </Typography>
              <Typography
                variant="body1"
                fontWeight="normal"
                fontSize="16px"
                lineHeight="24px"
                id="pin-activation-message"
                mb={5}
                sx={{
                  maxWidth: '450px',
                }}
              >
                {isPhysical ? (
                  <>
                    {t('physicalCardMessage')} <br />
                    <strong>{t('physicalCardMessageStrong')}</strong>
                  </>
                ) : (
                  <>{t('createPin')}</>
                )}
              </Typography>
              <Box sx={{ maxWidth: '450px' }}>
                <PinSDK
                  isPhysical={isPhysical}
                  paymentCardId={params.paymentCardId ?? ''}
                  onSubmit={handleSubmit}
                  btnLoading={btnLoading}
                  setBtnLoading={setBtnLoading}
                />
              </Box>
            </Box>

            {/* Card UI */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                maxWidth: { xs: '450px', sm: '500px' },
                width: '100%',
                p: { xs: 0, sm: 4 },
                mb: { xs: 2, sm: 0 },
                borderRadius: 7,
                backgroundColor: { sm: theme.palette.primary.lighter },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: ' 100%',
                  justifyContent: { xs: 'flex-start', sm: 'flex-end' },
                  gap: 1,
                  alignItems: 'center',
                  mb: 5,
                }}
              >
                <MasterCardLogo />
                <Typography variant="h4">{cardProduct?.name}</Typography>
              </Box>
              <Box sx={{ width: ' 100%', mb: 5 }}>
                <Typography variant="display">
                  <strong>
                    <Currency
                      value={balance?.currentBalance?.value}
                      currency={balance?.currentBalance?.currencyCode}
                    />
                  </strong>
                </Typography>

                <BorderLinearProgress
                  sx={{
                    mt: 1,
                    mb: 1,
                  }}
                  variant="determinate"
                  value={percentage(
                    balance?.currentBalance?.value + balance?.remainingBalance?.value,
                    balance?.currentBalance?.value
                  )}
                />
                <Typography
                  variant="body1"
                  fontWeight="normal"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  {t('monthlySpendLimit')}
                  <strong>
                    {velocityRule && (
                      <Currency
                        value={velocityRule?.maximumAmount?.value ?? 0}
                        currency={velocityRule?.maximumAmount?.currencyCode ?? 'USD'}
                      />
                    )}
                    {!velocityRule && t('noLimit')}
                  </strong>
                </Typography>
              </Box>
              <SpecificCard
                paymentCardId={params.paymentCardId ?? ''}
                clientToken={token}
                name={cardProduct.name}
                formFactor={formFactor}
                zipcode={zipcode}
                sx={{ width: '100%' }}
              />
            </Box>
          </Box>
        )}
      </SideSheetContent>
    </>
  );
}

export default ActivateCardSheet;
