import { GraphQLResult } from '@aws-amplify/api-graphql';
import { createAsyncThunk, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import {
  GetPaidolHighnoteIntegrationQuery,
  HighnoteProductType,
  HNAccountHolderApplicationState,
  HNAccountHolderApplicationStatusCode,
  HNAccountHolderCardProductApplicationSnapshotConnection,
  HNAccountHolderVerificationStatusCode,
  HNAccountHolderVerificationStatusReasonCode,
  HnCancelScheduledTransferMutation,
  HNCardProduct,
  HnCreateAccountHolderCardProductApplicationMutation,
  HnCreateOneTimeACHTransferMutation,
  HnCreateRecurringACHTransferMutation,
  HnGetAccountHolderCardProductApplicationQuery,
  HnGetBusinessAccountHolderQuery,
  HnInitiateFundsDepositACHTransferMutation,
  HNIssueFinancialAccountForApplicationInput,
  HnIssueFinancialAccountForApplicationMutation,
  HNRecurringAchTransferFrequencyCode,
  HNTransferBalanceAmountCode,
  HNUSBusinessAccountHolder,
  HNUSBusinessProfileSnapshot,
  HNUSPersonAccountHolderSnapshot,
  PaidolHighnoteIntegration,
  PlaidLinkToken,
  PlaidLinkTokenCreateMutation,
  PlaidLinkTokenExchangeMutation,
  PlaidProcessorToken,
  UpdatePaidolHighnoteIntegrationInput,
  UpdatePaidolHighnoteIntegrationMutation,
} from 'API';
import { hnGetAccountHolderCardProductApplication, hnGetFinancialAccount } from 'app/pages/store/queries';
import { AppDispatch } from 'app/store';
import { signOut, UserAttributes } from 'app/store/authSlice';
import { RootState } from 'app/store/rootReducer';
import { setSelectedPaidolId } from 'app/store/userCompaniesSlice';
import { API, graphqlOperation } from 'aws-amplify';
import {
  hnCancelScheduledTransfer,
  hnCreateDocumentUploadLink,
  hnCreateOneTimeACHTransfer,
  hnCreateRecurringACHTransfer,
  hnEndDocumentUploadSession,
  hnInitiateFundsDepositACHTransfer,
  hnIssueFinancialAccountForApplication,
  hnStartDocumentUploadSession,
  plaidLinkTokenCreate,
  plaidLinkTokenExchange,
  updatePaidolHighnoteIntegration as updatePaidolHighnoteIntegrationMutation,
} from 'graphql/mutations';
import { getPaidolHighnoteIntegration } from 'graphql/queries';
import { createThrottledThunk } from 'util/thunkHelpers';
import { isPlaidLinkToken, isPlaidProcessorToken } from 'util/typeGuards';
import { hnCreateAccountHolderCardProductApplication } from './mutations';

interface Application {
  accountHolderSnapshot?: {
    businessProfile?: HNUSBusinessProfileSnapshot;
    primaryAuthorizedPerson?: HNUSPersonAccountHolderSnapshot;
  };
  applicationState?: HNAccountHolderApplicationState | null;
  cardProduct?: HNCardProduct | null;
  applicationHistory?: HNAccountHolderCardProductApplicationSnapshotConnection | null;
  createdAt?: string | null;
  updatedAt?: string | null;

  needDocuments?: boolean;
}
export interface ReviewOnboardState {
  hasFinancialAccountId?: boolean;
  hasExternalAccountId?: boolean;
  isOnboardedToPcards?: boolean;
  hasSubmittedApplication?: boolean;
  integration?: PaidolHighnoteIntegration;
  plaidLinkToken?: PlaidLinkToken;
  plaidProcessorToken?: PlaidProcessorToken;
  application?: Application;
  issueFinancialAccountForApplicationLoading?: boolean;
}

export const initialState: ReviewOnboardState = {};

export const getIntegration = createAsyncThunk(
  'reviewOnboard/getIntegration',
  async (id: string, { dispatch }) => {
    return (
      API.graphql(graphqlOperation(getPaidolHighnoteIntegration, { id })) as Promise<
        GraphQLResult<GetPaidolHighnoteIntegrationQuery>
      >
    ).then((results) => results?.data?.getPaidolHighnoteIntegration);
  }
);

export const getCardProductApplication = createAsyncThunk(
  'reviewOnboard/getCardProductApplication',
  async (integration: PaidolHighnoteIntegration, { dispatch }) => {
    return (
      API.graphql(
        graphqlOperation(hnGetAccountHolderCardProductApplication, {
          id: integration.accountHolderCardProductApplicationId,
        })
      ) as Promise<GraphQLResult<HnGetAccountHolderCardProductApplicationQuery>>
    ).then((result) => {
      const application = result.data?.hnGetAccountHolderCardProductApplication as Application;
      if (
        application?.applicationState?.status === HNAccountHolderApplicationStatusCode.APPROVED &&
        !integration.isApplicationAccepted
      ) {
        dispatch(
          updatePaidolHighnoteIntegration({
            input: { id: integration.id, isApplicationAccepted: true },
          })
        );
      }

      const filterAccounts = Object.values(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        application?.accountHolderSnapshot
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ).filter((account: any) => account.currentVerification);

      const isDocumentneed = Object.values(filterAccounts).find(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (account: any) =>
          account.currentVerification.status === HNAccountHolderVerificationStatusCode.PENDING &&
          account.currentVerification.reason ===
            HNAccountHolderVerificationStatusReasonCode.DOCUMENT_UPLOAD_REQUIRED
      );

      return { ...application, needDocuments: !!isDocumentneed };
    });
  }
);

interface senDocumentArgs {
  documentUploadSessionId: string | undefined;
  files: (
    | {
        id: string;
        file: File | undefined;
        documentType?: string | undefined;
      }
    | undefined
  )[];
}

export const sendDocumentForManualReview = createAsyncThunk(
  'reviewOnboard/sendDocumentForManualReview',
  async ({ documentUploadSessionId, files }: senDocumentArgs) => {
    try {
      await API.graphql(
        graphqlOperation(hnStartDocumentUploadSession, {
          input: {
            documentUploadSessionId: documentUploadSessionId,
          },
        })
      );

      for (const item of files) {
        const createUploadLink = await API.graphql(
          graphqlOperation(hnCreateDocumentUploadLink, {
            input: {
              documentUploadSessionId: documentUploadSessionId,
              documentType: item?.documentType,
            },
          })
        );
        const uploadUrl =
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          createUploadLink.data?.hnCreateDocumentUploadLink?.uploadUrl;
        await fetch(uploadUrl, {
          method: 'PUT',
          body: item?.file,
        });
      }

      await API.graphql(
        graphqlOperation(hnEndDocumentUploadSession, {
          input: {
            documentUploadSessionId: documentUploadSessionId,
          },
        })
      );
    } catch (error: unknown) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }
);

export const getFinancialAccountIdThrottled = (integration: PaidolHighnoteIntegration) =>
  createThrottledThunk(
    (dispatch: AppDispatch) => {
      return dispatch(getFinancialAccountId(integration));
    },
    'reviewOnboard/getFinancialAccountIdThrottled',
    10 * 1000
  );

export const getFinancialAccountId = createAsyncThunk(
  'reviewOnboard/getFinancialAccountId',
  async (integration: PaidolHighnoteIntegration, { dispatch }) => {
    return (
      API.graphql(
        graphqlOperation(hnGetFinancialAccount, {
          id: integration.businessAccountHolderId,
        })
      ) as Promise<GraphQLResult<HnGetBusinessAccountHolderQuery>>
    ).then((result) => {
      const hnProfile = result.data?.hnGetBusinessAccountHolder as HNUSBusinessAccountHolder;

      if (hnProfile) {
        const financialAccountId = hnProfile.financialAccounts?.edges?.find((e) => e.node?.id)?.node?.id;
        if (financialAccountId) {
          dispatch(
            updatePaidolHighnoteIntegration({
              input: {
                id: integration.id,
                financialAccountId,
              },
            })
          );
        }
      }
    });
  }
);

export interface GetPlaidLinkTokenArgs {
  userId: string;
  redirectUri: string;
}

export const getPlaidLinkToken = createAsyncThunk(
  'reviewOnboard/getPlaidLinkToken',
  async (args: GetPlaidLinkTokenArgs) => {
    return (
      API.graphql(graphqlOperation(plaidLinkTokenCreate, args)) as Promise<
        GraphQLResult<PlaidLinkTokenCreateMutation>
      >
    ).then((response) => response?.data?.plaidLinkTokenCreate);
  }
);

export interface GetPlaidProcessorTokenArgs {
  publicToken: string;
  accountId: string;
}

export const getPlaidProcessorToken = createAsyncThunk(
  'reviewOnboard/getPlaidProcessorToken',
  async ({ publicToken, accountId }: GetPlaidProcessorTokenArgs) => {
    return (
      API.graphql(graphqlOperation(plaidLinkTokenExchange, { publicToken, accountId })) as Promise<
        GraphQLResult<PlaidLinkTokenExchangeMutation>
      >
    ).then((response) => response?.data?.plaidLinkTokenExchange);
  }
);

export interface CreateOneTimeTransferArgs {
  user: UserAttributes;
  integration: PaidolHighnoteIntegration;
  value: string | HNTransferBalanceAmountCode;
  date: Date;
}

export const createOneTimeTransfer = createAsyncThunk(
  'reviewOnboard/createOneTimeTransfer',
  async ({ user, integration, value, date = new Date() }: CreateOneTimeTransferArgs) => {
    return (
      API.graphql(
        graphqlOperation(hnCreateOneTimeACHTransfer, {
          input: {
            transferAmountStrategy: {
              ...(value === HNTransferBalanceAmountCode.OUTSTANDING_BALANCE ||
              value === HNTransferBalanceAmountCode.OUTSTANDING_STATEMENT_BALANCE
                ? { balanceAmountType: value }
                : {
                    transferAmount: {
                      currencyCode: 'USD',
                      value: Math.floor(parseFloat(value) * 100),
                    },
                  }),
            },
            transferDate: new Date(date).toISOString().slice(0, 10),
            transferAgreementConsent: {
              authorizedPersonId: integration.primaryAuthorizedPersonId,
              consentTimestamp: integration.createdAt,
              template: {
                // This is the ID of our MSA in Jotform
                consentTemplateId: process.env.REACT_APP_CONSENT_TEMPLATE_ID,
                consentTemplateVersion: process.env.REACT_APP_CONSENT_TEMPLATE_VERSION,
              },
            },
            descriptor: {
              companyEntryDescription: 'Speedchain',
              individualName: `${user.family_name} ${user.given_name}`.slice(0, 22),
            },
            fromFinancialAccountId: integration.externalFinancialBankAccountId,
            toFinancialAccountId: integration.financialAccountId,
          },
        })
      ) as Promise<GraphQLResult<HnCreateOneTimeACHTransferMutation>>
    ).then((results) => results.data?.hnCreateOneTimeACHTransfer);
  }
);

export interface CancelScheduledTransferArgs {
  scheduledTransferId: string;
}

export const cancelScheduledTransfer = createAsyncThunk(
  'reviewOnboard/cancelScheduledTransfer',
  async ({ scheduledTransferId }: CancelScheduledTransferArgs) => {
    return (
      API.graphql(
        graphqlOperation(hnCancelScheduledTransfer, {
          input: { scheduledTransferId },
        })
      ) as Promise<GraphQLResult<HnCancelScheduledTransferMutation>>
    ).then((results) => results.data?.hnCancelScheduledTransfer);
  }
);

export interface CreateRecurringTransferArgs {
  user: UserAttributes;
  integration: PaidolHighnoteIntegration;
  value: string | HNTransferBalanceAmountCode;
  frequency?: HNRecurringAchTransferFrequencyCode;
  transferDayOfMonth?: number;
}

export const createRecurringTransfer = createAsyncThunk(
  'reviewOnboard/createRecurringTransfer',
  async ({
    user,
    integration,
    value,
    frequency = HNRecurringAchTransferFrequencyCode.MONTHLY,
    transferDayOfMonth = 1,
  }: CreateRecurringTransferArgs) => {
    return (
      API.graphql(
        graphqlOperation(hnCreateRecurringACHTransfer, {
          input: {
            transferAmountStrategy: {
              ...(value === HNTransferBalanceAmountCode.OUTSTANDING_BALANCE ||
              value === HNTransferBalanceAmountCode.OUTSTANDING_STATEMENT_BALANCE
                ? { balanceAmountType: value }
                : {
                    transferAmount: {
                      currencyCode: 'USD',
                      value: Math.floor(parseFloat(value) * 100),
                    },
                  }),
            },
            frequency,
            transferDayOfMonth,
            transferAgreementConsent: {
              authorizedPersonId: integration.primaryAuthorizedPersonId,
              consentTimestamp: integration.createdAt,
              template: {
                // This is the ID of our MSA in Jotform
                consentTemplateId: process.env.REACT_APP_CONSENT_TEMPLATE_ID,
                consentTemplateVersion: process.env.REACT_APP_CONSENT_TEMPLATE_VERSION,
              },
            },
            descriptor: {
              companyEntryDescription: 'Speedchain',
              individualName: `${user.family_name} ${user.given_name}`.slice(0, 22),
            },
            fromFinancialAccountId: integration.externalFinancialBankAccountId,
            toFinancialAccountId: integration.financialAccountId,
          },
        })
      ) as Promise<GraphQLResult<HnCreateRecurringACHTransferMutation>>
    ).then((results) => results.data?.hnCreateRecurringACHTransfer);
  }
);

export interface InitiateFundsDepositTransferArgs {
  user: UserAttributes;
  integration: PaidolHighnoteIntegration;
  value: number;
}

export const initiateFundsDepositTransfer = createAsyncThunk(
  'reviewOnboard/initiateFundsDepositTransfer',
  async ({ user, integration, value }: InitiateFundsDepositTransferArgs) => {
    return (
      API.graphql(
        graphqlOperation(hnInitiateFundsDepositACHTransfer, {
          input: {
            amount: {
              currencyCode: 'USD',
              value: Math.floor(value * 100),
            },
            transferAgreementConsent: {
              authorizedPersonId: integration.primaryAuthorizedPersonId,
              consentTimestamp: integration.createdAt,
              template: {
                // This is the ID of our MSA in Jotform
                consentTemplateId: process.env.REACT_APP_CONSENT_TEMPLATE_ID,
                consentTemplateVersion: process.env.REACT_APP_CONSENT_TEMPLATE_VERSION,
              },
            },
            descriptor: {
              companyEntryDescription: 'Speedchain',
              individualName: `${user.family_name} ${user.given_name}`.slice(0, 22),
            },
            fromFinancialAccountId: integration.externalFinancialBankAccountId,
            toFinancialAccountId: integration.financialAccountId,
          },
        })
      ) as Promise<GraphQLResult<HnInitiateFundsDepositACHTransferMutation>>
    ).then((results) => results.data?.hnInitiateFundsDepositACHTransfer);
  }
);

export interface UpdateIntegrationArgs {
  input: UpdatePaidolHighnoteIntegrationInput;
}

export const updatePaidolHighnoteIntegration = createAsyncThunk(
  'reviewOnboard/updatePaidolHighnoteIntegration',
  async ({ input }: UpdateIntegrationArgs) => {
    return (
      API.graphql(
        graphqlOperation(updatePaidolHighnoteIntegrationMutation, {
          input,
        })
      ) as Promise<GraphQLResult<UpdatePaidolHighnoteIntegrationMutation>>
    ).then((result) => result.data?.updatePaidolHighnoteIntegration);
  }
);

type issueFinancialAccountForApplicationParams = HNIssueFinancialAccountForApplicationInput & {
  integration: PaidolHighnoteIntegration;
};

export const issueFinancialAccountForApplication = createAsyncThunk(
  'reviewOnboard/issueFinancialAccountForApplication',
  async (
    { integration, applicationId, externalId, name }: issueFinancialAccountForApplicationParams,
    { dispatch }
  ) => {
    return (
      API.graphql(
        graphqlOperation(hnIssueFinancialAccountForApplication, {
          input: {
            applicationId,
            externalId,
            name,
          },
        })
      ) as Promise<GraphQLResult<HnIssueFinancialAccountForApplicationMutation>>
    ).then((result) => {
      if (
        result?.data?.hnIssueFinancialAccountForApplication &&
        'id' in result.data.hnIssueFinancialAccountForApplication
      ) {
        dispatch(
          updatePaidolHighnoteIntegration({
            input: {
              id: integration.id,
              financialAccountId: result?.data?.hnIssueFinancialAccountForApplication.id,
            },
          })
        );
        return result.data.hnIssueFinancialAccountForApplication;
      }
    });
  }
);

export const createProductApplication = createAsyncThunk(
  'reviewOnboard/createProductApplication',
  async (
    { id, businessAccountHolderId, primaryAuthorizedPersonId, highnoteProductId }: PaidolHighnoteIntegration,
    { dispatch }
  ) => {
    return (
      API.graphql(
        graphqlOperation(hnCreateAccountHolderCardProductApplication, {
          input: {
            cardProductId: highnoteProductId,
            accountHolderId: businessAccountHolderId,
            cardHolderAgreementConsent: {
              primaryAuthorizedPersonId,
              consentTimestamp: new Date().toISOString(),
            },
          },
        })
      ) as Promise<GraphQLResult<HnCreateAccountHolderCardProductApplicationMutation>>
    ).then((result) => {
      if (
        result.data?.hnCreateAccountHolderCardProductApplication?.__typename ===
        'HNAccountHolderCardProductApplication'
      ) {
        dispatch(
          updatePaidolHighnoteIntegration({
            input: {
              id,
              accountHolderCardProductApplicationId:
                result.data.hnCreateAccountHolderCardProductApplication.id,
            },
          })
        );

        return result.data.hnCreateAccountHolderCardProductApplication;
      } else {
        throw new Error('Application failed');
      }
    });
  }
);

const reviewOnboardSlice = createSlice({
  name: 'reviewOnboard',
  initialState,
  reducers: {
    setIsOnboardedToPcards: (state, action: PayloadAction<boolean>) => {
      state.isOnboardedToPcards = action.payload;
    },
    setPlaidLinkToken: (state, action: PayloadAction<PlaidLinkToken>) => {
      state.plaidLinkToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPlaidLinkToken.fulfilled, (state, action) => {
      if (isPlaidLinkToken(action.payload)) {
        state.plaidLinkToken = action.payload;
      }
    });

    builder.addCase(issueFinancialAccountForApplication.pending, (state) => {
      state.issueFinancialAccountForApplicationLoading = true;
    });

    builder.addCase(getPlaidProcessorToken.fulfilled, (state, action) => {
      if (isPlaidProcessorToken(action.payload)) {
        state.plaidProcessorToken = action.payload;
      }
    });

    builder.addCase(createProductApplication.fulfilled, (state) => {
      state.hasSubmittedApplication = true;
    });

    builder.addCase(getCardProductApplication.fulfilled, (state, action) => {
      if (action.payload) {
        state.application = action.payload;
      }
    });

    builder.addCase(updatePaidolHighnoteIntegration.fulfilled, (state, action) => {
      if (action.payload) {
        state.integration = action.payload;
        state.hasExternalAccountId = !!action.payload?.externalFinancialBankAccountId;
      }
    });

    builder.addMatcher(
      isAnyOf(getIntegration.fulfilled, updatePaidolHighnoteIntegration.fulfilled),
      (state, action) => {
        state.integration = action.payload ?? undefined;
        state.hasExternalAccountId = !!action.payload?.externalFinancialBankAccountId;
        state.isOnboardedToPcards =
          !!action.payload?.externalFinancialBankAccountId &&
          (!!action.payload?.hasInitialFunding ||
            action.payload?.highnoteProduct?.type === HighnoteProductType.CREDIT);
        state.hasSubmittedApplication = !!action.payload?.accountHolderCardProductApplicationId;
        state.hasFinancialAccountId = !!action.payload?.financialAccountId;
      }
    );

    builder.addMatcher(
      isAnyOf(issueFinancialAccountForApplication.fulfilled, issueFinancialAccountForApplication.rejected),
      (state) => {
        state.issueFinancialAccountForApplicationLoading = false;
      }
    );

    builder.addMatcher(
      isAnyOf(setSelectedPaidolId.fulfilled, signOut.fulfilled, getIntegration.pending),
      () => {
        return initialState;
      }
    );
  },
});

export const { setIsOnboardedToPcards, setPlaidLinkToken } = reviewOnboardSlice.actions;

export const selectReviewOnboardSlice = (state: RootState): ReviewOnboardState =>
  state?.cards?.onboard ?? initialState;
export const selectIssueFinancialAccountForApplicationLoading = (state: RootState): boolean | undefined =>
  state?.cards?.onboard?.issueFinancialAccountForApplicationLoading;

export default reviewOnboardSlice.reducer;
