import { Autocomplete, TextField } from '@mui/material';
import { GetProcoreProjectsPayload } from 'API';
import { selectProcoreSlice } from 'app/pages/store/procoreSlice';
import { useAppSelector } from 'app/store';
import { Controller, useFormContext } from 'react-hook-form';

interface ProcoreProjectAutocompleteProps {
  onProjectChange?: (project: GetProcoreProjectsPayload | null) => void;
}

const ProcoreProjectAutocomplete = ({ onProjectChange }: ProcoreProjectAutocompleteProps) => {
  const { control, formState } = useFormContext();
  const { errors } = formState;
  const { projects } = useAppSelector(selectProcoreSlice);

  return (
    <>
      <Controller
        name="project"
        control={control}
        render={({ field }) => (
          <Autocomplete
            sx={{ backgroundColor: '#fff' }}
            onChange={(event, value) => {
              if (onProjectChange) {
                onProjectChange(value);
              }
              field.onChange(value);
            }}
            options={projects}
            getOptionLabel={(option) => option.name}
            filterSelectedOptions
            id="project-autocomplete-input"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(field) => (
              <TextField
                {...field}
                variant="outlined"
                label="Project"
                required
                error={!!errors.project}
                helperText={errors?.project?.message ? String(errors?.project?.message) : ''}
                fullWidth
              />
            )}
          />
        )}
      />
    </>
  );
};

export default ProcoreProjectAutocomplete;
