import { combineReducers } from '@reduxjs/toolkit';
import unreadMessages from 'app/pages/overview/store/unreadMessagesSlice';
import type { UnreadMessagesState } from 'app/pages/overview/store/unreadMessagesSlice';

export interface OverviewState {
  unreadMessages: UnreadMessagesState;
}

const reducer = combineReducers({
  unreadMessages,
});

export default reducer;
