import { Box, Typography } from '@mui/material';
import CompletionDialog from 'app/pages/review/CompletionDialog';
import FundingForm, { FundingFormValues } from 'app/pages/review/FundingForm';
import { selectReviewOnboardSlice } from 'app/pages/store/reviewOnboardSlice';
import { useAppSelector } from 'app/store';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface StepTwoProps {
  onSubmit: SubmitHandler<FundingFormValues>;
  onComplete: React.MouseEventHandler<HTMLButtonElement>;
  isWorking: boolean;
}

function StepTwo({ onSubmit, onComplete, isWorking }: StepTwoProps): JSX.Element {
  const { integration } = useAppSelector(selectReviewOnboardSlice);
  const isOnboardingComplete = !!integration?.hasInitialFunding;
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: {
          sm: `url("${process.env.PUBLIC_URL}/assets/illustrations/Stool 1.svg") no-repeat fixed bottom right`,
        },
      }}
    >
      <Typography variant="h1" color="primary.main" sx={{ textAlign: 'center' }}>
        {t('twoQuickThingsBeforeWeBegin')}
      </Typography>
      <FundingForm onSubmit={onSubmit} isWorking={isWorking} disabled={isOnboardingComplete} />
      <CompletionDialog open={isOnboardingComplete} onDismiss={onComplete} />
    </Box>
  );
}

export default StepTwo;
