import serializeError, { ErrorLike } from 'util/serializeError';
import { Auth } from 'aws-amplify';
import type { RootState } from 'app/store/rootReducer';
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import logger from 'util/logging';
import { PROCORE_TOKENS_KEY } from 'app/pages/store/procoreSlice';

export const CognitoError = {
  NotAuthorized: 'NotAuthorizedException',
  UserNotFound: 'UserNotFoundException',
  UsernameExists: 'UsernameExistsException',
  CodeMismatch: 'CodeMismatchException',
  LimitExceeded: 'LimitExceededException',
  PasswordReset: 'PasswordResetRequiredException',
  InvalidParameter: 'InvalidParameterException',
  LambdaError: 'UserLambdaValidationException',
};

export const DEFAULT_LANDING_PAGE = '/landing';
const LOCALSTORAGE_KEY = 'remember_me';

export interface UserAttributes {
  email: string;
  sub: string;
  family_name?: string;
  given_name?: string;
  preferred_username?: string;
  email_verified?: boolean;
}

export interface AuthState {
  isAuthStateUnknown: boolean; // true when app starts until first auth check
  didSessionExpire: boolean; // true if getCurrentUser returns error while signed in
  didUserSignOut: boolean;
  username?: string;
  user?: UserAttributes;
}

export const initialState: AuthState = {
  isAuthStateUnknown: true,
  didSessionExpire: false,
  didUserSignOut: false,
  username: localStorage.getItem(LOCALSTORAGE_KEY) || undefined,
  user: undefined,
};

export const getCurrentUser = createAsyncThunk('auth/getCurrentUser', async () => {
  return Auth.currentAuthenticatedUser({ bypassCache: true }).then((response) => {
    return response.attributes;
  });
});

interface SignInArgs {
  username: string;
  password: string;
  remember: boolean;
}

export const signIn = createAsyncThunk<UserAttributes, SignInArgs, { rejectValue: ErrorLike }>(
  'auth/signIn',
  async ({ username, password, remember }, { rejectWithValue, dispatch }) => {
    return Auth.signIn(username, password)
      .then((response) => {
        if (remember) {
          dispatch(rememberUsername({ username, persist: true }));
        } else {
          dispatch(forgetUsername());
        }
        return response.attributes;
      })
      .catch((error) => {
        return rejectWithValue(serializeError(error));
      });
  }
);

export const refreshUserSession = createAsyncThunk('auth/refreshUserSession', async () => {
  await Auth.currentAuthenticatedUser().then((cognitoUser) => {
    return Auth.currentSession().then((currentSession) => {
      cognitoUser.refreshSession(currentSession.getRefreshToken(), (err: Error, _session: unknown) => {
        if (err) {
          logger().error(err);
        }
      });
    });
  });
  return Auth.currentSession();
});

interface ForgotPasswordArgs {
  username: string;
}

export const forgotPassword = createAsyncThunk<unknown, ForgotPasswordArgs, { rejectValue: ErrorLike }>(
  'auth/forgotPassword',
  async ({ username }: ForgotPasswordArgs, { rejectWithValue }) => {
    return Auth.forgotPassword(username).catch((error) => rejectWithValue(serializeError(error)));
  }
);

interface ForgotPasswordSubmitArgs {
  username: string;
  code: string;
  new_password: string;
}

export const forgotPasswordSubmit = createAsyncThunk<
  string,
  ForgotPasswordSubmitArgs,
  { rejectValue: ErrorLike }
>(
  'auth/forgotPasswordSubmit',
  async ({ username, code, new_password }: ForgotPasswordSubmitArgs, { rejectWithValue, dispatch }) => {
    return Auth.forgotPasswordSubmit(username, code, new_password)
      .then((response) => {
        // Pre-fill username on Login page after submit.
        dispatch(rememberUsername({ username }));
        return response;
      })
      .catch((error) => rejectWithValue(serializeError(error)));
  }
);

interface SignUpArgs {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  password: string;
  birthdate: string;
  phoneNumber: string;
  address: string;
  complement: string;
  postalCode: string;
  city: string;
  state: string;
}

export const signUp = createAsyncThunk<unknown, SignUpArgs, { rejectValue: ErrorLike }>(
  'auth/signUp',
  async (
    {
      firstName,
      lastName,
      username,
      email,
      password,
      birthdate,
      phoneNumber,
      address,
      complement,
      postalCode,
      city,
      state,
    }: SignUpArgs,
    { rejectWithValue, dispatch }
  ) => {
    return Auth.signUp({
      password,
      username,
      attributes: {
        given_name: firstName,
        family_name: lastName,
        preferred_username: username,
        email,
        address: JSON.stringify({
          streetAddress: `${address}`,
          extendedAddress: `${complement}`,
          postalCode,
          locality: city,
          region: state,
        }),
        phone_number: phoneNumber, // format: '+15555555555'
        birthdate, // format: 'YYYY-MM-DD'
      },
    })
      .then((response) => {
        // Pre-fill username on Confirm page after submit.
        dispatch(rememberUsername({ username }));
        return response;
      })
      .catch((error) => rejectWithValue(error));
  }
);

interface ConfirmSignUpArgs {
  username: string;
  code: string;
}

export const confirmSignUp = createAsyncThunk<unknown, ConfirmSignUpArgs, { rejectValue: ErrorLike }>(
  'auth/verifyUser',
  async ({ username, code }: ConfirmSignUpArgs, { rejectWithValue, dispatch }) => {
    const safeCode = code.trim();
    return Auth.confirmSignUp(username, safeCode)
      .then((response) => {
        // Pre-fill username on Login page after submit.
        dispatch(rememberUsername({ username }));
        return response;
      })
      .catch((error) => rejectWithValue(serializeError(error)));
  }
);

interface ResendSignUpArgs {
  username: string;
}

export const resendSignUp = createAsyncThunk<unknown, ResendSignUpArgs, { rejectValue: ErrorLike }>(
  'auth/resendSignUp',
  async ({ username }: ResendSignUpArgs, { rejectWithValue }) => {
    return Auth.resendSignUp(username).catch((error) => rejectWithValue(serializeError(error)));
  }
);

export const updateUserAttributes = createAsyncThunk<unknown, UserAttributes>(
  'auth/updateUserAttributes',
  async (newAttributes, { dispatch }) => {
    return Auth.currentAuthenticatedUser().then((user) => {
      return Auth.updateUserAttributes(user, {
        ...user.attributes,
        ...newAttributes,
      }).then(() => dispatch(getCurrentUser()));
    });
  }
);

export const verifyCurrentUserAttributeSubmit = createAsyncThunk<unknown, string>(
  'auth/verifyCurrentUserAttributeSubmit',
  async (code, { dispatch }) => {
    return Auth.verifyCurrentUserAttributeSubmit('email', code).then(() => dispatch(getCurrentUser()));
  }
);

interface ChangePasswordArgs {
  oldPassword: string;
  newPassword: string;
}

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async ({ oldPassword, newPassword }: ChangePasswordArgs) => {
    return Auth.currentAuthenticatedUser().then((user) => {
      return Auth.changePassword(user, oldPassword, newPassword);
    });
  }
);

export const signOut = createAsyncThunk<unknown, void, { rejectValue: ErrorLike }>(
  'auth/signOut',
  async (_, { rejectWithValue }) => {
    return Auth.signOut()
      .then(() => localStorage.removeItem(PROCORE_TOKENS_KEY))
      .catch((error) => rejectWithValue(serializeError(error)));
  }
);

interface RememberUsernameArgs {
  username: string;
  persist?: boolean;
}

export const rememberUsername = createAsyncThunk(
  'auth/rememberUsername',
  async ({ username, persist = false }: RememberUsernameArgs, { dispatch }) => {
    if (persist) {
      // Only use `persist` option if user has checked 'Remember Me'
      // option during sign-in
      localStorage.setItem(LOCALSTORAGE_KEY, username);
    } else {
      localStorage.removeItem(LOCALSTORAGE_KEY);
    }
    dispatch(updateUsername(username));
  }
);

export const forgetUsername = createAsyncThunk('auth/forgetUsername', async (_, { dispatch }) => {
  localStorage.removeItem(LOCALSTORAGE_KEY);
  dispatch(deleteUsername());
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
    },
    deleteUsername: (state) => {
      state.username = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentUser.rejected, (state, action) => {
      if (state.user) {
        state.didSessionExpire = true;
      }
    });

    builder.addCase(signOut.fulfilled, (state) => {
      state.user = undefined;
      state.didUserSignOut = true;
    });

    builder.addMatcher(isAnyOf(getCurrentUser.fulfilled, signIn.fulfilled), (state, action) => {
      state.isAuthStateUnknown = false;
      state.didSessionExpire = false;
      state.didUserSignOut = false;
      state.user = action.payload;
    });

    builder.addMatcher(isAnyOf(getCurrentUser.rejected, signIn.rejected), (state, action) => {
      state.isAuthStateUnknown = false;
      state.user = undefined;
    });
  },
});

export const { updateUsername, deleteUsername } = authSlice.actions;

export const selectAuth = (state: RootState): AuthState => state.auth as AuthState;

export default authSlice.reducer;
