import NewCardForm from 'app/pages/cards/cards/NewCardSheet/NewCardForm';
import { Box, Grid } from '@mui/material';
import { FormType } from 'app/pages/cards/cards/NewCardSheet/newCardUtils';
import { useAppSelector } from 'app/store';
import { selectAllSpendRules } from 'app/pages/store/spendRulesSlice';
import { useTranslation } from 'react-i18next';

interface IssueCardFormProps {
  handleNext: () => void;
}
const IssueCardForm = ({ handleNext }: IssueCardFormProps) => {
  const merchantSpendRules = useAppSelector(selectAllSpendRules);
  const formType = FormType.IssueCard;
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="center" sx={{ width: '100%' }}>
      <Grid item xs={3} />
      <Grid
        item
        xs={6}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '620px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <NewCardForm
            formType={formType}
            loading={false}
            groups={[]}
            rules={merchantSpendRules}
            onSubmit={() => handleNext()}
            onCancel={() => handleNext()}
            cancelText={t('illDoThisLater')}
          />
        </Box>
      </Grid>
      <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          component={'img'}
          src={` ${process.env.PUBLIC_URL}/assets/illustrations/humans/human-sitting.svg`}
          sx={{
            px: 2,
            maxHeight: '500px',
            maxWidth: '100%',
            width: 'auto',
            height: 'auto',
            objectFit: 'contain',
          }}
        />
      </Grid>
    </Grid>
  );
};

export default IssueCardForm;
