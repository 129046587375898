import { yupResolver } from '@hookform/resolvers/yup';
import { ChecklistRtl } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { HNISO3166Alpha3Country, HNMerchantCategory, MerchantSpendRule } from 'API';
import ResponsiveButtonContainer from 'app/shared-components/layout/ResponsiveButtonContainer';
import { useCallback, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const schema = yup.object({
  name: yup.string().required('You must enter a spend rule name'),
  spendingByCategory: yup.array(),
  spendingByCountry: yup.array(),
  spendingByCategoryType: yup.string(),
  spendingByCountryType: yup.string(),
});

export type EditSpendRuleFormValues = yup.InferType<typeof schema>;

export interface EditSpendRuleFormProps {
  selectedSpendRule?: MerchantSpendRule | undefined;
  highnoteRules?: Map<any, any>;
  renderButtons: (formProps: {
    isValid: boolean;
    isDirty: boolean;
    buttonLabel: string;
    handleSubmit: (onSubmit: (formValues: EditSpendRuleFormValues) => void) => () => void;
  }) => React.ReactNode;
}

function EditSpendRuleForm({
  selectedSpendRule,
  highnoteRules,
  renderButtons,
}: EditSpendRuleFormProps): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();

  const methods = useForm<EditSpendRuleFormValues>({
    mode: 'onChange',
    defaultValues: {
      name: selectedSpendRule?.name,
    },
    resolver: yupResolver(schema),
  });
  const { control, formState, getValues, setError } = methods;
  const { errors, isValid, isDirty } = formState;
  const buttonLabel = selectedSpendRule ? t('update') : t('save');
  const [showCountriesSelect, setShowCountriesSelect] = useState('0');
  const [showCategoriesSelect, setShowCategoriesSelect] = useState('0');

  const handleCountriesSelectChange = (value: string) => {
    setShowCountriesSelect(value);
  };

  const handleCategoriesSelectChange = (value: string) => {
    setShowCategoriesSelect(value);
  };

  const handleSubmit = useCallback(
    (onSubmit: (values: EditSpendRuleFormValues) => void) => () => onSubmit(getValues()),
    [getValues]
  );

  const allowedCategories =
    (highnoteRules?.get(selectedSpendRule?.merchantCategorySpendRuleId) &&
      (highnoteRules?.get(selectedSpendRule?.merchantCategorySpendRuleId).allowedCategory
        ? highnoteRules?.get(selectedSpendRule?.merchantCategorySpendRuleId).allowedCategory
        : [])) ??
    [];
  const blockedCategories =
    (highnoteRules?.get(selectedSpendRule?.merchantCategorySpendRuleId) &&
      (highnoteRules?.get(selectedSpendRule?.merchantCategorySpendRuleId).blockedCategory
        ? highnoteRules?.get(selectedSpendRule?.merchantCategorySpendRuleId).blockedCategory
        : [])) ??
    [];
  const blockedCountries =
    (highnoteRules?.get(selectedSpendRule?.merchantCountrySpendRuleId) &&
      (highnoteRules?.get(selectedSpendRule?.merchantCountrySpendRuleId).blockedCountry
        ? highnoteRules?.get(selectedSpendRule?.merchantCountrySpendRuleId).blockedCountry
        : [])) ??
    [];
  const allowedCountries =
    (highnoteRules?.get(selectedSpendRule?.merchantCountrySpendRuleId) &&
      (highnoteRules?.get(selectedSpendRule?.merchantCountrySpendRuleId).allowedCountry
        ? highnoteRules?.get(selectedSpendRule?.merchantCountrySpendRuleId).allowedCountry
        : [])) ??
    [];

  useEffect(() => {
    if (selectedSpendRule) {
      if (allowedCountries.length) {
        handleCountriesSelectChange('allowed');
      } else if (blockedCountries.length) {
        handleCountriesSelectChange('blocked');
      } else handleCountriesSelectChange('0'); // no restrictions
      if (allowedCategories.length) {
        handleCategoriesSelectChange('allowed');
      } else if (blockedCategories.length) {
        handleCategoriesSelectChange('blocked');
      } else handleCategoriesSelectChange('0'); // no restrictions
    }
  }, []);

  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              type="text"
              error={!!errors.name}
              helperText={errors?.name?.message}
              label={t('ruleName')}
              sx={{ m: 3 }}
              id="name"
              variant="outlined"
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ChecklistRtl />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: '24px',
            m: 3,
            backgroundColor: theme.palette.primary.lighter,
            borderRadius: '16px',
          }}
        >
          <Typography variant="h4" color="primary.main" mb={2.5}>
            {t('spendingByCountry')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: '24px',
              backgroundColor: '#fff',
              borderRadius: '16px',
            }}
          >
            <Controller
              name="spendingByCountryType"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <Select
                    {...field}
                    disabled={!!selectedSpendRule}
                    onChange={(event) => {
                      field.onChange(event.target.value);
                      handleCountriesSelectChange(event.target.value);
                    }}
                    variant="standard"
                    disableUnderline
                    value={showCountriesSelect}
                  >
                    <MenuItem value={'0'}>
                      <em>{t('noRestrictions')}</em>
                    </MenuItem>
                    <MenuItem value={'blocked'}>{t('allCountriesExcept')}</MenuItem>
                    <MenuItem value={'allowed'}>{t('noCountriesExcept')}</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
            {showCountriesSelect !== '0' && (
              <Box mt={1}>
                <Controller
                  name="spendingByCountry"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      sx={{ backgroundColor: '#fff' }}
                      onChange={(event, value) => field.onChange(value)}
                      multiple
                      options={Object.values(HNISO3166Alpha3Country)}
                      getOptionLabel={(option: HNISO3166Alpha3Country) => option}
                      disabled={!!selectedSpendRule}
                      defaultValue={allowedCountries.length ? allowedCountries : blockedCountries}
                      filterSelectedOptions
                      id="spendingByCountry-autocomplete-input"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: '24px',
            m: 3,
            backgroundColor: theme.palette.primary.lighter,
            borderRadius: '16px',
          }}
        >
          <Typography variant="h4" color="primary.main" mb={1}>
            {t('spendingByCategory')}
          </Typography>
          <Typography variant="small" mb={3.5}>
            {t('byMerchantCategoryCodes')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: '24px',
              backgroundColor: '#fff',
              borderRadius: '16px',
            }}
          >
            <Controller
              name="spendingByCategoryType"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <Select
                    {...field}
                    onChange={(event) => {
                      field.onChange(event.target.value);
                      handleCategoriesSelectChange(event.target.value);
                    }}
                    variant="standard"
                    disableUnderline
                    disabled={!!selectedSpendRule}
                    value={showCategoriesSelect}
                  >
                    <MenuItem value={'0'}>
                      <em>{t('noRestrictions')}</em>
                    </MenuItem>
                    <MenuItem value={'blocked'}>{t('allCategoriesExcept')}</MenuItem>
                    <MenuItem value={'allowed'}>{t('noCategoriesExcept')}</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
            {showCategoriesSelect !== '0' && (
              <Box mt={1}>
                <Controller
                  name="spendingByCategory"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      sx={{ backgroundColor: '#fff' }}
                      multiple
                      onChange={(event, value) => field.onChange(value)}
                      options={Object.values(HNMerchantCategory)}
                      disabled={!!selectedSpendRule}
                      defaultValue={allowedCategories.length ? allowedCategories : blockedCategories}
                      getOptionLabel={(option: HNMerchantCategory) => option}
                      filterSelectedOptions
                      id="spendingByCategory-autocomplete-input"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <ResponsiveButtonContainer>
        {renderButtons({ isValid, isDirty, buttonLabel, handleSubmit })}
      </ResponsiveButtonContainer>
    </FormProvider>
  );
}

export default EditSpendRuleForm;
