import { Box, Button, styled, Typography } from '@mui/material';
import { useProcoreIntegration } from 'app/hooks/erp-integration/useProcoreIntegration';

import { useAgaveIntegration } from 'app/hooks/erp-integration/useAgaveIntegration';
import ConnectButton from 'app/shared-components/connect-button/ConnectButton';
import ContainerComponent from 'app/shared-components/container-component/container-component';
import ConnectToYourAccountingSystem from 'app/shared-components/erp-connection/ConnectToYourAccountingSystem';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const StyledImg = styled('img')({
  maxWidth: '36rem',
  alignSelf: 'flex-end',
});

const StyledContainerComponent = styled(ContainerComponent)({
  gap: '8rem',
});

export interface ConnectToAccountingSystemFormProps {
  handleNext?: () => void;
}

function ConnectToAccountingSystemForm({ handleNext }: ConnectToAccountingSystemFormProps) {
  const { t } = useTranslation();
  const [value, setValue] = useState<string | undefined>();

  const {
    loading: loadingAgave,
    connected: agaveConnected,
    openPopup: openAgavePopup,
  } = useAgaveIntegration({ sourceSystem: value });

  const { connected: procoreConnected, loading: loadingProcore, procoreUrl } = useProcoreIntegration();

  const isConnected = useMemo(() => procoreConnected || agaveConnected, [procoreConnected, agaveConnected]);
  const isLoading = useMemo(() => loadingProcore || loadingAgave, [loadingProcore, loadingAgave]);

  useEffect(() => {
    isConnected && handleNext?.();
  }, [isConnected, handleNext]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="6rem"
      flexWrap="wrap"
      width="100%"
    >
      <StyledContainerComponent>
        <Typography maxWidth="37rem" variant="body1">
          {t('connectToYourAccountingSystemHelperText')}
        </Typography>
        <StyledImg
          alt={t('illustration.humanGroupChartAmplifier')}
          src={`${process.env.PUBLIC_URL}/assets/illustrations/humans/human-group-chart-amplifier.svg`}
        />
      </StyledContainerComponent>
      <ConnectToYourAccountingSystem value={value} setValue={setValue} disabled={isConnected} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="row"
        gap="4rem"
        flexWrap="wrap"
      >
        <Button onClick={handleNext} size="small">
          {t('illDoThisLater')}
        </Button>
        <ConnectButton
          loading={isLoading}
          disabled={isConnected || !value}
          {...(value === 'procore' ? { href: procoreUrl } : { onClick: openAgavePopup })}
        >
          {isConnected ? t('connected') : t('connect')}
        </ConnectButton>
      </Box>
    </Box>
  );
}

export default ConnectToAccountingSystemForm;
