/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "dwollaWebhookEndpoint",
            "endpoint": "https://nqke2iosjc.execute-api.us-east-2.amazonaws.com/main",
            "region": "us-east-2"
        },
        {
            "name": "hnCollaborativeAuthorizationEndpoint",
            "endpoint": "https://y7sujjlpjd.execute-api.us-east-2.amazonaws.com/main",
            "region": "us-east-2"
        },
        {
            "name": "hnWebhookEndpoint",
            "endpoint": "https://mfvydnotn6.execute-api.us-east-2.amazonaws.com/main",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://f5uxy32vgrf4ri24xqauzirh3y.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-2:a1cdac89-a7e0-47f5-8761-02ba799c0f9c",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_ypPquHjFF",
    "aws_user_pools_web_client_id": "puiutnuu6f7hh6e0v2c0tqke9",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME",
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "user213504-main",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
