import { Button, CircularProgress, DialogContent, Fade, Typography } from '@mui/material';
import ResponsiveDialog from 'app/shared-components/layout/dialog/ResponsiveDialog';
import { ReactComponent as BankConnectIllustration } from 'assets/BankConnect.svg';
import { Box } from '@mui/system';
import ResponsiveButtonContainer from 'app/shared-components/layout/ResponsiveButtonContainer';

export interface ProgressModalProps {
  open: boolean;
  isWorking: boolean;
  onDismiss: React.MouseEventHandler<HTMLButtonElement>;
}

function ProgressDialog({ open, isWorking, onDismiss }: ProgressModalProps): JSX.Element {
  return (
    <ResponsiveDialog open={open}>
      <DialogContent>
        <Box
          sx={{
            width: { sm: 460 },
            height: { sm: 345 },
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ mx: 'auto', mt: 2 }}>
            <BankConnectIllustration />
          </Box>
          <Box
            sx={{
              mt: 2,
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Fade in={isWorking}>
              <Box sx={{ mx: 'auto', mt: 4 }}>
                <CircularProgress size={100} />
              </Box>
            </Fade>
            <Fade in={!isWorking}>
              <Box sx={{ position: 'absolute' }}>
                <Box sx={{ mx: 4 }}>
                  <Typography variant="h3" sx={{ mb: 2 }}>
                    <strong>You have successfully connected your bank account to Speedchain</strong>
                  </Typography>
                  <Typography variant="large">
                    Next we'll set your funding levels for your purchase card program
                  </Typography>
                </Box>
                <ResponsiveButtonContainer>
                  <Button variant="text" onClick={onDismiss}>
                    Dismiss
                  </Button>
                </ResponsiveButtonContainer>
              </Box>
            </Fade>
          </Box>
        </Box>
      </DialogContent>
    </ResponsiveDialog>
  );
}

export default ProgressDialog;
