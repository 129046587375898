import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { RootState } from 'app/store/rootReducer';
import { isNotNullOrUndefined } from 'util/typeGuards';
import { format } from 'date-fns';
import { PaymentCardDetails } from './specificCardSlice';
import { getCardByHighnoteId } from 'graphql/queries';
import {
  BudgetSpendByMonthAndPaymentCard,
  GetCardByHighnoteIdInput,
  GetCardByHighnoteIdQuery,
  GetCardsInput,
  GetCardsPayload,
  GetCardsPayloadResult,
  GetCardsQuery,
  GetHighnotePaymentCardQuery,
  HighnotePaymentCard,
  HNPageInfo,
  HNPaymentCard,
  ListHighnotePaymentCardsByPaidolIdQuery,
} from 'API';
import {
  hnGetPaymentCardsForBusinessId,
  ListHighnotePaymentCards,
  GetHighnotePaymentCard,
  HnGetPaymentCardsForBusinessIdQuery,
  GetPaymentCardDetails,
  getCards,
} from './queries';

export interface GetHighnotePaymentCardResults {
  hnGetPaymentCardDetails: HNPaymentCard;
  getHighnotePaymentCard?: HighnotePaymentCard;
  getBudgetSpendByMonthAndPaymentCard?: BudgetSpendByMonthAndPaymentCard;
}

export interface PaymentCardsState {
  userCardList: Array<GetCardsPayload> | [];
  paymentCardList: Array<GetCardsPayload> | [];
  paymentCards: Array<GetHighnotePaymentCardResults>;
  cardDetails: Map<string, HighnotePaymentCard>;
  pageInfo?: HNPageInfo;
  panelOpen?: boolean;
  selectedCard?: HNPaymentCard;
  cardholderNames: Record<string, Array<string>>;
  cardsLoading: boolean;
  initialized: boolean;
  loadMore: boolean;
}

export const initialState: PaymentCardsState = {
  paymentCards: [],
  userCardList: [],
  paymentCardList: [],
  cardDetails: new Map(),
  pageInfo: undefined,
  cardholderNames: {},
  cardsLoading: false,
  initialized: false,
  loadMore: false,
};

export interface GetPaymentCardsArgs {
  id: string;
  paidolId: string;
  status?: string;
  after?: string;
}

export const getPaymentCards = createAsyncThunk(
  'cards/paymentCards/getPaymentCards',
  async ({ id, paidolId, status = undefined, after = undefined }: GetPaymentCardsArgs, { dispatch }) => {
    return (
      API.graphql(
        graphqlOperation(hnGetPaymentCardsForBusinessId, {
          id,
          first: 20,
          after,
          ...(status && {
            filterBy: {
              status: { equals: status },
            },
          }),
        })
      ) as Promise<GraphQLResult<HnGetPaymentCardsForBusinessIdQuery>>
    ).then((results) => {
      const paymentCards = results?.data?.hnGetPaymentCardsForBusinessId?.paymentCards;

      if (paymentCards?.edges) {
        paymentCards.edges.forEach((card) => {
          if (card.node) {
            dispatch(
              getPaymentCardDetails({
                id: card.node.id,
                paidolId,
                yearAndMonth: format(new Date(), 'yyyy-MM'),
              })
            );
          }
        });
      }

      return paymentCards;
    });
  }
);

interface GetPaymentCardDetailsArgs {
  id: string;
  paidolId: string;
  yearAndMonth: string;
}

export const getPaymentCardDetails = createAsyncThunk(
  'cards/paymentCards/getPaymentCardDetails',
  async ({ id, paidolId, yearAndMonth }: GetPaymentCardDetailsArgs) => {
    return (
      API.graphql(
        graphqlOperation(GetPaymentCardDetails, {
          id,
          paidolId,
          yearAndMonth,
        })
      ) as Promise<GraphQLResult<GetHighnotePaymentCardResults>>
    ).then((result) => {
      const response: PaymentCardDetails = {
        paymentCardDetails: result?.data?.hnGetPaymentCardDetails,
        highnotePaymentCard: result?.data?.getHighnotePaymentCard,
        highnotePaymentCardBudget: result?.data?.getBudgetSpendByMonthAndPaymentCard,
      };
      return response;
    });
  }
);

export const getCardList = createAsyncThunk('cards/paymentCards/getCards', async (input: GetCardsInput) => {
  return (
    API.graphql(
      graphqlOperation(getCards, {
        input,
      })
    ) as Promise<GraphQLResult<GetCardsQuery>>
  ).then((result) => {
    return result.data?.getCards as GetCardsPayloadResult;
  });
});

export const getCardByHightnoteId = createAsyncThunk(
  'cards/paymentCards/getCardByHightnoteId',
  async (input: GetCardByHighnoteIdInput) => {
    return (
      API.graphql(
        graphqlOperation(getCardByHighnoteId, {
          input,
        })
      ) as Promise<GraphQLResult<GetCardByHighnoteIdQuery>>
    ).then((result) => {
      return result.data?.getCardByHighnoteId as GetCardsPayload;
    });
  }
);

export const getCardDetails = createAsyncThunk(
  'cards/paymentCards/getCardDetails',
  async (paymentCardId: string) => {
    return (
      API.graphql(graphqlOperation(GetHighnotePaymentCard, { paymentCardId })) as Promise<
        GraphQLResult<GetHighnotePaymentCardQuery>
      >
    ).then((result) => {
      return result.data?.getHighnotePaymentCard as HighnotePaymentCard;
    });
  }
);

export const getCardholderNames = createAsyncThunk(
  'cards/paymentCards/getCardholderNames',
  async (paidolId: string) => {
    return (
      API.graphql(graphqlOperation(ListHighnotePaymentCards, { paidolId })) as Promise<
        GraphQLResult<ListHighnotePaymentCardsByPaidolIdQuery>
      >
    ).then((result) => result.data?.listHighnotePaymentCardsByPaidolId?.items as Array<HighnotePaymentCard>);
  }
);

const paymentCardsSlice = createSlice({
  name: 'cards/paymentCards',
  initialState,
  reducers: {
    resetPaymentCardsSlice: () => {
      return initialState;
    },

    setPanelOpen: (state, action) => {
      state.panelOpen = action.payload;
    },

    setSelectedCard: (state, action) => {
      state.selectedCard = action.payload;
    },

    /* Used to change loadMore state to call getCardsList without loading twice on startup */
    setLoadMore: (state) => {
      state.loadMore = true;
    },
  },
  extraReducers: (builder) => {
    /* Load the payment cards for tables */
    builder
      .addCase(getCardList.pending, (state) => {
        state.cardsLoading = true;
      })
      .addCase(getCardList.fulfilled, (state, action) => {
        const userId = action.meta.arg.userId;

        if (action.payload) {
          /* Handles loading more cards */
          if (state.loadMore === true) {
            state.paymentCardList = [...state.paymentCardList, ...(action.payload.items ?? [])];
            state.cardsLoading = false;
            state.pageInfo = action.payload.pageInfo ?? undefined;
          }

          /* Handles loading the users cards */
          if (userId) {
            state.userCardList = action.payload.items;
          }

          /* Handles Initial card load */
          if (state.initialized === false && !userId) {
            state.paymentCardList = action.payload.items ?? [];
            state.pageInfo = action.payload.pageInfo ?? undefined;
            state.initialized = true;
          }
          state.cardsLoading = false;
        }
      })
      .addCase(getCardList.rejected, (state) => {
        state.cardsLoading = false;
      });

    /* Handles updating card changes */
    /* high not is spelt */
    builder.addCase(getCardByHightnoteId.fulfilled, (state, action) => {
      if (action.payload) {
        state.paymentCardList = state.paymentCardList.map((card) => {
          if (card.id === action.payload.id) {
            return action.payload;
          }
          return card;
        });
      }
    });

    builder.addCase(getPaymentCards.fulfilled, (state, action) => {
      if (action.payload) {
        const { edges, pageInfo } = action.payload;

        if (edges) {
          const mapped: GetHighnotePaymentCardResults[] = edges.map((edge) => ({
            hnGetPaymentCardDetails: edge.node!,
          }));
          state.paymentCards.push(...mapped);
        }

        if (pageInfo) {
          state.pageInfo = pageInfo;
        }
      }
    });

    builder.addCase(getCardholderNames.fulfilled, (state, action) => {
      if (action.payload) {
        state.cardholderNames = action.payload.reduce((prev, next) => {
          const users = next.paidolUsers?.items;
          if (users?.length) {
            prev[next?.paymentCardId] = users
              .map((u) => u?.paidolUser)
              .filter((u) => isNotNullOrUndefined(u))
              .map((u) => {
                if (u?.user) {
                  return `${u!.user!.first_name} ${u!.user!.last_name}`;
                } else {
                  return u?.email ?? 'Unknown';
                }
              });
          }
          return prev;
        }, {} as Record<string, Array<string>>);
      }
    });

    builder.addCase(getCardDetails.fulfilled, (state, action) => {
      if (action.payload) {
        const card = action.payload;
        state.cardDetails.set(card.paymentCardId, card);
      }
    });

    builder.addCase(getPaymentCardDetails.fulfilled, (state, action) => {
      if (action.payload) {
        const details = action.payload;
        const newState = state.paymentCards.map((paymentCard) => {
          if (paymentCard.hnGetPaymentCardDetails.id === details.paymentCardDetails?.id) {
            paymentCard.getBudgetSpendByMonthAndPaymentCard = details.highnotePaymentCardBudget;
            paymentCard.getHighnotePaymentCard = details.highnotePaymentCard;
            paymentCard.hnGetPaymentCardDetails = details.paymentCardDetails;
          }

          return paymentCard;
        });
        state.paymentCards = newState;
      }
    });
  },
});

export const { resetPaymentCardsSlice, setPanelOpen, setSelectedCard, setLoadMore } =
  paymentCardsSlice.actions;

export const selectPaymentCardsSlice = (state: RootState): PaymentCardsState =>
  state?.cards?.paymentCards ?? initialState;

export default paymentCardsSlice.reducer;
