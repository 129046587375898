/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getOnboard = /* GraphQL */ `query GetOnboard($id: ID!) {
  getOnboard(id: $id) {
    id
    status
    invitationCode
    formData {
      informationCertification
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    rebatePercentage
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOnboardQueryVariables,
  APITypes.GetOnboardQuery
>;
export const listOnboards = /* GraphQL */ `query ListOnboards(
  $filter: ModelOnboardFilterInput
  $limit: Int
  $nextToken: String
) {
  listOnboards(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      status
      invitationCode
      createdAt
      updatedAt
      rebatePercentage
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOnboardsQueryVariables,
  APITypes.ListOnboardsQuery
>;
export const onboardByInvitationCode = /* GraphQL */ `query OnboardByInvitationCode(
  $invitationCode: String!
  $sortDirection: ModelSortDirection
  $filter: ModelOnboardFilterInput
  $limit: Int
  $nextToken: String
) {
  onboardByInvitationCode(
    invitationCode: $invitationCode
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      status
      invitationCode
      createdAt
      updatedAt
      rebatePercentage
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.OnboardByInvitationCodeQueryVariables,
  APITypes.OnboardByInvitationCodeQuery
>;
export const getPaidolTheme = /* GraphQL */ `query GetPaidolTheme($id: ID!) {
  getPaidolTheme(id: $id) {
    id
    paidolId
    palette {
      primary
      primaryDark
      primaryDarker
      primaryLight
      primaryLighter
      secondary
      secondaryDark
      textPrimary
      textSecondary
      whiteColor
      successColor
      warningColor
      errorColor
      errorLightColor
      lightestGrayColor
      lightGrayColor
      lightBlueColor
      info
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPaidolThemeQueryVariables,
  APITypes.GetPaidolThemeQuery
>;
export const listPaidolThemes = /* GraphQL */ `query ListPaidolThemes(
  $id: ID
  $filter: ModelPaidolThemeFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listPaidolThemes(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      paidolId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPaidolThemesQueryVariables,
  APITypes.ListPaidolThemesQuery
>;
export const listPaidolThemeByPaidolId = /* GraphQL */ `query ListPaidolThemeByPaidolId(
  $paidolId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPaidolThemeFilterInput
  $limit: Int
  $nextToken: String
) {
  listPaidolThemeByPaidolId(
    paidolId: $paidolId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      paidolId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPaidolThemeByPaidolIdQueryVariables,
  APITypes.ListPaidolThemeByPaidolIdQuery
>;
export const getPaidolUser = /* GraphQL */ `query GetPaidolUser($id: ID!) {
  getPaidolUser(id: $id) {
    id
    paidol_id
    paidol {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      createdAt
      updatedAt
      owner
      __typename
    }
    user_id
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    email
    position
    phone
    address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    roles
    is_test_fixture
    paymentCards {
      nextToken
      __typename
    }
    authorizedUserId
    authorizedUserApplicationId
    allowedJobCodes
    employee_id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPaidolUserQueryVariables,
  APITypes.GetPaidolUserQuery
>;
export const listPaidolUsers = /* GraphQL */ `query ListPaidolUsers(
  $filter: ModelPaidolUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listPaidolUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      paidol_id
      user_id
      email
      position
      phone
      roles
      is_test_fixture
      authorizedUserId
      authorizedUserApplicationId
      allowedJobCodes
      employee_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPaidolUsersQueryVariables,
  APITypes.ListPaidolUsersQuery
>;
export const paidolUserByPaidolId = /* GraphQL */ `query PaidolUserByPaidolId(
  $paidol_id: ID!
  $roles: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPaidolUserFilterInput
  $limit: Int
  $nextToken: String
) {
  paidolUserByPaidolId(
    paidol_id: $paidol_id
    roles: $roles
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      paidol_id
      user_id
      email
      position
      phone
      roles
      is_test_fixture
      authorizedUserId
      authorizedUserApplicationId
      allowedJobCodes
      employee_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PaidolUserByPaidolIdQueryVariables,
  APITypes.PaidolUserByPaidolIdQuery
>;
export const paidolUserByUserId = /* GraphQL */ `query PaidolUserByUserId(
  $user_id: ID!
  $paidol_id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPaidolUserFilterInput
  $limit: Int
  $nextToken: String
) {
  paidolUserByUserId(
    user_id: $user_id
    paidol_id: $paidol_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      paidol_id
      user_id
      email
      position
      phone
      roles
      is_test_fixture
      authorizedUserId
      authorizedUserApplicationId
      allowedJobCodes
      employee_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PaidolUserByUserIdQueryVariables,
  APITypes.PaidolUserByUserIdQuery
>;
export const paidolUserByEmail = /* GraphQL */ `query PaidolUserByEmail(
  $email: String!
  $paidol_id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPaidolUserFilterInput
  $limit: Int
  $nextToken: String
) {
  paidolUserByEmail(
    email: $email
    paidol_id: $paidol_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      paidol_id
      user_id
      email
      position
      phone
      roles
      is_test_fixture
      authorizedUserId
      authorizedUserApplicationId
      allowedJobCodes
      employee_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PaidolUserByEmailQueryVariables,
  APITypes.PaidolUserByEmailQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    username
    email
    first_name
    last_name
    paidol_users {
      nextToken
      __typename
    }
    picture
    is_speedster
    birthdate
    address
    phone_number
    ssn
    phone_extension
    password_updated_at
    finicityCustomerId
    dwollaCustomerId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const userByUsername = /* GraphQL */ `query UserByUsername(
  $username: String!
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  userByUsername(
    username: $username
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserByUsernameQueryVariables,
  APITypes.UserByUsernameQuery
>;
export const userByEmail = /* GraphQL */ `query UserByEmail(
  $email: String!
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  userByEmail(
    email: $email
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserByEmailQueryVariables,
  APITypes.UserByEmailQuery
>;
export const speedsterUser = /* GraphQL */ `query SpeedsterUser(
  $is_speedster: String!
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  speedsterUser(
    is_speedster: $is_speedster
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SpeedsterUserQueryVariables,
  APITypes.SpeedsterUserQuery
>;
export const getPaidol = /* GraphQL */ `query GetPaidol($id: ID!) {
  getPaidol(id: $id) {
    id
    id_base58
    id_base58_short
    owner_paidol_id
    slug
    legal_name
    account_balance
    name
    dba
    ein
    mcc
    corp_type
    industry
    company_email
    customer_facing_email
    company_phone
    website
    company_address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    customer_facing_address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    legal_address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    business_owner_ssn
    dwolla_business_industry_classification
    admin_user_id
    accounts_payable_user_id
    accounts_receivable_user_id
    payment_methods {
      ach
      vcard
      check
      __typename
    }
    low_balance_alert_threshold
    low_balance_auto_funding_amount
    low_balance_auto_funding_threshold
    speedscore
    priority_status
    priority_buyer_id
    deleted_at
    company_id
    logo
    priority_response
    type
    bankAccounts {
      bankId
      accountType
      accountNumber
      routingNumber
      defaults
      isActive
      __typename
    }
    achEnabled
    virtualCardRequested
    virtualCardEnabled
    achRequested
    cron_status
    owner_sub
    is_test_fixture
    is_migration
    enablePayables
    enablePCards
    reimbursementSettings {
      isEnabled
      isCheckrunEnabled
      rhythm
      day
      weekday
      ordinal
      __typename
    }
    jobCodeSettings {
      isEnabled
      __typename
    }
    isConstructionType
    agaveAccountToken
    isAgaveClient
    selectedERP
    primaryAuthorizedPerson {
      firstName
      lastName
      email
      hasExtension
      birthdate
      socialSecurityNumber
      jobTitle
      percentageOwnership
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPaidolQueryVariables, APITypes.GetPaidolQuery>;
export const listPaidols = /* GraphQL */ `query ListPaidols(
  $filter: ModelPaidolFilterInput
  $limit: Int
  $nextToken: String
) {
  listPaidols(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPaidolsQueryVariables,
  APITypes.ListPaidolsQuery
>;
export const paidolByCronStatus = /* GraphQL */ `query PaidolByCronStatus(
  $cron_status: CronStatus!
  $sortDirection: ModelSortDirection
  $filter: ModelPaidolFilterInput
  $limit: Int
  $nextToken: String
) {
  paidolByCronStatus(
    cron_status: $cron_status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PaidolByCronStatusQueryVariables,
  APITypes.PaidolByCronStatusQuery
>;
export const searchPaidols = /* GraphQL */ `query SearchPaidols(
  $filter: SearchablePaidolFilterInput
  $sort: [SearchablePaidolSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchablePaidolAggregationInput]
) {
  searchPaidols(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchPaidolsQueryVariables,
  APITypes.SearchPaidolsQuery
>;
export const getPaymentFileQueue = /* GraphQL */ `query GetPaymentFileQueue($id: ID!) {
  getPaymentFileQueue(id: $id) {
    id
    user_id
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    paidol_id
    paidol {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      createdAt
      updatedAt
      owner
      __typename
    }
    key
    status
    processed_at
    error_messages
    num_vendors
    num_payments
    num_success
    num_failed
    total {
      value
      currency
      __typename
    }
    createdAt
    filename
    supplier_vendor_paidol_ids
    referenced_vendors
    invoice_ids
    invoice_spx_ids
    priority_status
    priority_file_id
    priority_process_on
    priority_response
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPaymentFileQueueQueryVariables,
  APITypes.GetPaymentFileQueueQuery
>;
export const listPaymentFileQueues = /* GraphQL */ `query ListPaymentFileQueues(
  $filter: ModelPaymentFileQueueFilterInput
  $limit: Int
  $nextToken: String
) {
  listPaymentFileQueues(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      user_id
      paidol_id
      key
      status
      processed_at
      error_messages
      num_vendors
      num_payments
      num_success
      num_failed
      createdAt
      filename
      supplier_vendor_paidol_ids
      referenced_vendors
      invoice_ids
      invoice_spx_ids
      priority_status
      priority_file_id
      priority_process_on
      priority_response
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPaymentFileQueuesQueryVariables,
  APITypes.ListPaymentFileQueuesQuery
>;
export const paymentFileQueuesByPaidol = /* GraphQL */ `query PaymentFileQueuesByPaidol(
  $paidol_id: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPaymentFileQueueFilterInput
  $limit: Int
  $nextToken: String
) {
  paymentFileQueuesByPaidol(
    paidol_id: $paidol_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user_id
      paidol_id
      key
      status
      processed_at
      error_messages
      num_vendors
      num_payments
      num_success
      num_failed
      createdAt
      filename
      supplier_vendor_paidol_ids
      referenced_vendors
      invoice_ids
      invoice_spx_ids
      priority_status
      priority_file_id
      priority_process_on
      priority_response
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PaymentFileQueuesByPaidolQueryVariables,
  APITypes.PaymentFileQueuesByPaidolQuery
>;
export const paymentFileQueuesByStatus = /* GraphQL */ `query PaymentFileQueuesByStatus(
  $status: PaymentFileQueueStatusEnum!
  $sortDirection: ModelSortDirection
  $filter: ModelPaymentFileQueueFilterInput
  $limit: Int
  $nextToken: String
) {
  paymentFileQueuesByStatus(
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user_id
      paidol_id
      key
      status
      processed_at
      error_messages
      num_vendors
      num_payments
      num_success
      num_failed
      createdAt
      filename
      supplier_vendor_paidol_ids
      referenced_vendors
      invoice_ids
      invoice_spx_ids
      priority_status
      priority_file_id
      priority_process_on
      priority_response
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PaymentFileQueuesByStatusQueryVariables,
  APITypes.PaymentFileQueuesByStatusQuery
>;
export const searchPaymentFileQueues = /* GraphQL */ `query SearchPaymentFileQueues(
  $filter: SearchablePaymentFileQueueFilterInput
  $sort: [SearchablePaymentFileQueueSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchablePaymentFileQueueAggregationInput]
) {
  searchPaymentFileQueues(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      user_id
      paidol_id
      key
      status
      processed_at
      error_messages
      num_vendors
      num_payments
      num_success
      num_failed
      createdAt
      filename
      supplier_vendor_paidol_ids
      referenced_vendors
      invoice_ids
      invoice_spx_ids
      priority_status
      priority_file_id
      priority_process_on
      priority_response
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchPaymentFileQueuesQueryVariables,
  APITypes.SearchPaymentFileQueuesQuery
>;
export const getPaidolVendor = /* GraphQL */ `query GetPaidolVendor($id: ID!) {
  getPaidolVendor(id: $id) {
    id
    user_vendor_id
    is_migration
    paidol_id
    vendor {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      createdAt
      updatedAt
      owner
      __typename
    }
    vendor_paidol_id
    priority_active
    payment_terms
    priority_account_balance
    contact_name
    priority_billing_code
    priority_repeate_code
    priority_group_code
    email_addresses
    priority_document_delivery
    priority_regular_inv
    priority_summary_inv
    foreign_name
    remarks
    box_1099
    form_code_1099
    remark_1
    priority_response
    cron_status
    priority_status
    priority_supplier_id
    virtual_card_email
    bankAccounts {
      bankId
      accountType
      accountNumber
      routingNumber
      defaults
      isActive
      __typename
    }
    achEnabled
    virtualCardEnabled
    checkIssuingEnabled
    checkIssuingPayableTo
    checkIssuingStreet
    checkIssuingCity
    checkIssuingState
    checkIssuingCountry
    checkIssuingPostalCode
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPaidolVendorQueryVariables,
  APITypes.GetPaidolVendorQuery
>;
export const listPaidolVendors = /* GraphQL */ `query ListPaidolVendors(
  $filter: ModelPaidolVendorFilterInput
  $limit: Int
  $nextToken: String
) {
  listPaidolVendors(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      user_vendor_id
      is_migration
      paidol_id
      vendor_paidol_id
      priority_active
      payment_terms
      priority_account_balance
      contact_name
      priority_billing_code
      priority_repeate_code
      priority_group_code
      email_addresses
      priority_document_delivery
      priority_regular_inv
      priority_summary_inv
      foreign_name
      remarks
      box_1099
      form_code_1099
      remark_1
      priority_response
      cron_status
      priority_status
      priority_supplier_id
      virtual_card_email
      achEnabled
      virtualCardEnabled
      checkIssuingEnabled
      checkIssuingPayableTo
      checkIssuingStreet
      checkIssuingCity
      checkIssuingState
      checkIssuingCountry
      checkIssuingPostalCode
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPaidolVendorsQueryVariables,
  APITypes.ListPaidolVendorsQuery
>;
export const vendorByPaidol = /* GraphQL */ `query VendorByPaidol(
  $paidol_id: ID!
  $user_vendor_id: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPaidolVendorFilterInput
  $limit: Int
  $nextToken: String
) {
  vendorByPaidol(
    paidol_id: $paidol_id
    user_vendor_id: $user_vendor_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user_vendor_id
      is_migration
      paidol_id
      vendor_paidol_id
      priority_active
      payment_terms
      priority_account_balance
      contact_name
      priority_billing_code
      priority_repeate_code
      priority_group_code
      email_addresses
      priority_document_delivery
      priority_regular_inv
      priority_summary_inv
      foreign_name
      remarks
      box_1099
      form_code_1099
      remark_1
      priority_response
      cron_status
      priority_status
      priority_supplier_id
      virtual_card_email
      achEnabled
      virtualCardEnabled
      checkIssuingEnabled
      checkIssuingPayableTo
      checkIssuingStreet
      checkIssuingCity
      checkIssuingState
      checkIssuingCountry
      checkIssuingPostalCode
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VendorByPaidolQueryVariables,
  APITypes.VendorByPaidolQuery
>;
export const paidolVendorsByCronStatus = /* GraphQL */ `query PaidolVendorsByCronStatus(
  $cron_status: CronStatus!
  $sortDirection: ModelSortDirection
  $filter: ModelPaidolVendorFilterInput
  $limit: Int
  $nextToken: String
) {
  paidolVendorsByCronStatus(
    cron_status: $cron_status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user_vendor_id
      is_migration
      paidol_id
      vendor_paidol_id
      priority_active
      payment_terms
      priority_account_balance
      contact_name
      priority_billing_code
      priority_repeate_code
      priority_group_code
      email_addresses
      priority_document_delivery
      priority_regular_inv
      priority_summary_inv
      foreign_name
      remarks
      box_1099
      form_code_1099
      remark_1
      priority_response
      cron_status
      priority_status
      priority_supplier_id
      virtual_card_email
      achEnabled
      virtualCardEnabled
      checkIssuingEnabled
      checkIssuingPayableTo
      checkIssuingStreet
      checkIssuingCity
      checkIssuingState
      checkIssuingCountry
      checkIssuingPostalCode
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PaidolVendorsByCronStatusQueryVariables,
  APITypes.PaidolVendorsByCronStatusQuery
>;
export const searchPaidolVendors = /* GraphQL */ `query SearchPaidolVendors(
  $filter: SearchablePaidolVendorFilterInput
  $sort: [SearchablePaidolVendorSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchablePaidolVendorAggregationInput]
) {
  searchPaidolVendors(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      user_vendor_id
      is_migration
      paidol_id
      vendor_paidol_id
      priority_active
      payment_terms
      priority_account_balance
      contact_name
      priority_billing_code
      priority_repeate_code
      priority_group_code
      email_addresses
      priority_document_delivery
      priority_regular_inv
      priority_summary_inv
      foreign_name
      remarks
      box_1099
      form_code_1099
      remark_1
      priority_response
      cron_status
      priority_status
      priority_supplier_id
      virtual_card_email
      achEnabled
      virtualCardEnabled
      checkIssuingEnabled
      checkIssuingPayableTo
      checkIssuingStreet
      checkIssuingCity
      checkIssuingState
      checkIssuingCountry
      checkIssuingPostalCode
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchPaidolVendorsQueryVariables,
  APITypes.SearchPaidolVendorsQuery
>;
export const getInvoice = /* GraphQL */ `query GetInvoice($id: ID!) {
  getInvoice(id: $id) {
    id
    id_base58
    id_base58_short
    payment_file_queue_id
    payment_file {
      id
      user_id
      paidol_id
      key
      status
      processed_at
      error_messages
      num_vendors
      num_payments
      num_success
      num_failed
      createdAt
      filename
      supplier_vendor_paidol_ids
      referenced_vendors
      invoice_ids
      invoice_spx_ids
      priority_status
      priority_file_id
      priority_process_on
      priority_response
      updatedAt
      __typename
    }
    buyer_paidol_id
    paidol_vendor_id
    paidol_vendor {
      id
      user_vendor_id
      is_migration
      paidol_id
      vendor_paidol_id
      priority_active
      payment_terms
      priority_account_balance
      contact_name
      priority_billing_code
      priority_repeate_code
      priority_group_code
      email_addresses
      priority_document_delivery
      priority_regular_inv
      priority_summary_inv
      foreign_name
      remarks
      box_1099
      form_code_1099
      remark_1
      priority_response
      cron_status
      priority_status
      priority_supplier_id
      virtual_card_email
      achEnabled
      virtualCardEnabled
      checkIssuingEnabled
      checkIssuingPayableTo
      checkIssuingStreet
      checkIssuingCity
      checkIssuingState
      checkIssuingCountry
      checkIssuingPostalCode
      createdAt
      updatedAt
      __typename
    }
    supplier_paidol_id
    supplier_paidol {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      createdAt
      updatedAt
      owner
      __typename
    }
    user_invoice_id
    date
    balance_due {
      value
      currency
      __typename
    }
    createdAt
    updatedAt
    transaction_id
    priority_payment_id
    priority_payment_status
    priority_payment_type
    priority_payment_date
    priority_payment_auth_message
    priority_response
    status
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInvoiceQueryVariables,
  APITypes.GetInvoiceQuery
>;
export const listInvoices = /* GraphQL */ `query ListInvoices(
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      id_base58
      id_base58_short
      payment_file_queue_id
      buyer_paidol_id
      paidol_vendor_id
      supplier_paidol_id
      user_invoice_id
      date
      createdAt
      updatedAt
      transaction_id
      priority_payment_id
      priority_payment_status
      priority_payment_type
      priority_payment_date
      priority_payment_auth_message
      priority_response
      status
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInvoicesQueryVariables,
  APITypes.ListInvoicesQuery
>;
export const invoicesByPaymentFileQueue = /* GraphQL */ `query InvoicesByPaymentFileQueue(
  $payment_file_queue_id: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  invoicesByPaymentFileQueue(
    payment_file_queue_id: $payment_file_queue_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      id_base58
      id_base58_short
      payment_file_queue_id
      buyer_paidol_id
      paidol_vendor_id
      supplier_paidol_id
      user_invoice_id
      date
      createdAt
      updatedAt
      transaction_id
      priority_payment_id
      priority_payment_status
      priority_payment_type
      priority_payment_date
      priority_payment_auth_message
      priority_response
      status
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InvoicesByPaymentFileQueueQueryVariables,
  APITypes.InvoicesByPaymentFileQueueQuery
>;
export const invoicesByBuyerPaidol = /* GraphQL */ `query InvoicesByBuyerPaidol(
  $buyer_paidol_id: ID!
  $date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  invoicesByBuyerPaidol(
    buyer_paidol_id: $buyer_paidol_id
    date: $date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      id_base58
      id_base58_short
      payment_file_queue_id
      buyer_paidol_id
      paidol_vendor_id
      supplier_paidol_id
      user_invoice_id
      date
      createdAt
      updatedAt
      transaction_id
      priority_payment_id
      priority_payment_status
      priority_payment_type
      priority_payment_date
      priority_payment_auth_message
      priority_response
      status
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InvoicesByBuyerPaidolQueryVariables,
  APITypes.InvoicesByBuyerPaidolQuery
>;
export const invoicesByBuyerPaidolIdAndSupplierPaidolIdAndUserInvoiceId = /* GraphQL */ `query InvoicesByBuyerPaidolIdAndSupplierPaidolIdAndUserInvoiceId(
  $buyer_paidol_id: ID!
  $user_invoice_idSupplier_paidol_id: ModelInvoiceByBuyerPaidolIdAndSupplierPaidolIdAndUserInvoiceIdCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  invoicesByBuyerPaidolIdAndSupplierPaidolIdAndUserInvoiceId(
    buyer_paidol_id: $buyer_paidol_id
    user_invoice_idSupplier_paidol_id: $user_invoice_idSupplier_paidol_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      id_base58
      id_base58_short
      payment_file_queue_id
      buyer_paidol_id
      paidol_vendor_id
      supplier_paidol_id
      user_invoice_id
      date
      createdAt
      updatedAt
      transaction_id
      priority_payment_id
      priority_payment_status
      priority_payment_type
      priority_payment_date
      priority_payment_auth_message
      priority_response
      status
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InvoicesByBuyerPaidolIdAndSupplierPaidolIdAndUserInvoiceIdQueryVariables,
  APITypes.InvoicesByBuyerPaidolIdAndSupplierPaidolIdAndUserInvoiceIdQuery
>;
export const invoicesByBuyerPaidolIdAndUpdatedAt = /* GraphQL */ `query InvoicesByBuyerPaidolIdAndUpdatedAt(
  $buyer_paidol_id: ID!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  invoicesByBuyerPaidolIdAndUpdatedAt(
    buyer_paidol_id: $buyer_paidol_id
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      id_base58
      id_base58_short
      payment_file_queue_id
      buyer_paidol_id
      paidol_vendor_id
      supplier_paidol_id
      user_invoice_id
      date
      createdAt
      updatedAt
      transaction_id
      priority_payment_id
      priority_payment_status
      priority_payment_type
      priority_payment_date
      priority_payment_auth_message
      priority_response
      status
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InvoicesByBuyerPaidolIdAndUpdatedAtQueryVariables,
  APITypes.InvoicesByBuyerPaidolIdAndUpdatedAtQuery
>;
export const invoicesByUserInvoiceId = /* GraphQL */ `query InvoicesByUserInvoiceId(
  $user_invoice_id: String!
  $buyer_paidol_idSupplier_paidol_id: ModelInvoiceByUserInvoiceIdCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  invoicesByUserInvoiceId(
    user_invoice_id: $user_invoice_id
    buyer_paidol_idSupplier_paidol_id: $buyer_paidol_idSupplier_paidol_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      id_base58
      id_base58_short
      payment_file_queue_id
      buyer_paidol_id
      paidol_vendor_id
      supplier_paidol_id
      user_invoice_id
      date
      createdAt
      updatedAt
      transaction_id
      priority_payment_id
      priority_payment_status
      priority_payment_type
      priority_payment_date
      priority_payment_auth_message
      priority_response
      status
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InvoicesByUserInvoiceIdQueryVariables,
  APITypes.InvoicesByUserInvoiceIdQuery
>;
export const invoicesByPriorityPaymentId = /* GraphQL */ `query InvoicesByPriorityPaymentId(
  $priority_payment_id: String!
  $sortDirection: ModelSortDirection
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  invoicesByPriorityPaymentId(
    priority_payment_id: $priority_payment_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      id_base58
      id_base58_short
      payment_file_queue_id
      buyer_paidol_id
      paidol_vendor_id
      supplier_paidol_id
      user_invoice_id
      date
      createdAt
      updatedAt
      transaction_id
      priority_payment_id
      priority_payment_status
      priority_payment_type
      priority_payment_date
      priority_payment_auth_message
      priority_response
      status
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InvoicesByPriorityPaymentIdQueryVariables,
  APITypes.InvoicesByPriorityPaymentIdQuery
>;
export const invoicesByPriorityPaymentStatus = /* GraphQL */ `query InvoicesByPriorityPaymentStatus(
  $priority_payment_status: String!
  $sortDirection: ModelSortDirection
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  invoicesByPriorityPaymentStatus(
    priority_payment_status: $priority_payment_status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      id_base58
      id_base58_short
      payment_file_queue_id
      buyer_paidol_id
      paidol_vendor_id
      supplier_paidol_id
      user_invoice_id
      date
      createdAt
      updatedAt
      transaction_id
      priority_payment_id
      priority_payment_status
      priority_payment_type
      priority_payment_date
      priority_payment_auth_message
      priority_response
      status
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InvoicesByPriorityPaymentStatusQueryVariables,
  APITypes.InvoicesByPriorityPaymentStatusQuery
>;
export const getSupplierFileQueue = /* GraphQL */ `query GetSupplierFileQueue($id: ID!) {
  getSupplierFileQueue(id: $id) {
    id
    user_id
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    paidol_id
    paidol {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      createdAt
      updatedAt
      owner
      __typename
    }
    key
    status
    processed_at
    error_messages
    num_vendors
    num_success
    num_failed
    createdAt
    is_migration
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSupplierFileQueueQueryVariables,
  APITypes.GetSupplierFileQueueQuery
>;
export const listSupplierFileQueues = /* GraphQL */ `query ListSupplierFileQueues(
  $filter: ModelSupplierFileQueueFilterInput
  $limit: Int
  $nextToken: String
) {
  listSupplierFileQueues(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user_id
      paidol_id
      key
      status
      processed_at
      error_messages
      num_vendors
      num_success
      num_failed
      createdAt
      is_migration
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSupplierFileQueuesQueryVariables,
  APITypes.ListSupplierFileQueuesQuery
>;
export const supplierFileQueuesByPaidol = /* GraphQL */ `query SupplierFileQueuesByPaidol(
  $paidol_id: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSupplierFileQueueFilterInput
  $limit: Int
  $nextToken: String
) {
  supplierFileQueuesByPaidol(
    paidol_id: $paidol_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user_id
      paidol_id
      key
      status
      processed_at
      error_messages
      num_vendors
      num_success
      num_failed
      createdAt
      is_migration
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SupplierFileQueuesByPaidolQueryVariables,
  APITypes.SupplierFileQueuesByPaidolQuery
>;
export const getPaymentStatusDownload = /* GraphQL */ `query GetPaymentStatusDownload($id: ID!) {
  getPaymentStatusDownload(id: $id) {
    id
    user_id
    paidol_id
    start_date
    end_date
    s3_key
    cron_status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPaymentStatusDownloadQueryVariables,
  APITypes.GetPaymentStatusDownloadQuery
>;
export const listPaymentStatusDownloads = /* GraphQL */ `query ListPaymentStatusDownloads(
  $filter: ModelPaymentStatusDownloadFilterInput
  $limit: Int
  $nextToken: String
) {
  listPaymentStatusDownloads(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user_id
      paidol_id
      start_date
      end_date
      s3_key
      cron_status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPaymentStatusDownloadsQueryVariables,
  APITypes.ListPaymentStatusDownloadsQuery
>;
export const paymentStatusDownloadByPaidolId = /* GraphQL */ `query PaymentStatusDownloadByPaidolId(
  $paidol_id: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPaymentStatusDownloadFilterInput
  $limit: Int
  $nextToken: String
) {
  paymentStatusDownloadByPaidolId(
    paidol_id: $paidol_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user_id
      paidol_id
      start_date
      end_date
      s3_key
      cron_status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PaymentStatusDownloadByPaidolIdQueryVariables,
  APITypes.PaymentStatusDownloadByPaidolIdQuery
>;
export const getPaidolHighnoteIntegration = /* GraphQL */ `query GetPaidolHighnoteIntegration($id: ID!) {
  getPaidolHighnoteIntegration(id: $id) {
    id
    paidolId
    userId
    legalBusinessName
    businessAccountHolderId
    primaryAuthorizedPersonId
    financialAccountId
    externalFinancialBankAccountId
    hasInitialFunding
    accountHolderCardProductApplicationId
    isApplicationAccepted
    isDisconnected
    highnoteProductId
    highnoteProduct {
      id
      type
      isActive
      createdAt
      updatedAt
      __typename
    }
    finicityCustomerId
    dwollaCustomerId
    cardProfileId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPaidolHighnoteIntegrationQueryVariables,
  APITypes.GetPaidolHighnoteIntegrationQuery
>;
export const listPaidolHighnoteIntegrations = /* GraphQL */ `query ListPaidolHighnoteIntegrations(
  $filter: ModelPaidolHighnoteIntegrationFilterInput
  $limit: Int
  $nextToken: String
) {
  listPaidolHighnoteIntegrations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      paidolId
      userId
      legalBusinessName
      businessAccountHolderId
      primaryAuthorizedPersonId
      financialAccountId
      externalFinancialBankAccountId
      hasInitialFunding
      accountHolderCardProductApplicationId
      isApplicationAccepted
      isDisconnected
      highnoteProductId
      finicityCustomerId
      dwollaCustomerId
      cardProfileId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPaidolHighnoteIntegrationsQueryVariables,
  APITypes.ListPaidolHighnoteIntegrationsQuery
>;
export const listPaidolHighnoteIntegrationByPaidolId = /* GraphQL */ `query ListPaidolHighnoteIntegrationByPaidolId(
  $paidolId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPaidolHighnoteIntegrationFilterInput
  $limit: Int
  $nextToken: String
) {
  listPaidolHighnoteIntegrationByPaidolId(
    paidolId: $paidolId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      paidolId
      userId
      legalBusinessName
      businessAccountHolderId
      primaryAuthorizedPersonId
      financialAccountId
      externalFinancialBankAccountId
      hasInitialFunding
      accountHolderCardProductApplicationId
      isApplicationAccepted
      isDisconnected
      highnoteProductId
      finicityCustomerId
      dwollaCustomerId
      cardProfileId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPaidolHighnoteIntegrationByPaidolIdQueryVariables,
  APITypes.ListPaidolHighnoteIntegrationByPaidolIdQuery
>;
export const getHighnoteProduct = /* GraphQL */ `query GetHighnoteProduct($id: ID!) {
  getHighnoteProduct(id: $id) {
    id
    type
    isActive
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetHighnoteProductQueryVariables,
  APITypes.GetHighnoteProductQuery
>;
export const listHighnoteProducts = /* GraphQL */ `query ListHighnoteProducts(
  $filter: ModelHighnoteProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listHighnoteProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      isActive
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListHighnoteProductsQueryVariables,
  APITypes.ListHighnoteProductsQuery
>;
export const getHighnotePaymentCard = /* GraphQL */ `query GetHighnotePaymentCard($paymentCardId: ID!) {
  getHighnotePaymentCard(paymentCardId: $paymentCardId) {
    paymentCardId
    paidolId
    authPaidolId
    paidolUsers {
      nextToken
      __typename
    }
    name
    cardGroupId
    group {
      id
      paidolId
      authPaidolId
      name
      isMonthlyBudgetUnlimited
      createdAt
      updatedAt
      __typename
    }
    status
    bin
    last4
    network
    formFactor
    expirationDate
    budget {
      __typename
    }
    hasPin
    procoreEmployee {
      id
      name
      email_address
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetHighnotePaymentCardQueryVariables,
  APITypes.GetHighnotePaymentCardQuery
>;
export const listHighnotePaymentCards = /* GraphQL */ `query ListHighnotePaymentCards(
  $paymentCardId: ID
  $filter: ModelHighnotePaymentCardFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listHighnotePaymentCards(
    paymentCardId: $paymentCardId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      paymentCardId
      paidolId
      authPaidolId
      name
      cardGroupId
      status
      bin
      last4
      network
      formFactor
      expirationDate
      hasPin
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListHighnotePaymentCardsQueryVariables,
  APITypes.ListHighnotePaymentCardsQuery
>;
export const listHighnotePaymentCardsByPaidolId = /* GraphQL */ `query ListHighnotePaymentCardsByPaidolId(
  $paidolId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelHighnotePaymentCardFilterInput
  $limit: Int
  $nextToken: String
) {
  listHighnotePaymentCardsByPaidolId(
    paidolId: $paidolId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      paymentCardId
      paidolId
      authPaidolId
      name
      cardGroupId
      status
      bin
      last4
      network
      formFactor
      expirationDate
      hasPin
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListHighnotePaymentCardsByPaidolIdQueryVariables,
  APITypes.ListHighnotePaymentCardsByPaidolIdQuery
>;
export const searchHighnotePaymentCards = /* GraphQL */ `query SearchHighnotePaymentCards(
  $filter: SearchableHighnotePaymentCardFilterInput
  $sort: [SearchableHighnotePaymentCardSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableHighnotePaymentCardAggregationInput]
) {
  searchHighnotePaymentCards(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      paymentCardId
      paidolId
      authPaidolId
      name
      cardGroupId
      status
      bin
      last4
      network
      formFactor
      expirationDate
      hasPin
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchHighnotePaymentCardsQueryVariables,
  APITypes.SearchHighnotePaymentCardsQuery
>;
export const getHighnotePaymentCardSpendRules = /* GraphQL */ `query GetHighnotePaymentCardSpendRules($id: ID!) {
  getHighnotePaymentCardSpendRules(id: $id) {
    id
    paymentCardId
    name
    maximumAmount {
      value
      currencyCode
      __typename
    }
    merchantCategoryAllowed
    merchantCategoryBlocked
    merchantCountryAllowed
    merchantCountryBlocked
    streetAddressAllowed
    streetAddressBlocked
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetHighnotePaymentCardSpendRulesQueryVariables,
  APITypes.GetHighnotePaymentCardSpendRulesQuery
>;
export const listHighnotePaymentCardSpendRules = /* GraphQL */ `query ListHighnotePaymentCardSpendRules(
  $id: ID
  $filter: ModelHighnotePaymentCardSpendRulesFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listHighnotePaymentCardSpendRules(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      paymentCardId
      name
      merchantCategoryAllowed
      merchantCategoryBlocked
      merchantCountryAllowed
      merchantCountryBlocked
      streetAddressAllowed
      streetAddressBlocked
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListHighnotePaymentCardSpendRulesQueryVariables,
  APITypes.ListHighnotePaymentCardSpendRulesQuery
>;
export const listHighnotePaymentCardSpendRulesByPaymentCardId = /* GraphQL */ `query ListHighnotePaymentCardSpendRulesByPaymentCardId(
  $paymentCardId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelHighnotePaymentCardSpendRulesFilterInput
  $limit: Int
  $nextToken: String
) {
  listHighnotePaymentCardSpendRulesByPaymentCardId(
    paymentCardId: $paymentCardId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      paymentCardId
      name
      merchantCategoryAllowed
      merchantCategoryBlocked
      merchantCountryAllowed
      merchantCountryBlocked
      streetAddressAllowed
      streetAddressBlocked
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListHighnotePaymentCardSpendRulesByPaymentCardIdQueryVariables,
  APITypes.ListHighnotePaymentCardSpendRulesByPaymentCardIdQuery
>;
export const getHighnotePaymentCardVelocityRules = /* GraphQL */ `query GetHighnotePaymentCardVelocityRules($id: ID!) {
  getHighnotePaymentCardVelocityRules(id: $id) {
    id
    paymentCardId
    name
    maximumAmount {
      value
      currencyCode
      __typename
    }
    cumulativeRuleId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetHighnotePaymentCardVelocityRulesQueryVariables,
  APITypes.GetHighnotePaymentCardVelocityRulesQuery
>;
export const listHighnotePaymentCardVelocityRules = /* GraphQL */ `query ListHighnotePaymentCardVelocityRules(
  $id: ID
  $filter: ModelHighnotePaymentCardVelocityRulesFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listHighnotePaymentCardVelocityRules(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      paymentCardId
      name
      cumulativeRuleId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListHighnotePaymentCardVelocityRulesQueryVariables,
  APITypes.ListHighnotePaymentCardVelocityRulesQuery
>;
export const listHighnotePaymentCardVelocityRulesByPaymentCardId = /* GraphQL */ `query ListHighnotePaymentCardVelocityRulesByPaymentCardId(
  $paymentCardId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelHighnotePaymentCardVelocityRulesFilterInput
  $limit: Int
  $nextToken: String
) {
  listHighnotePaymentCardVelocityRulesByPaymentCardId(
    paymentCardId: $paymentCardId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      paymentCardId
      name
      cumulativeRuleId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListHighnotePaymentCardVelocityRulesByPaymentCardIdQueryVariables,
  APITypes.ListHighnotePaymentCardVelocityRulesByPaymentCardIdQuery
>;
export const getBudget = /* GraphQL */ `query GetBudget($paidolId: ID!) {
  getBudget(paidolId: $paidolId) {
    paidolId
    authPaidolId
    budget {
      value
      currency
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetBudgetQueryVariables, APITypes.GetBudgetQuery>;
export const listBudgets = /* GraphQL */ `query ListBudgets(
  $paidolId: ID
  $filter: ModelBudgetFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listBudgets(
    paidolId: $paidolId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      paidolId
      authPaidolId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBudgetsQueryVariables,
  APITypes.ListBudgetsQuery
>;
export const getBudgetSpendByMonth = /* GraphQL */ `query GetBudgetSpendByMonth($paidolId: ID!, $yearAndMonth: String!) {
  getBudgetSpendByMonth(paidolId: $paidolId, yearAndMonth: $yearAndMonth) {
    paidolId
    authPaidolId
    yearAndMonth
    amount
    transactionCount
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBudgetSpendByMonthQueryVariables,
  APITypes.GetBudgetSpendByMonthQuery
>;
export const listBudgetSpendByMonths = /* GraphQL */ `query ListBudgetSpendByMonths(
  $paidolId: ID
  $yearAndMonth: ModelStringKeyConditionInput
  $filter: ModelBudgetSpendByMonthFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listBudgetSpendByMonths(
    paidolId: $paidolId
    yearAndMonth: $yearAndMonth
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      paidolId
      authPaidolId
      yearAndMonth
      amount
      transactionCount
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBudgetSpendByMonthsQueryVariables,
  APITypes.ListBudgetSpendByMonthsQuery
>;
export const getBudgetSpendByMonthAndMerchant = /* GraphQL */ `query GetBudgetSpendByMonthAndMerchant(
  $paidolId: ID!
  $yearAndMonth: String!
  $merchantName: String!
) {
  getBudgetSpendByMonthAndMerchant(
    paidolId: $paidolId
    yearAndMonth: $yearAndMonth
    merchantName: $merchantName
  ) {
    paidolId
    authPaidolId
    yearAndMonth
    merchantName
    amount
    transactionCount
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBudgetSpendByMonthAndMerchantQueryVariables,
  APITypes.GetBudgetSpendByMonthAndMerchantQuery
>;
export const listBudgetSpendByMonthAndMerchants = /* GraphQL */ `query ListBudgetSpendByMonthAndMerchants(
  $paidolId: ID
  $yearAndMonthMerchantName: ModelBudgetSpendByMonthAndMerchantPrimaryCompositeKeyConditionInput
  $filter: ModelBudgetSpendByMonthAndMerchantFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listBudgetSpendByMonthAndMerchants(
    paidolId: $paidolId
    yearAndMonthMerchantName: $yearAndMonthMerchantName
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      paidolId
      authPaidolId
      yearAndMonth
      merchantName
      amount
      transactionCount
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBudgetSpendByMonthAndMerchantsQueryVariables,
  APITypes.ListBudgetSpendByMonthAndMerchantsQuery
>;
export const listBudgetSpendByMonthAndMerchantByAmount = /* GraphQL */ `query ListBudgetSpendByMonthAndMerchantByAmount(
  $paidolId: ID!
  $yearAndMonthAmount: ModelBudgetSpendByMonthAndMerchantByYearAndMonthAndAmountCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBudgetSpendByMonthAndMerchantFilterInput
  $limit: Int
  $nextToken: String
) {
  listBudgetSpendByMonthAndMerchantByAmount(
    paidolId: $paidolId
    yearAndMonthAmount: $yearAndMonthAmount
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      paidolId
      authPaidolId
      yearAndMonth
      merchantName
      amount
      transactionCount
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBudgetSpendByMonthAndMerchantByAmountQueryVariables,
  APITypes.ListBudgetSpendByMonthAndMerchantByAmountQuery
>;
export const getBudgetSpendByMonthAndCategory = /* GraphQL */ `query GetBudgetSpendByMonthAndCategory(
  $paidolId: ID!
  $yearAndMonth: String!
  $category: String!
) {
  getBudgetSpendByMonthAndCategory(
    paidolId: $paidolId
    yearAndMonth: $yearAndMonth
    category: $category
  ) {
    paidolId
    authPaidolId
    yearAndMonth
    category
    amount
    transactionCount
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBudgetSpendByMonthAndCategoryQueryVariables,
  APITypes.GetBudgetSpendByMonthAndCategoryQuery
>;
export const listBudgetSpendByMonthAndCategories = /* GraphQL */ `query ListBudgetSpendByMonthAndCategories(
  $paidolId: ID
  $yearAndMonthCategory: ModelBudgetSpendByMonthAndCategoryPrimaryCompositeKeyConditionInput
  $filter: ModelBudgetSpendByMonthAndCategoryFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listBudgetSpendByMonthAndCategories(
    paidolId: $paidolId
    yearAndMonthCategory: $yearAndMonthCategory
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      paidolId
      authPaidolId
      yearAndMonth
      category
      amount
      transactionCount
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBudgetSpendByMonthAndCategoriesQueryVariables,
  APITypes.ListBudgetSpendByMonthAndCategoriesQuery
>;
export const listBudgetSpendByMonthAndCategoryByAmount = /* GraphQL */ `query ListBudgetSpendByMonthAndCategoryByAmount(
  $paidolId: ID!
  $yearAndMonthAmount: ModelBudgetSpendByMonthAndCategoryByYearAndMonthAndAmountCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBudgetSpendByMonthAndCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listBudgetSpendByMonthAndCategoryByAmount(
    paidolId: $paidolId
    yearAndMonthAmount: $yearAndMonthAmount
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      paidolId
      authPaidolId
      yearAndMonth
      category
      amount
      transactionCount
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBudgetSpendByMonthAndCategoryByAmountQueryVariables,
  APITypes.ListBudgetSpendByMonthAndCategoryByAmountQuery
>;
export const getBudgetSpendByMonthAndPaymentCard = /* GraphQL */ `query GetBudgetSpendByMonthAndPaymentCard(
  $paidolId: ID!
  $yearAndMonth: String!
  $paymentCardId: ID!
) {
  getBudgetSpendByMonthAndPaymentCard(
    paidolId: $paidolId
    yearAndMonth: $yearAndMonth
    paymentCardId: $paymentCardId
  ) {
    paidolId
    authPaidolId
    yearAndMonth
    paymentCardId
    amount
    transactionCount
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBudgetSpendByMonthAndPaymentCardQueryVariables,
  APITypes.GetBudgetSpendByMonthAndPaymentCardQuery
>;
export const listBudgetSpendByMonthAndPaymentCards = /* GraphQL */ `query ListBudgetSpendByMonthAndPaymentCards(
  $paidolId: ID
  $yearAndMonthPaymentCardId: ModelBudgetSpendByMonthAndPaymentCardPrimaryCompositeKeyConditionInput
  $filter: ModelBudgetSpendByMonthAndPaymentCardFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listBudgetSpendByMonthAndPaymentCards(
    paidolId: $paidolId
    yearAndMonthPaymentCardId: $yearAndMonthPaymentCardId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      paidolId
      authPaidolId
      yearAndMonth
      paymentCardId
      amount
      transactionCount
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBudgetSpendByMonthAndPaymentCardsQueryVariables,
  APITypes.ListBudgetSpendByMonthAndPaymentCardsQuery
>;
export const getBudgetSpendByMonthAndJobCodeId = /* GraphQL */ `query GetBudgetSpendByMonthAndJobCodeId(
  $paidolId: ID!
  $yearAndMonth: String!
  $jobCodeId: ID!
) {
  getBudgetSpendByMonthAndJobCodeId(
    paidolId: $paidolId
    yearAndMonth: $yearAndMonth
    jobCodeId: $jobCodeId
  ) {
    paidolId
    authPaidolId
    yearAndMonth
    jobCodeId
    amount
    transactionCount
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBudgetSpendByMonthAndJobCodeIdQueryVariables,
  APITypes.GetBudgetSpendByMonthAndJobCodeIdQuery
>;
export const listBudgetSpendByMonthAndJobCodeIds = /* GraphQL */ `query ListBudgetSpendByMonthAndJobCodeIds(
  $paidolId: ID
  $yearAndMonthJobCodeId: ModelBudgetSpendByMonthAndJobCodeIdPrimaryCompositeKeyConditionInput
  $filter: ModelBudgetSpendByMonthAndJobCodeIdFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listBudgetSpendByMonthAndJobCodeIds(
    paidolId: $paidolId
    yearAndMonthJobCodeId: $yearAndMonthJobCodeId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      paidolId
      authPaidolId
      yearAndMonth
      jobCodeId
      amount
      transactionCount
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBudgetSpendByMonthAndJobCodeIdsQueryVariables,
  APITypes.ListBudgetSpendByMonthAndJobCodeIdsQuery
>;
export const listBudgetSpendByMonthAndJobCodeIdByAmount = /* GraphQL */ `query ListBudgetSpendByMonthAndJobCodeIdByAmount(
  $paidolId: ID!
  $yearAndMonthAmount: ModelBudgetSpendByMonthAndJobCodeIdByYearAndMonthAndJobCodeIdCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBudgetSpendByMonthAndJobCodeIdFilterInput
  $limit: Int
  $nextToken: String
) {
  listBudgetSpendByMonthAndJobCodeIdByAmount(
    paidolId: $paidolId
    yearAndMonthAmount: $yearAndMonthAmount
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      paidolId
      authPaidolId
      yearAndMonth
      jobCodeId
      amount
      transactionCount
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBudgetSpendByMonthAndJobCodeIdByAmountQueryVariables,
  APITypes.ListBudgetSpendByMonthAndJobCodeIdByAmountQuery
>;
export const getMcc = /* GraphQL */ `query GetMcc($id: ID!) {
  getMcc(id: $id) {
    id
    code
    highnoteDescription
    highnoteCategoryDescription
    speedchainDescription
    expenseTypes {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetMccQueryVariables, APITypes.GetMccQuery>;
export const listMccs = /* GraphQL */ `query ListMccs(
  $id: ID
  $filter: ModelMccFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listMccs(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      code
      highnoteDescription
      highnoteCategoryDescription
      speedchainDescription
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListMccsQueryVariables, APITypes.ListMccsQuery>;
export const getExpenseType = /* GraphQL */ `query GetExpenseType($id: ID!) {
  getExpenseType(id: $id) {
    id
    name
    codes {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetExpenseTypeQueryVariables,
  APITypes.GetExpenseTypeQuery
>;
export const listExpenseTypes = /* GraphQL */ `query ListExpenseTypes(
  $id: ID
  $filter: ModelExpenseTypeFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listExpenseTypes(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListExpenseTypesQueryVariables,
  APITypes.ListExpenseTypesQuery
>;
export const getReceiptSummaryField = /* GraphQL */ `query GetReceiptSummaryField($id: ID!) {
  getReceiptSummaryField(id: $id) {
    id
    paidolId
    authPaidolId
    receiptUploadId
    type
    typeConfidenceScore
    label
    labelConfidenceScore
    value
    valueConfidenceScore
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReceiptSummaryFieldQueryVariables,
  APITypes.GetReceiptSummaryFieldQuery
>;
export const listReceiptSummaryFields = /* GraphQL */ `query ListReceiptSummaryFields(
  $id: ID
  $filter: ModelReceiptSummaryFieldFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listReceiptSummaryFields(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      paidolId
      authPaidolId
      receiptUploadId
      type
      typeConfidenceScore
      label
      labelConfidenceScore
      value
      valueConfidenceScore
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReceiptSummaryFieldsQueryVariables,
  APITypes.ListReceiptSummaryFieldsQuery
>;
export const listReceiptSummaryFieldsByPaidolId = /* GraphQL */ `query ListReceiptSummaryFieldsByPaidolId(
  $paidolId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelReceiptSummaryFieldFilterInput
  $limit: Int
  $nextToken: String
) {
  listReceiptSummaryFieldsByPaidolId(
    paidolId: $paidolId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      paidolId
      authPaidolId
      receiptUploadId
      type
      typeConfidenceScore
      label
      labelConfidenceScore
      value
      valueConfidenceScore
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReceiptSummaryFieldsByPaidolIdQueryVariables,
  APITypes.ListReceiptSummaryFieldsByPaidolIdQuery
>;
export const searchReceiptSummaryFields = /* GraphQL */ `query SearchReceiptSummaryFields(
  $filter: SearchableReceiptSummaryFieldFilterInput
  $sort: [SearchableReceiptSummaryFieldSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableReceiptSummaryFieldAggregationInput]
) {
  searchReceiptSummaryFields(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      paidolId
      authPaidolId
      receiptUploadId
      type
      typeConfidenceScore
      label
      labelConfidenceScore
      value
      valueConfidenceScore
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchReceiptSummaryFieldsQueryVariables,
  APITypes.SearchReceiptSummaryFieldsQuery
>;
export const getReceiptLineItem = /* GraphQL */ `query GetReceiptLineItem($id: ID!) {
  getReceiptLineItem(id: $id) {
    id
    paidolId
    authPaidolId
    receiptUploadId
    lineItemFields {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReceiptLineItemQueryVariables,
  APITypes.GetReceiptLineItemQuery
>;
export const listReceiptLineItems = /* GraphQL */ `query ListReceiptLineItems(
  $id: ID
  $filter: ModelReceiptLineItemFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listReceiptLineItems(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      paidolId
      authPaidolId
      receiptUploadId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReceiptLineItemsQueryVariables,
  APITypes.ListReceiptLineItemsQuery
>;
export const listReceiptLineItemByPaidolId = /* GraphQL */ `query ListReceiptLineItemByPaidolId(
  $paidolId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelReceiptLineItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listReceiptLineItemByPaidolId(
    paidolId: $paidolId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      paidolId
      authPaidolId
      receiptUploadId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReceiptLineItemByPaidolIdQueryVariables,
  APITypes.ListReceiptLineItemByPaidolIdQuery
>;
export const searchReceiptLineItems = /* GraphQL */ `query SearchReceiptLineItems(
  $filter: SearchableReceiptLineItemFilterInput
  $sort: [SearchableReceiptLineItemSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableReceiptLineItemAggregationInput]
) {
  searchReceiptLineItems(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      paidolId
      authPaidolId
      receiptUploadId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchReceiptLineItemsQueryVariables,
  APITypes.SearchReceiptLineItemsQuery
>;
export const getReceiptLineItemField = /* GraphQL */ `query GetReceiptLineItemField($id: ID!) {
  getReceiptLineItemField(id: $id) {
    id
    paidolId
    authPaidolId
    receiptLineItemId
    type
    typeConfidenceScore
    label
    labelConfidenceScore
    value
    valueConfidenceScore
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReceiptLineItemFieldQueryVariables,
  APITypes.GetReceiptLineItemFieldQuery
>;
export const listReceiptLineItemFields = /* GraphQL */ `query ListReceiptLineItemFields(
  $id: ID
  $filter: ModelReceiptLineItemFieldFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listReceiptLineItemFields(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      paidolId
      authPaidolId
      receiptLineItemId
      type
      typeConfidenceScore
      label
      labelConfidenceScore
      value
      valueConfidenceScore
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReceiptLineItemFieldsQueryVariables,
  APITypes.ListReceiptLineItemFieldsQuery
>;
export const listReceiptLineItemFieldsByPaidolId = /* GraphQL */ `query ListReceiptLineItemFieldsByPaidolId(
  $paidolId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelReceiptLineItemFieldFilterInput
  $limit: Int
  $nextToken: String
) {
  listReceiptLineItemFieldsByPaidolId(
    paidolId: $paidolId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      paidolId
      authPaidolId
      receiptLineItemId
      type
      typeConfidenceScore
      label
      labelConfidenceScore
      value
      valueConfidenceScore
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReceiptLineItemFieldsByPaidolIdQueryVariables,
  APITypes.ListReceiptLineItemFieldsByPaidolIdQuery
>;
export const searchReceiptLineItemFields = /* GraphQL */ `query SearchReceiptLineItemFields(
  $filter: SearchableReceiptLineItemFieldFilterInput
  $sort: [SearchableReceiptLineItemFieldSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableReceiptLineItemFieldAggregationInput]
) {
  searchReceiptLineItemFields(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      paidolId
      authPaidolId
      receiptLineItemId
      type
      typeConfidenceScore
      label
      labelConfidenceScore
      value
      valueConfidenceScore
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchReceiptLineItemFieldsQueryVariables,
  APITypes.SearchReceiptLineItemFieldsQuery
>;
export const getReceiptUpload = /* GraphQL */ `query GetReceiptUpload($id: ID!) {
  getReceiptUpload(id: $id) {
    id
    paidolId
    authPaidolId
    document
    status
    summaryFields {
      nextToken
      __typename
    }
    lineItems {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReceiptUploadQueryVariables,
  APITypes.GetReceiptUploadQuery
>;
export const listReceiptUploads = /* GraphQL */ `query ListReceiptUploads(
  $id: ID
  $filter: ModelReceiptUploadFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listReceiptUploads(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      paidolId
      authPaidolId
      document
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReceiptUploadsQueryVariables,
  APITypes.ListReceiptUploadsQuery
>;
export const listReceiptUploadsByPaidolId = /* GraphQL */ `query ListReceiptUploadsByPaidolId(
  $paidolId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelReceiptUploadFilterInput
  $limit: Int
  $nextToken: String
) {
  listReceiptUploadsByPaidolId(
    paidolId: $paidolId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      paidolId
      authPaidolId
      document
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReceiptUploadsByPaidolIdQueryVariables,
  APITypes.ListReceiptUploadsByPaidolIdQuery
>;
export const searchReceiptUploads = /* GraphQL */ `query SearchReceiptUploads(
  $filter: SearchableReceiptUploadFilterInput
  $sort: [SearchableReceiptUploadSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableReceiptUploadAggregationInput]
) {
  searchReceiptUploads(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      paidolId
      authPaidolId
      document
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchReceiptUploadsQueryVariables,
  APITypes.SearchReceiptUploadsQuery
>;
export const getTransaction = /* GraphQL */ `query GetTransaction($id: ID!) {
  getTransaction(id: $id) {
    id
    paidolId
    authPaidolId
    transactionDate
    reimbursementTransactionId
    reimbursementTransaction {
      id
      paidolId
      authPaidolId
      dwollaTransferId
      transactionDate
      status
      checkrunStatus
      note
      contestNote
      deniedNote
      merchantName
      receiptLineItems
      jobCodeId
      reimbursementDate
      paidolUserId
      transactionType
      expenseTypeId
      receiptId
      procoreProjectId
      procoreWBSCodeId
      createdAt
      updatedAt
      __typename
    }
    highnoteTransactionId
    highnoteTransaction {
      transactionId
      paymentCardId
      paidolId
      authPaidolId
      cardGroupId
      status
      transactionDate
      merchantName
      merchantCategoryCode
      merchantCategory
      transactionEvents
      note
      jobCodeId
      procoreCompanyId
      procoreProjectId
      procoreWBSCodeId
      procoreDirectCostId
      procoreDirectCostLineItemId
      agaveProjectId
      agaveCostCodeId
      agaveCostTypeId
      agaveExpenseId
      agaveVendorId
      receiptPath
      receiptId
      erpApprovalStatus
      last4
      userId
      email
      approvedAmountValue
      approvedAmountCurrencyCode
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTransactionQueryVariables,
  APITypes.GetTransactionQuery
>;
export const listTransactions = /* GraphQL */ `query ListTransactions(
  $id: ID
  $filter: ModelTransactionFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listTransactions(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      paidolId
      authPaidolId
      transactionDate
      reimbursementTransactionId
      highnoteTransactionId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTransactionsQueryVariables,
  APITypes.ListTransactionsQuery
>;
export const listTransactionsByPaidolId = /* GraphQL */ `query ListTransactionsByPaidolId(
  $paidolId: ID!
  $transactionDate: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  listTransactionsByPaidolId(
    paidolId: $paidolId
    transactionDate: $transactionDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      paidolId
      authPaidolId
      transactionDate
      reimbursementTransactionId
      highnoteTransactionId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTransactionsByPaidolIdQueryVariables,
  APITypes.ListTransactionsByPaidolIdQuery
>;
export const searchTransactions = /* GraphQL */ `query SearchTransactions(
  $filter: SearchableTransactionFilterInput
  $sort: [SearchableTransactionSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableTransactionAggregationInput]
) {
  searchTransactions(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      paidolId
      authPaidolId
      transactionDate
      reimbursementTransactionId
      highnoteTransactionId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchTransactionsQueryVariables,
  APITypes.SearchTransactionsQuery
>;
export const getReimbursementTransaction = /* GraphQL */ `query GetReimbursementTransaction($id: ID!) {
  getReimbursementTransaction(id: $id) {
    id
    paidolId
    authPaidolId
    dwollaTransferId
    transactionDate
    amount {
      value
      currencyCode
      __typename
    }
    status
    checkrunStatus
    note
    contestNote
    deniedNote
    merchantName
    receiptLineItems
    jobCodeId
    jobCode {
      id
      paidolId
      authPaidolId
      description
      code
      isActive
      budget
      jobCodeImportId
      createdAt
      updatedAt
      __typename
    }
    reimbursementDate
    paidolUserId
    paidolUser {
      id
      paidol_id
      user_id
      email
      position
      phone
      roles
      is_test_fixture
      authorizedUserId
      authorizedUserApplicationId
      allowedJobCodes
      employee_id
      createdAt
      updatedAt
      __typename
    }
    transactionType
    expenseTypeId
    expenseType {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    receiptId
    receipt {
      id
      paidolId
      authPaidolId
      document
      status
      createdAt
      updatedAt
      __typename
    }
    procoreProjectId
    procoreProject {
      id
      active
      display_name
      name
      created_at
      start_date
      completion_date
      project_number
      total_value
      __typename
    }
    procoreWBSCodeId
    procoreWBSCode {
      id
      flat_code
      flat_name
      description
      status
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReimbursementTransactionQueryVariables,
  APITypes.GetReimbursementTransactionQuery
>;
export const listReimbursementTransactions = /* GraphQL */ `query ListReimbursementTransactions(
  $id: ID
  $filter: ModelReimbursementTransactionFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listReimbursementTransactions(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      paidolId
      authPaidolId
      dwollaTransferId
      transactionDate
      status
      checkrunStatus
      note
      contestNote
      deniedNote
      merchantName
      receiptLineItems
      jobCodeId
      reimbursementDate
      paidolUserId
      transactionType
      expenseTypeId
      receiptId
      procoreProjectId
      procoreWBSCodeId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReimbursementTransactionsQueryVariables,
  APITypes.ListReimbursementTransactionsQuery
>;
export const listReimbursementTransactionsByPaidolId = /* GraphQL */ `query ListReimbursementTransactionsByPaidolId(
  $paidolId: ID!
  $transactionDate: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReimbursementTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  listReimbursementTransactionsByPaidolId(
    paidolId: $paidolId
    transactionDate: $transactionDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      paidolId
      authPaidolId
      dwollaTransferId
      transactionDate
      status
      checkrunStatus
      note
      contestNote
      deniedNote
      merchantName
      receiptLineItems
      jobCodeId
      reimbursementDate
      paidolUserId
      transactionType
      expenseTypeId
      receiptId
      procoreProjectId
      procoreWBSCodeId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReimbursementTransactionsByPaidolIdQueryVariables,
  APITypes.ListReimbursementTransactionsByPaidolIdQuery
>;
export const reimbursementTransactionByPaidolUserId = /* GraphQL */ `query ReimbursementTransactionByPaidolUserId(
  $paidolUserId: ID!
  $paidolId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReimbursementTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  reimbursementTransactionByPaidolUserId(
    paidolUserId: $paidolUserId
    paidolId: $paidolId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      paidolId
      authPaidolId
      dwollaTransferId
      transactionDate
      status
      checkrunStatus
      note
      contestNote
      deniedNote
      merchantName
      receiptLineItems
      jobCodeId
      reimbursementDate
      paidolUserId
      transactionType
      expenseTypeId
      receiptId
      procoreProjectId
      procoreWBSCodeId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ReimbursementTransactionByPaidolUserIdQueryVariables,
  APITypes.ReimbursementTransactionByPaidolUserIdQuery
>;
export const reimbursementTransactionByExpenseTypeId = /* GraphQL */ `query ReimbursementTransactionByExpenseTypeId(
  $expenseTypeId: ID!
  $paidolId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReimbursementTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  reimbursementTransactionByExpenseTypeId(
    expenseTypeId: $expenseTypeId
    paidolId: $paidolId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      paidolId
      authPaidolId
      dwollaTransferId
      transactionDate
      status
      checkrunStatus
      note
      contestNote
      deniedNote
      merchantName
      receiptLineItems
      jobCodeId
      reimbursementDate
      paidolUserId
      transactionType
      expenseTypeId
      receiptId
      procoreProjectId
      procoreWBSCodeId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ReimbursementTransactionByExpenseTypeIdQueryVariables,
  APITypes.ReimbursementTransactionByExpenseTypeIdQuery
>;
export const reimbursementTransactionByReceiptId = /* GraphQL */ `query ReimbursementTransactionByReceiptId(
  $receiptId: ID!
  $paidolId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReimbursementTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  reimbursementTransactionByReceiptId(
    receiptId: $receiptId
    paidolId: $paidolId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      paidolId
      authPaidolId
      dwollaTransferId
      transactionDate
      status
      checkrunStatus
      note
      contestNote
      deniedNote
      merchantName
      receiptLineItems
      jobCodeId
      reimbursementDate
      paidolUserId
      transactionType
      expenseTypeId
      receiptId
      procoreProjectId
      procoreWBSCodeId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ReimbursementTransactionByReceiptIdQueryVariables,
  APITypes.ReimbursementTransactionByReceiptIdQuery
>;
export const searchReimbursementTransactions = /* GraphQL */ `query SearchReimbursementTransactions(
  $filter: SearchableReimbursementTransactionFilterInput
  $sort: [SearchableReimbursementTransactionSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableReimbursementTransactionAggregationInput]
) {
  searchReimbursementTransactions(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      paidolId
      authPaidolId
      dwollaTransferId
      transactionDate
      status
      checkrunStatus
      note
      contestNote
      deniedNote
      merchantName
      receiptLineItems
      jobCodeId
      reimbursementDate
      paidolUserId
      transactionType
      expenseTypeId
      receiptId
      procoreProjectId
      procoreWBSCodeId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchReimbursementTransactionsQueryVariables,
  APITypes.SearchReimbursementTransactionsQuery
>;
export const getHighnoteTransaction = /* GraphQL */ `query GetHighnoteTransaction($transactionId: ID!) {
  getHighnoteTransaction(transactionId: $transactionId) {
    transactionId
    paymentCardId
    paymentCard {
      paymentCardId
      paidolId
      authPaidolId
      name
      cardGroupId
      status
      bin
      last4
      network
      formFactor
      expirationDate
      hasPin
      createdAt
      updatedAt
      __typename
    }
    paidolId
    authPaidolId
    cardGroupId
    cardGroup {
      id
      paidolId
      authPaidolId
      name
      isMonthlyBudgetUnlimited
      createdAt
      updatedAt
      __typename
    }
    status
    transactionDate
    approvedAmount {
      value
      currencyCode
      __typename
    }
    merchantName
    merchantCategoryCode
    merchantCategory
    transactionEvents
    note
    jobCodeId
    jobCode {
      id
      paidolId
      authPaidolId
      description
      code
      isActive
      budget
      jobCodeImportId
      createdAt
      updatedAt
      __typename
    }
    procoreCompanyId
    procoreCompany {
      id
      name
      is_active
      logo_url
      __typename
    }
    procoreProjectId
    procoreProject {
      id
      active
      display_name
      name
      created_at
      start_date
      completion_date
      project_number
      total_value
      __typename
    }
    procoreWBSCodeId
    procoreWBSCode {
      id
      flat_code
      flat_name
      description
      status
      __typename
    }
    procoreDirectCostId
    procoreDirectCostLineItemId
    agaveProjectId
    agaveProject {
      id
      source_id
      amount
      completion_date
      name
      number
      start_date
      status
      type
      source_create_time
      source_update_time
      __typename
    }
    agaveCostCodeId
    agaveCostCode {
      id
      source_id
      code
      full_code
      name
      parent_id
      project_id
      source_create_time
      source_update_time
      __typename
    }
    agaveCostTypeId
    agaveCostType {
      id
      source_id
      code
      name
      project_id
      source_create_time
      source_update_time
      __typename
    }
    agaveExpenseId
    agaveExpense {
      id
      source_id
      amount
      amount_due
      ap_payment_ids
      batch
      batch_id
      creator_id
      currency_code
      description
      discount_amount
      discount_date
      due_date
      issue_date
      misc_amount
      net_amount
      number
      project_id
      retention_amount
      retention_percent
      status
      subtotal_amount
      tax_amount
      total_amount
      type
      vendor_id
      source_create_time
      source_update_time
      __typename
    }
    agaveVendor {
      id
      code
      email
      fax
      name
      phone
      status
      tax_number
      terms
      type
      website
      __typename
    }
    agaveVendorId
    receiptPath
    receiptId
    receipt {
      id
      paidolId
      authPaidolId
      document
      status
      createdAt
      updatedAt
      __typename
    }
    erpApprovalStatus
    last4
    userId
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    email
    approvedAmountValue
    approvedAmountCurrencyCode
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetHighnoteTransactionQueryVariables,
  APITypes.GetHighnoteTransactionQuery
>;
export const listHighnoteTransactions = /* GraphQL */ `query ListHighnoteTransactions(
  $transactionId: ID
  $filter: ModelHighnoteTransactionFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listHighnoteTransactions(
    transactionId: $transactionId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      transactionId
      paymentCardId
      paidolId
      authPaidolId
      cardGroupId
      status
      transactionDate
      merchantName
      merchantCategoryCode
      merchantCategory
      transactionEvents
      note
      jobCodeId
      procoreCompanyId
      procoreProjectId
      procoreWBSCodeId
      procoreDirectCostId
      procoreDirectCostLineItemId
      agaveProjectId
      agaveCostCodeId
      agaveCostTypeId
      agaveExpenseId
      agaveVendorId
      receiptPath
      receiptId
      erpApprovalStatus
      last4
      userId
      email
      approvedAmountValue
      approvedAmountCurrencyCode
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListHighnoteTransactionsQueryVariables,
  APITypes.ListHighnoteTransactionsQuery
>;
export const listHighnoteTransactionsByPaymentCardId = /* GraphQL */ `query ListHighnoteTransactionsByPaymentCardId(
  $paymentCardId: ID!
  $transactionDate: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelHighnoteTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  listHighnoteTransactionsByPaymentCardId(
    paymentCardId: $paymentCardId
    transactionDate: $transactionDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      transactionId
      paymentCardId
      paidolId
      authPaidolId
      cardGroupId
      status
      transactionDate
      merchantName
      merchantCategoryCode
      merchantCategory
      transactionEvents
      note
      jobCodeId
      procoreCompanyId
      procoreProjectId
      procoreWBSCodeId
      procoreDirectCostId
      procoreDirectCostLineItemId
      agaveProjectId
      agaveCostCodeId
      agaveCostTypeId
      agaveExpenseId
      agaveVendorId
      receiptPath
      receiptId
      erpApprovalStatus
      last4
      userId
      email
      approvedAmountValue
      approvedAmountCurrencyCode
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListHighnoteTransactionsByPaymentCardIdQueryVariables,
  APITypes.ListHighnoteTransactionsByPaymentCardIdQuery
>;
export const listHighnoteTransactionsByPaidolId = /* GraphQL */ `query ListHighnoteTransactionsByPaidolId(
  $paidolId: ID!
  $transactionDate: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelHighnoteTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  listHighnoteTransactionsByPaidolId(
    paidolId: $paidolId
    transactionDate: $transactionDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      transactionId
      paymentCardId
      paidolId
      authPaidolId
      cardGroupId
      status
      transactionDate
      merchantName
      merchantCategoryCode
      merchantCategory
      transactionEvents
      note
      jobCodeId
      procoreCompanyId
      procoreProjectId
      procoreWBSCodeId
      procoreDirectCostId
      procoreDirectCostLineItemId
      agaveProjectId
      agaveCostCodeId
      agaveCostTypeId
      agaveExpenseId
      agaveVendorId
      receiptPath
      receiptId
      erpApprovalStatus
      last4
      userId
      email
      approvedAmountValue
      approvedAmountCurrencyCode
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListHighnoteTransactionsByPaidolIdQueryVariables,
  APITypes.ListHighnoteTransactionsByPaidolIdQuery
>;
export const listHighnoteTransactionsByCardGroupId = /* GraphQL */ `query ListHighnoteTransactionsByCardGroupId(
  $cardGroupId: ID!
  $transactionDate: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelHighnoteTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  listHighnoteTransactionsByCardGroupId(
    cardGroupId: $cardGroupId
    transactionDate: $transactionDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      transactionId
      paymentCardId
      paidolId
      authPaidolId
      cardGroupId
      status
      transactionDate
      merchantName
      merchantCategoryCode
      merchantCategory
      transactionEvents
      note
      jobCodeId
      procoreCompanyId
      procoreProjectId
      procoreWBSCodeId
      procoreDirectCostId
      procoreDirectCostLineItemId
      agaveProjectId
      agaveCostCodeId
      agaveCostTypeId
      agaveExpenseId
      agaveVendorId
      receiptPath
      receiptId
      erpApprovalStatus
      last4
      userId
      email
      approvedAmountValue
      approvedAmountCurrencyCode
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListHighnoteTransactionsByCardGroupIdQueryVariables,
  APITypes.ListHighnoteTransactionsByCardGroupIdQuery
>;
export const highnoteTransactionByReceiptId = /* GraphQL */ `query HighnoteTransactionByReceiptId(
  $receiptId: ID!
  $paidolId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelHighnoteTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  highnoteTransactionByReceiptId(
    receiptId: $receiptId
    paidolId: $paidolId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      transactionId
      paymentCardId
      paidolId
      authPaidolId
      cardGroupId
      status
      transactionDate
      merchantName
      merchantCategoryCode
      merchantCategory
      transactionEvents
      note
      jobCodeId
      procoreCompanyId
      procoreProjectId
      procoreWBSCodeId
      procoreDirectCostId
      procoreDirectCostLineItemId
      agaveProjectId
      agaveCostCodeId
      agaveCostTypeId
      agaveExpenseId
      agaveVendorId
      receiptPath
      receiptId
      erpApprovalStatus
      last4
      userId
      email
      approvedAmountValue
      approvedAmountCurrencyCode
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.HighnoteTransactionByReceiptIdQueryVariables,
  APITypes.HighnoteTransactionByReceiptIdQuery
>;
export const searchHighnoteTransactions = /* GraphQL */ `query SearchHighnoteTransactions(
  $filter: SearchableHighnoteTransactionFilterInput
  $sort: [SearchableHighnoteTransactionSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableHighnoteTransactionAggregationInput]
) {
  searchHighnoteTransactions(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      transactionId
      paymentCardId
      paidolId
      authPaidolId
      cardGroupId
      status
      transactionDate
      merchantName
      merchantCategoryCode
      merchantCategory
      transactionEvents
      note
      jobCodeId
      procoreCompanyId
      procoreProjectId
      procoreWBSCodeId
      procoreDirectCostId
      procoreDirectCostLineItemId
      agaveProjectId
      agaveCostCodeId
      agaveCostTypeId
      agaveExpenseId
      agaveVendorId
      receiptPath
      receiptId
      erpApprovalStatus
      last4
      userId
      email
      approvedAmountValue
      approvedAmountCurrencyCode
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchHighnoteTransactionsQueryVariables,
  APITypes.SearchHighnoteTransactionsQuery
>;
export const getCardGroup = /* GraphQL */ `query GetCardGroup($id: ID!) {
  getCardGroup(id: $id) {
    id
    paidolId
    authPaidolId
    name
    paymentCards {
      nextToken
      __typename
    }
    monthlyBudget {
      value
      currency
      __typename
    }
    isMonthlyBudgetUnlimited
    monthlyBudgetSpend {
      nextToken
      __typename
    }
    transactions {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCardGroupQueryVariables,
  APITypes.GetCardGroupQuery
>;
export const listCardGroups = /* GraphQL */ `query ListCardGroups(
  $id: ID
  $filter: ModelCardGroupFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCardGroups(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      paidolId
      authPaidolId
      name
      isMonthlyBudgetUnlimited
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCardGroupsQueryVariables,
  APITypes.ListCardGroupsQuery
>;
export const listCardGroupsByPaidolId = /* GraphQL */ `query ListCardGroupsByPaidolId(
  $paidolId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCardGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listCardGroupsByPaidolId(
    paidolId: $paidolId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      paidolId
      authPaidolId
      name
      isMonthlyBudgetUnlimited
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCardGroupsByPaidolIdQueryVariables,
  APITypes.ListCardGroupsByPaidolIdQuery
>;
export const getCardGroupBudgetSpendByMonth = /* GraphQL */ `query GetCardGroupBudgetSpendByMonth(
  $cardGroupId: ID!
  $yearAndMonth: String!
) {
  getCardGroupBudgetSpendByMonth(
    cardGroupId: $cardGroupId
    yearAndMonth: $yearAndMonth
  ) {
    cardGroupId
    paidolId
    yearAndMonth
    amount
    transactionCount
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCardGroupBudgetSpendByMonthQueryVariables,
  APITypes.GetCardGroupBudgetSpendByMonthQuery
>;
export const listCardGroupBudgetSpendByMonths = /* GraphQL */ `query ListCardGroupBudgetSpendByMonths(
  $cardGroupId: ID
  $yearAndMonth: ModelStringKeyConditionInput
  $filter: ModelCardGroupBudgetSpendByMonthFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCardGroupBudgetSpendByMonths(
    cardGroupId: $cardGroupId
    yearAndMonth: $yearAndMonth
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      cardGroupId
      paidolId
      yearAndMonth
      amount
      transactionCount
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCardGroupBudgetSpendByMonthsQueryVariables,
  APITypes.ListCardGroupBudgetSpendByMonthsQuery
>;
export const getMerchantSpendRule = /* GraphQL */ `query GetMerchantSpendRule($id: ID!) {
  getMerchantSpendRule(id: $id) {
    id
    paidolId
    authPaidolId
    name
    paymentCards
    merchantCountrySpendRuleId
    merchantCategorySpendRuleId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMerchantSpendRuleQueryVariables,
  APITypes.GetMerchantSpendRuleQuery
>;
export const listMerchantSpendRules = /* GraphQL */ `query ListMerchantSpendRules(
  $id: ID
  $filter: ModelMerchantSpendRuleFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listMerchantSpendRules(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      paidolId
      authPaidolId
      name
      paymentCards
      merchantCountrySpendRuleId
      merchantCategorySpendRuleId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMerchantSpendRulesQueryVariables,
  APITypes.ListMerchantSpendRulesQuery
>;
export const listMerchantSpendRulesByPaidolId = /* GraphQL */ `query ListMerchantSpendRulesByPaidolId(
  $paidolId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMerchantSpendRuleFilterInput
  $limit: Int
  $nextToken: String
) {
  listMerchantSpendRulesByPaidolId(
    paidolId: $paidolId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      paidolId
      authPaidolId
      name
      paymentCards
      merchantCountrySpendRuleId
      merchantCategorySpendRuleId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMerchantSpendRulesByPaidolIdQueryVariables,
  APITypes.ListMerchantSpendRulesByPaidolIdQuery
>;
export const getTransactionsDownloadQueue = /* GraphQL */ `query GetTransactionsDownloadQueue($id: ID!) {
  getTransactionsDownloadQueue(id: $id) {
    id
    user_id
    paidol_id
    range_option
    start_date
    end_date
    columns
    s3_key
    cron_status
    createdAt
    paidolUserId
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTransactionsDownloadQueueQueryVariables,
  APITypes.GetTransactionsDownloadQueueQuery
>;
export const listTransactionsDownloadQueues = /* GraphQL */ `query ListTransactionsDownloadQueues(
  $filter: ModelTransactionsDownloadQueueFilterInput
  $limit: Int
  $nextToken: String
) {
  listTransactionsDownloadQueues(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user_id
      paidol_id
      range_option
      start_date
      end_date
      columns
      s3_key
      cron_status
      createdAt
      paidolUserId
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTransactionsDownloadQueuesQueryVariables,
  APITypes.ListTransactionsDownloadQueuesQuery
>;
export const transactionsDownloadByPaidolId = /* GraphQL */ `query TransactionsDownloadByPaidolId(
  $paidol_id: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTransactionsDownloadQueueFilterInput
  $limit: Int
  $nextToken: String
) {
  transactionsDownloadByPaidolId(
    paidol_id: $paidol_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user_id
      paidol_id
      range_option
      start_date
      end_date
      columns
      s3_key
      cron_status
      createdAt
      paidolUserId
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TransactionsDownloadByPaidolIdQueryVariables,
  APITypes.TransactionsDownloadByPaidolIdQuery
>;
export const getTransactionsReceiptsDownloadQueue = /* GraphQL */ `query GetTransactionsReceiptsDownloadQueue($id: ID!) {
  getTransactionsReceiptsDownloadQueue(id: $id) {
    id
    user_id
    paidol_id
    paidolUserId
    s3_key
    cron_status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTransactionsReceiptsDownloadQueueQueryVariables,
  APITypes.GetTransactionsReceiptsDownloadQueueQuery
>;
export const listTransactionsReceiptsDownloadQueues = /* GraphQL */ `query ListTransactionsReceiptsDownloadQueues(
  $filter: ModelTransactionsReceiptsDownloadQueueFilterInput
  $limit: Int
  $nextToken: String
) {
  listTransactionsReceiptsDownloadQueues(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user_id
      paidol_id
      paidolUserId
      s3_key
      cron_status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTransactionsReceiptsDownloadQueuesQueryVariables,
  APITypes.ListTransactionsReceiptsDownloadQueuesQuery
>;
export const transactionsReceiptsDownloadByPaidolId = /* GraphQL */ `query TransactionsReceiptsDownloadByPaidolId(
  $paidol_id: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTransactionsReceiptsDownloadQueueFilterInput
  $limit: Int
  $nextToken: String
) {
  transactionsReceiptsDownloadByPaidolId(
    paidol_id: $paidol_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user_id
      paidol_id
      paidolUserId
      s3_key
      cron_status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TransactionsReceiptsDownloadByPaidolIdQueryVariables,
  APITypes.TransactionsReceiptsDownloadByPaidolIdQuery
>;
export const getSpendRuleConfig = /* GraphQL */ `query GetSpendRuleConfig($id: ID!) {
  getSpendRuleConfig(id: $id) {
    id
    paidolId
    authPaidolId
    ruleId
    ruleType
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSpendRuleConfigQueryVariables,
  APITypes.GetSpendRuleConfigQuery
>;
export const listSpendRuleConfigs = /* GraphQL */ `query ListSpendRuleConfigs(
  $id: ID
  $filter: ModelSpendRuleConfigFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listSpendRuleConfigs(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      paidolId
      authPaidolId
      ruleId
      ruleType
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSpendRuleConfigsQueryVariables,
  APITypes.ListSpendRuleConfigsQuery
>;
export const listSpendRulesConfigByPaidolId = /* GraphQL */ `query ListSpendRulesConfigByPaidolId(
  $paidolId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelSpendRuleConfigFilterInput
  $limit: Int
  $nextToken: String
) {
  listSpendRulesConfigByPaidolId(
    paidolId: $paidolId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      paidolId
      authPaidolId
      ruleId
      ruleType
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSpendRulesConfigByPaidolIdQueryVariables,
  APITypes.ListSpendRulesConfigByPaidolIdQuery
>;
export const getJobCodeImport = /* GraphQL */ `query GetJobCodeImport($id: ID!) {
  getJobCodeImport(id: $id) {
    id
    paidolId
    authPaidolId
    createdAt
    storageFileKey
    fileName
    status
    errors
    jobCodes {
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetJobCodeImportQueryVariables,
  APITypes.GetJobCodeImportQuery
>;
export const listJobCodeImports = /* GraphQL */ `query ListJobCodeImports(
  $id: ID
  $filter: ModelJobCodeImportFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listJobCodeImports(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      paidolId
      authPaidolId
      createdAt
      storageFileKey
      fileName
      status
      errors
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListJobCodeImportsQueryVariables,
  APITypes.ListJobCodeImportsQuery
>;
export const jobCodeImportByPaidolId = /* GraphQL */ `query JobCodeImportByPaidolId(
  $paidolId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelJobCodeImportFilterInput
  $limit: Int
  $nextToken: String
) {
  jobCodeImportByPaidolId(
    paidolId: $paidolId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      paidolId
      authPaidolId
      createdAt
      storageFileKey
      fileName
      status
      errors
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.JobCodeImportByPaidolIdQueryVariables,
  APITypes.JobCodeImportByPaidolIdQuery
>;
export const getJobCode = /* GraphQL */ `query GetJobCode($id: ID!) {
  getJobCode(id: $id) {
    id
    paidolId
    authPaidolId
    description
    code
    isActive
    budget
    jobCodeImportId
    jobCodeImport {
      id
      paidolId
      authPaidolId
      createdAt
      storageFileKey
      fileName
      status
      errors
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetJobCodeQueryVariables,
  APITypes.GetJobCodeQuery
>;
export const listJobCodes = /* GraphQL */ `query ListJobCodes(
  $id: ID
  $filter: ModelJobCodeFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listJobCodes(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      paidolId
      authPaidolId
      description
      code
      isActive
      budget
      jobCodeImportId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListJobCodesQueryVariables,
  APITypes.ListJobCodesQuery
>;
export const jobCodeByJobCodeImportId = /* GraphQL */ `query JobCodeByJobCodeImportId(
  $jobCodeImportId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelJobCodeFilterInput
  $limit: Int
  $nextToken: String
) {
  jobCodeByJobCodeImportId(
    jobCodeImportId: $jobCodeImportId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      paidolId
      authPaidolId
      description
      code
      isActive
      budget
      jobCodeImportId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.JobCodeByJobCodeImportIdQueryVariables,
  APITypes.JobCodeByJobCodeImportIdQuery
>;
export const getPaidolUserToHighnotePaymentCard = /* GraphQL */ `query GetPaidolUserToHighnotePaymentCard($id: ID!) {
  getPaidolUserToHighnotePaymentCard(id: $id) {
    id
    paidolUserID
    highnotePaymentCardID
    paidolUser {
      id
      paidol_id
      user_id
      email
      position
      phone
      roles
      is_test_fixture
      authorizedUserId
      authorizedUserApplicationId
      allowedJobCodes
      employee_id
      createdAt
      updatedAt
      __typename
    }
    highnotePaymentCard {
      paymentCardId
      paidolId
      authPaidolId
      name
      cardGroupId
      status
      bin
      last4
      network
      formFactor
      expirationDate
      hasPin
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPaidolUserToHighnotePaymentCardQueryVariables,
  APITypes.GetPaidolUserToHighnotePaymentCardQuery
>;
export const listPaidolUserToHighnotePaymentCards = /* GraphQL */ `query ListPaidolUserToHighnotePaymentCards(
  $filter: ModelPaidolUserToHighnotePaymentCardFilterInput
  $limit: Int
  $nextToken: String
) {
  listPaidolUserToHighnotePaymentCards(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      paidolUserID
      highnotePaymentCardID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPaidolUserToHighnotePaymentCardsQueryVariables,
  APITypes.ListPaidolUserToHighnotePaymentCardsQuery
>;
export const getExpenseTypeToMCC = /* GraphQL */ `query GetExpenseTypeToMCC($id: ID!) {
  getExpenseTypeToMCC(id: $id) {
    id
    mccID
    expenseTypeID
    mcc {
      id
      code
      highnoteDescription
      highnoteCategoryDescription
      speedchainDescription
      createdAt
      updatedAt
      __typename
    }
    expenseType {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetExpenseTypeToMCCQueryVariables,
  APITypes.GetExpenseTypeToMCCQuery
>;
export const listExpenseTypeToMCCS = /* GraphQL */ `query ListExpenseTypeToMCCS(
  $filter: ModelExpenseTypeToMCCFilterInput
  $limit: Int
  $nextToken: String
) {
  listExpenseTypeToMCCS(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      mccID
      expenseTypeID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListExpenseTypeToMCCSQueryVariables,
  APITypes.ListExpenseTypeToMCCSQuery
>;
export const userList = /* GraphQL */ `query UserList($user_id: ID!, $paidol_id: ID!) {
  userList(user_id: $user_id, paidol_id: $paidol_id) {
    userId
    nickname
    __typename
  }
}
` as GeneratedQuery<APITypes.UserListQueryVariables, APITypes.UserListQuery>;
export const unreadMessages = /* GraphQL */ `query UnreadMessages($userId: ID!) {
  unreadMessages(userId: $userId) {
    unread_count
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UnreadMessagesQueryVariables,
  APITypes.UnreadMessagesQuery
>;
export const dispatchMessage = /* GraphQL */ `query DispatchMessage($userId: ID!, $message: String!, $paidolId: String!) {
  dispatchMessage(userId: $userId, message: $message, paidolId: $paidolId) {
    channel_url
    message
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DispatchMessageQueryVariables,
  APITypes.DispatchMessageQuery
>;
export const sendEmail = /* GraphQL */ `query SendEmail($to: String!, $subject: String!, $body: String!) {
  sendEmail(to: $to, subject: $subject, body: $body) {
    statusCode
    body
    __typename
  }
}
` as GeneratedQuery<APITypes.SendEmailQueryVariables, APITypes.SendEmailQuery>;
export const listFeatureFlags = /* GraphQL */ `query ListFeatureFlags($email: String!) {
  listFeatureFlags(email: $email) {
    name
    value
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFeatureFlagsQueryVariables,
  APITypes.ListFeatureFlagsQuery
>;
export const hnGetBusinessAccountHolder = /* GraphQL */ `query HnGetBusinessAccountHolder($id: String!) {
  hnGetBusinessAccountHolder(id: $id) {
    id
    primaryAuthorizedPerson {
      id
      percentageOwnership
      authorizingPersonTitle
      email
      dateOfBirth
      createdAt
      updatedAt
      __typename
    }
    businessProfile {
      id
      businessType
      website
      createdAt
      updatedAt
      __typename
    }
    externalId
    createdAt
    updatedAt
    cardProductApplications {
      __typename
    }
    paymentCards {
      __typename
    }
    externalFinancialAccounts {
      __typename
    }
    financialAccounts {
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.HnGetBusinessAccountHolderQueryVariables,
  APITypes.HnGetBusinessAccountHolderQuery
>;
export const hnGetPaymentCardsForBusinessId = /* GraphQL */ `query HnGetPaymentCardsForBusinessId(
  $id: ID!
  $first: Int
  $after: String
  $filterBy: HNAccountHolderPaymentCardsFilterInput
) {
  hnGetPaymentCardsForBusinessId(
    id: $id
    first: $first
    after: $after
    filterBy: $filterBy
  ) {
    id
    primaryAuthorizedPerson {
      id
      percentageOwnership
      authorizingPersonTitle
      email
      dateOfBirth
      createdAt
      updatedAt
      __typename
    }
    businessProfile {
      id
      businessType
      website
      createdAt
      updatedAt
      __typename
    }
    externalId
    createdAt
    updatedAt
    cardProductApplications {
      __typename
    }
    paymentCards {
      __typename
    }
    externalFinancialAccounts {
      __typename
    }
    financialAccounts {
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.HnGetPaymentCardsForBusinessIdQueryVariables,
  APITypes.HnGetPaymentCardsForBusinessIdQuery
>;
export const hnGetDetailsForPaymentCard = /* GraphQL */ `query HnGetDetailsForPaymentCard($id: ID!) {
  hnGetDetailsForPaymentCard(id: $id) {
    id
    bin
    last4
    expirationDate
    expirationMonth
    expirationYear
    network
    cardProduct {
      id
      name
      usage
      vertical
      commercial
      sourceTemplateId
      __typename
    }
    cardProductApplication {
      ... on HNAccountHolderCardProductApplication {
        id
        applicationDenialReason
        createdAt
        updatedAt
      }
      ... on HNAuthorizedUserCardProductApplication {
        id
        applicationDenialReason
        createdAt
        updatedAt
      }
    }
    application {
      id
      applicationDenialReason
      createdAt
      updatedAt
      __typename
    }
    status
    formFactor
    originalPaymentCard {
      id
      bin
      last4
      expirationDate
      expirationMonth
      expirationYear
      network
      status
      formFactor
      __typename
    }
    restrictedDetails {
      ... on HNPaymentCardRestrictedDetails {
        number
        cvv
      }
      ... on HNAccessDeniedError {
        message
      }
    }
    atmLocations {
      ... on HNAccessDeniedError {
        message
      }
    }
    transactionEvents {
      __typename
    }
    financialAccounts {
      id
      externalId
      name
      createdAt
      updatedAt
      accountClosureBlockers
      accountStatus
      accountAttributes
      __typename
    }
    physicalPaymentCardOrders {
      id
      createdAt
      updatedAt
      __typename
    }
    attachedSpendRules {
      __typename
    }
    cardDigitalWalletTokens {
      __typename
    }
    velocityRuleBalances {
      __typename
    }
    velocitySpendRuleBalances {
      ... on HNVelocitySpendRuleAmountBalance {
        attachedLevel
      }
      ... on HNVelocitySpendRuleCountBalance {
        currentCountBalance
        remainingCountBalance
        attachedLevel
      }
    }
    attachedVelocityRules {
      __typename
    }
    paymentCardHolderDetails {
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.HnGetDetailsForPaymentCardQueryVariables,
  APITypes.HnGetDetailsForPaymentCardQuery
>;
export const hnGetAccountHolderCardProductApplication = /* GraphQL */ `query HnGetAccountHolderCardProductApplication($id: ID!) {
  hnGetAccountHolderCardProductApplication(id: $id) {
    id
    applicationState {
      status
      __typename
    }
    adverseActionReasons {
      code
      description
      __typename
    }
    applicationDenialReason
    accountHolderSnapshot {
      ... on HNUSPersonAccountHolderSnapshot {
        email
        dateOfBirth
      }
    }
    cardProduct {
      id
      name
      usage
      vertical
      commercial
      sourceTemplateId
      __typename
    }
    applicationHistory {
      __typename
    }
    createdAt
    updatedAt
    financialAccounts {
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.HnGetAccountHolderCardProductApplicationQueryVariables,
  APITypes.HnGetAccountHolderCardProductApplicationQuery
>;
export const hnGetSpendRuleById = /* GraphQL */ `query HnGetSpendRuleById($ruleId: ID!) {
  hnGetSpendRuleById(ruleId: $ruleId) {
    id
    name
    blockedCountry
    allowedCategory
    blockedCategory
    allowedCountry
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.HnGetSpendRuleByIdQueryVariables,
  APITypes.HnGetSpendRuleByIdQuery
>;
export const hnGetPaymentCardDetails = /* GraphQL */ `query HnGetPaymentCardDetails($id: ID!) {
  hnGetPaymentCardDetails(id: $id) {
    id
    bin
    last4
    expirationDate
    expirationMonth
    expirationYear
    network
    cardProduct {
      id
      name
      usage
      vertical
      commercial
      sourceTemplateId
      __typename
    }
    cardProductApplication {
      ... on HNAccountHolderCardProductApplication {
        id
        applicationDenialReason
        createdAt
        updatedAt
      }
      ... on HNAuthorizedUserCardProductApplication {
        id
        applicationDenialReason
        createdAt
        updatedAt
      }
    }
    application {
      id
      applicationDenialReason
      createdAt
      updatedAt
      __typename
    }
    status
    formFactor
    originalPaymentCard {
      id
      bin
      last4
      expirationDate
      expirationMonth
      expirationYear
      network
      status
      formFactor
      __typename
    }
    restrictedDetails {
      ... on HNPaymentCardRestrictedDetails {
        number
        cvv
      }
      ... on HNAccessDeniedError {
        message
      }
    }
    atmLocations {
      ... on HNAccessDeniedError {
        message
      }
    }
    transactionEvents {
      __typename
    }
    financialAccounts {
      id
      externalId
      name
      createdAt
      updatedAt
      accountClosureBlockers
      accountStatus
      accountAttributes
      __typename
    }
    physicalPaymentCardOrders {
      id
      createdAt
      updatedAt
      __typename
    }
    attachedSpendRules {
      __typename
    }
    cardDigitalWalletTokens {
      __typename
    }
    velocityRuleBalances {
      __typename
    }
    velocitySpendRuleBalances {
      ... on HNVelocitySpendRuleAmountBalance {
        attachedLevel
      }
      ... on HNVelocitySpendRuleCountBalance {
        currentCountBalance
        remainingCountBalance
        attachedLevel
      }
    }
    attachedVelocityRules {
      __typename
    }
    paymentCardHolderDetails {
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.HnGetPaymentCardDetailsQueryVariables,
  APITypes.HnGetPaymentCardDetailsQuery
>;
export const hnViewDirectDepositDetails = /* GraphQL */ `query HnViewDirectDepositDetails($id: ID!) {
  hnViewDirectDepositDetails(id: $id) {
    id
    restrictedDetails {
      ... on HNDirectDepositDetailRestrictedDetails {
        number
        routingNumber
        type
      }
      ... on HNAccessDeniedError {
        message
      }
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.HnViewDirectDepositDetailsQueryVariables,
  APITypes.HnViewDirectDepositDetailsQuery
>;
export const hnFindFinancialAccountTransfers = /* GraphQL */ `query HnFindFinancialAccountTransfers(
  $id: ID!
  $firstScheduledTransfers: Int
  $scheduledTransfersAfterCursor: String
  $firstIntegratorTransfers: Int
  $integratorTransfersAfterCursor: String
  $firstExternalTransfers: Int
  $externalTransfersAfterCursor: String
) {
  hnFindFinancialAccountTransfers(
    id: $id
    firstScheduledTransfers: $firstScheduledTransfers
    scheduledTransfersAfterCursor: $scheduledTransfersAfterCursor
    firstIntegratorTransfers: $firstIntegratorTransfers
    integratorTransfersAfterCursor: $integratorTransfersAfterCursor
    firstExternalTransfers: $firstExternalTransfers
    externalTransfersAfterCursor: $externalTransfersAfterCursor
  ) {
    id
    externalId
    ledgers {
      id
      name
      description
      normalBalance
      asOf
      __typename
    }
    owner {
      ... on HNOrganization {
        id
      }
      ... on HNPaymentCard {
        id
        bin
        last4
        expirationDate
        expirationMonth
        expirationYear
        network
        status
        formFactor
      }
      ... on HNUSPersonAccountHolder {
        id
        email
        dateOfBirth
        externalId
        createdAt
        updatedAt
      }
      ... on HNUSBusinessAccountHolder {
        id
        externalId
        createdAt
        updatedAt
      }
    }
    name
    cardProduct {
      id
      name
      usage
      vertical
      commercial
      sourceTemplateId
      __typename
    }
    application {
      id
      applicationDenialReason
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    features {
      enabled
      createdAt
      updatedAt

      ... on HNOnDemandFundingFinancialAccountFeature {
        sourceFinancialAccountId
        pseudoBalanceEnabled
      }
    }
    directDepositDetails {
      id
      createdAt
      updatedAt
      __typename
    }
    activeBillingCycleConfiguration {
      id
      paymentDueDayOfMonth
      billingCycleStartDayOfMonth
      billingCycleEndDayOfMonth
      from
      through
      __typename
    }
    statementSnapshot {
      asOf
      updatedAt
    }
    statements {
      __typename
    }
    financialAccountActivities {
      __typename
    }
    integratorInitiatedAchTransfers {
      __typename
    }
    integratorInitiatedACHTransfer {
      __typename
    }
    integratorACHTransfers {
      __typename
    }
    externallyInitiatedAchTransfers {
      __typename
    }
    externallyInitiatedACHTransfer {
      __typename
    }
    externalACHTransfers {
      __typename
    }
    incomingScheduledTransfers {
      __typename
    }
    transactionEvents {
      __typename
    }
    interFinancialAccountTransfers {
      __typename
    }
    accountClosureBlockers
    accountStatus
    accountAttributes
    paymentCards {
      __typename
    }
    velocityRuleBalances {
      __typename
    }
    velocitySpendRuleBalances {
      ... on HNVelocitySpendRuleAmountBalance {
        attachedLevel
      }
      ... on HNVelocitySpendRuleCountBalance {
        currentCountBalance
        remainingCountBalance
        attachedLevel
      }
    }
    delinquency {
      delinquencyStartedOn
      totalDaysDelinquent
      numberOfCycles
    }
    delinquencyState {
      state
      updatedAt
      __typename
    }
    billingCycleHistory {
      __typename
    }
    feeTransferEvents {
      __typename
    }
    paymentCardTransactionDisputes {
      ... on HNAccessDeniedError {
        message
      }
    }
    attachedSpendRules {
      __typename
    }
    attachedVelocityRules {
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.HnFindFinancialAccountTransfersQueryVariables,
  APITypes.HnFindFinancialAccountTransfersQuery
>;
export const hnFindFinancialAccountLedgerActivity = /* GraphQL */ `query HnFindFinancialAccountLedgerActivity(
  $id: ID!
  $firstActivities: Int
  $activitiesAfterCursor: String
) {
  hnFindFinancialAccountLedgerActivity(
    id: $id
    firstActivities: $firstActivities
    activitiesAfterCursor: $activitiesAfterCursor
  ) {
    id
    externalId
    ledgers {
      id
      name
      description
      normalBalance
      asOf
      __typename
    }
    owner {
      ... on HNOrganization {
        id
      }
      ... on HNPaymentCard {
        id
        bin
        last4
        expirationDate
        expirationMonth
        expirationYear
        network
        status
        formFactor
      }
      ... on HNUSPersonAccountHolder {
        id
        email
        dateOfBirth
        externalId
        createdAt
        updatedAt
      }
      ... on HNUSBusinessAccountHolder {
        id
        externalId
        createdAt
        updatedAt
      }
    }
    name
    cardProduct {
      id
      name
      usage
      vertical
      commercial
      sourceTemplateId
      __typename
    }
    application {
      id
      applicationDenialReason
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    features {
      enabled
      createdAt
      updatedAt

      ... on HNOnDemandFundingFinancialAccountFeature {
        sourceFinancialAccountId
        pseudoBalanceEnabled
      }
    }
    directDepositDetails {
      id
      createdAt
      updatedAt
      __typename
    }
    activeBillingCycleConfiguration {
      id
      paymentDueDayOfMonth
      billingCycleStartDayOfMonth
      billingCycleEndDayOfMonth
      from
      through
      __typename
    }
    statementSnapshot {
      asOf
      updatedAt
    }
    statements {
      __typename
    }
    financialAccountActivities {
      __typename
    }
    integratorInitiatedAchTransfers {
      __typename
    }
    integratorInitiatedACHTransfer {
      __typename
    }
    integratorACHTransfers {
      __typename
    }
    externallyInitiatedAchTransfers {
      __typename
    }
    externallyInitiatedACHTransfer {
      __typename
    }
    externalACHTransfers {
      __typename
    }
    incomingScheduledTransfers {
      __typename
    }
    transactionEvents {
      __typename
    }
    interFinancialAccountTransfers {
      __typename
    }
    accountClosureBlockers
    accountStatus
    accountAttributes
    paymentCards {
      __typename
    }
    velocityRuleBalances {
      __typename
    }
    velocitySpendRuleBalances {
      ... on HNVelocitySpendRuleAmountBalance {
        attachedLevel
      }
      ... on HNVelocitySpendRuleCountBalance {
        currentCountBalance
        remainingCountBalance
        attachedLevel
      }
    }
    delinquency {
      delinquencyStartedOn
      totalDaysDelinquent
      numberOfCycles
    }
    delinquencyState {
      state
      updatedAt
      __typename
    }
    billingCycleHistory {
      __typename
    }
    feeTransferEvents {
      __typename
    }
    paymentCardTransactionDisputes {
      ... on HNAccessDeniedError {
        message
      }
    }
    attachedSpendRules {
      __typename
    }
    attachedVelocityRules {
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.HnFindFinancialAccountLedgerActivityQueryVariables,
  APITypes.HnFindFinancialAccountLedgerActivityQuery
>;
export const hnFindFinancialAccountActivities = /* GraphQL */ `query HnFindFinancialAccountActivities(
  $id: ID!
  $firstActivities: Int
  $filterBy: HNFinancialAccountActivitiesFilterInput
  $activitiesAfterCursor: String
) {
  hnFindFinancialAccountActivities(
    id: $id
    firstActivities: $firstActivities
    filterBy: $filterBy
    activitiesAfterCursor: $activitiesAfterCursor
  ) {
    id
    externalId
    ledgers {
      id
      name
      description
      normalBalance
      asOf
      __typename
    }
    owner {
      ... on HNOrganization {
        id
      }
      ... on HNPaymentCard {
        id
        bin
        last4
        expirationDate
        expirationMonth
        expirationYear
        network
        status
        formFactor
      }
      ... on HNUSPersonAccountHolder {
        id
        email
        dateOfBirth
        externalId
        createdAt
        updatedAt
      }
      ... on HNUSBusinessAccountHolder {
        id
        externalId
        createdAt
        updatedAt
      }
    }
    name
    cardProduct {
      id
      name
      usage
      vertical
      commercial
      sourceTemplateId
      __typename
    }
    application {
      id
      applicationDenialReason
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    features {
      enabled
      createdAt
      updatedAt

      ... on HNOnDemandFundingFinancialAccountFeature {
        sourceFinancialAccountId
        pseudoBalanceEnabled
      }
    }
    directDepositDetails {
      id
      createdAt
      updatedAt
      __typename
    }
    activeBillingCycleConfiguration {
      id
      paymentDueDayOfMonth
      billingCycleStartDayOfMonth
      billingCycleEndDayOfMonth
      from
      through
      __typename
    }
    statementSnapshot {
      asOf
      updatedAt
    }
    statements {
      __typename
    }
    financialAccountActivities {
      __typename
    }
    integratorInitiatedAchTransfers {
      __typename
    }
    integratorInitiatedACHTransfer {
      __typename
    }
    integratorACHTransfers {
      __typename
    }
    externallyInitiatedAchTransfers {
      __typename
    }
    externallyInitiatedACHTransfer {
      __typename
    }
    externalACHTransfers {
      __typename
    }
    incomingScheduledTransfers {
      __typename
    }
    transactionEvents {
      __typename
    }
    interFinancialAccountTransfers {
      __typename
    }
    accountClosureBlockers
    accountStatus
    accountAttributes
    paymentCards {
      __typename
    }
    velocityRuleBalances {
      __typename
    }
    velocitySpendRuleBalances {
      ... on HNVelocitySpendRuleAmountBalance {
        attachedLevel
      }
      ... on HNVelocitySpendRuleCountBalance {
        currentCountBalance
        remainingCountBalance
        attachedLevel
      }
    }
    delinquency {
      delinquencyStartedOn
      totalDaysDelinquent
      numberOfCycles
    }
    delinquencyState {
      state
      updatedAt
      __typename
    }
    billingCycleHistory {
      __typename
    }
    feeTransferEvents {
      __typename
    }
    paymentCardTransactionDisputes {
      ... on HNAccessDeniedError {
        message
      }
    }
    attachedSpendRules {
      __typename
    }
    attachedVelocityRules {
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.HnFindFinancialAccountActivitiesQueryVariables,
  APITypes.HnFindFinancialAccountActivitiesQuery
>;
export const getCards = /* GraphQL */ `query GetCards($input: GetCardsInput!) {
  getCards(input: $input) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
      __typename
    }
    items {
      cursor
      id
      paidolId
      formFactor
      name
      last4
      expirationDate
      status
      requireAddress
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetCardsQueryVariables, APITypes.GetCardsQuery>;
export const getCardByHighnoteId = /* GraphQL */ `query GetCardByHighnoteId($input: GetCardByHighnoteIdInput!) {
  getCardByHighnoteId(input: $input) {
    cursor
    id
    paidolId
    formFactor
    name
    last4
    procoreEmployee {
      id
      name
      email_address
      __typename
    }
    expirationDate
    status
    cardholder {
      id
      name
      email
      picture
      __typename
    }
    cardGroup {
      id
      name
      __typename
    }
    requireAddress
    spxSpendRule {
      id
      name
      merchantCountrySpendRuleId
      merchantCategorySpendRuleId
      paymentCards
      __typename
    }
    monthlySpendLimit {
      id
      name
      amount
      currency
      cumulativeRuleId
      __typename
    }
    maximumTransactionAmount {
      id
      amount
      currency
      __typename
    }
    spent {
      amount
      currency
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCardByHighnoteIdQueryVariables,
  APITypes.GetCardByHighnoteIdQuery
>;
export const validateEmailAndUsername = /* GraphQL */ `query ValidateEmailAndUsername($input: ValidateEmailAndUsernameInput!) {
  validateEmailAndUsername(input: $input)
}
` as GeneratedQuery<
  APITypes.ValidateEmailAndUsernameQueryVariables,
  APITypes.ValidateEmailAndUsernameQuery
>;
export const getJobCodes = /* GraphQL */ `query GetJobCodes($input: GetJobCodesInput!) {
  getJobCodes(input: $input) {
    jobCodeImport {
      id
      paidolId
      authPaidolId
      createdAt
      storageFileKey
      fileName
      status
      errors
      updatedAt
      __typename
    }
    paidolUsersMap {
      code
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetJobCodesQueryVariables,
  APITypes.GetJobCodesQuery
>;
export const getFinicityConnectUrl = /* GraphQL */ `query GetFinicityConnectUrl($input: GetFinicityConnectUrlInput!) {
  getFinicityConnectUrl(input: $input) {
    link
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFinicityConnectUrlQueryVariables,
  APITypes.GetFinicityConnectUrlQuery
>;
export const getFinicityCustomerAccounts = /* GraphQL */ `query GetFinicityCustomerAccounts($input: GetFinicityCustomerAccountsInput!) {
  getFinicityCustomerAccounts(input: $input) {
    id
    accountNumberDisplay
    name
    type
    balance
    status
    institutionId
    institution {
      name
      urlHomeApp
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFinicityCustomerAccountsQueryVariables,
  APITypes.GetFinicityCustomerAccountsQuery
>;
export const getDwollaWalletBalance = /* GraphQL */ `query GetDwollaWalletBalance(
  $input: GetDwollaBusinessCustomerWalletBalanceInput!
) {
  getDwollaWalletBalance(input: $input) {
    value
    currency
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDwollaWalletBalanceQueryVariables,
  APITypes.GetDwollaWalletBalanceQuery
>;
export const getProcoreCompanies = /* GraphQL */ `query GetProcoreCompanies($input: GetProcoreCompaniesInput!) {
  getProcoreCompanies(input: $input) {
    id
    name
    is_active
    logo_url
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProcoreCompaniesQueryVariables,
  APITypes.GetProcoreCompaniesQuery
>;
export const getProcoreProjects = /* GraphQL */ `query GetProcoreProjects($input: GetProcoreProjectsInput!) {
  getProcoreProjects(input: $input) {
    id
    active
    display_name
    name
    created_at
    start_date
    completion_date
    project_number
    project_stage {
      id
      name
      __typename
    }
    project_template {
      id
      name
      __typename
    }
    total_value
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProcoreProjectsQueryVariables,
  APITypes.GetProcoreProjectsQuery
>;
export const getProcoreProjectUsers = /* GraphQL */ `query GetProcoreProjectUsers($input: GetProcoreProjectUsersInput!) {
  getProcoreProjectUsers(input: $input) {
    id
    name
    email_address
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProcoreProjectUsersQueryVariables,
  APITypes.GetProcoreProjectUsersQuery
>;
export const getProcoreWBSCodes = /* GraphQL */ `query GetProcoreWBSCodes($input: GetProcoreWBSCodesInput!) {
  getProcoreWBSCodes(input: $input) {
    id
    flat_code
    flat_name
    description
    status
    segment_items {
      id
      code
      name
      status
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProcoreWBSCodesQueryVariables,
  APITypes.GetProcoreWBSCodesQuery
>;
export const getHNTransactionsFilterOptions = /* GraphQL */ `query GetHNTransactionsFilterOptions(
  $input: GetHNTransactionsFilterOptionsInput!
) {
  getHNTransactionsFilterOptions(input: $input) {
    merchantName {
      id
      label
      __typename
    }
    merchantCategory {
      id
      label
      __typename
    }
    cardGroup {
      id
      label
      __typename
    }
    last4
    userId {
      id
      label
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetHNTransactionsFilterOptionsQueryVariables,
  APITypes.GetHNTransactionsFilterOptionsQuery
>;
export const getAgaveProjects = /* GraphQL */ `query GetAgaveProjects($input: AgaveProjectsInput!) {
  getAgaveProjects(input: $input) {
    id
    source_id
    address {
      street_1
      street_2
      city
      state
      postal_code
      country
      __typename
    }
    amount
    completion_date
    name
    number
    start_date
    status
    type
    source_create_time
    source_update_time
    source_data {
      CreationDate
      DeliveryAddressLine
      DeliveryCity
      DeliveryCountry
      DeliveryPostcode
      DeliveryState
      FaxNumber
      LogoPath
      ProjectOwnerOrganizationId
      ProjectOwnerOrganizationName
      PhoneNumber
      PostalAddressLine
      PostalCity
      PostalCountry
      PostalPostcode
      PostalState
      ProjectAddressLine
      ProjectCity
      ProjectCode
      ProjectCountry
      ProjectDescription
      ProjectId
      ProjectName
      ProjectPostcode
      ProjectShortName
      ProjectState
      ProjectType
      ProjectValue
      ProjectResponsibilities
      StartDate
      StopDate
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAgaveProjectsQueryVariables,
  APITypes.GetAgaveProjectsQuery
>;
export const getAgaveJobs = /* GraphQL */ `query GetAgaveJobs($input: AgaveCostCodesInput!) {
  getAgaveJobs(input: $input) {
    id
    source_id
    code
    full_code
    name
    parent_id
    project_id
    source_create_time
    source_update_time
    source_data {
      path
      content_type
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAgaveJobsQueryVariables,
  APITypes.GetAgaveJobsQuery
>;
export const getAgaveEmployees = /* GraphQL */ `query GetAgaveEmployees($input: AgaveEmployeesInput!) {
  getAgaveEmployees(input: $input) {
    id
    source_id
    address {
      city
      country
      postal_code
      state
      street_1
      street_2
      type
      __typename
    }
    company
    email
    first_name
    full_name
    gender
    hire_date
    industry
    job_title
    last_name
    mobile_phone
    phone
    source_create_time
    source_update_time
    status
    termination_date
    type
    source_data {
      path
      content_type
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAgaveEmployeesQueryVariables,
  APITypes.GetAgaveEmployeesQuery
>;
export const getAgaveCostTypes = /* GraphQL */ `query GetAgaveCostTypes($input: AgaveCostTypesInput!) {
  getAgaveCostTypes(input: $input) {
    id
    source_id
    code
    name
    project_id
    source_create_time
    source_update_time
    source_data {
      path
      content_type
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAgaveCostTypesQueryVariables,
  APITypes.GetAgaveCostTypesQuery
>;
export const getAgaveVendorByName = /* GraphQL */ `query GetAgaveVendorByName($input: GetAgaveVendorInput!) {
  getAgaveVendorByName(input: $input) {
    id
    address {
      street_1
      street_2
      city
      state
      country
      postal_code
      __typename
    }
    code
    email
    fax
    name
    phone
    status
    tax_number
    terms
    type
    website
    source_data {
      V1099YN
      V1099Type
      V1099Box
      EFT
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAgaveVendorByNameQueryVariables,
  APITypes.GetAgaveVendorByNameQuery
>;
export const getAgaveAccount = /* GraphQL */ `query GetAgaveAccount($input: AgaveAccountInput!) {
  getAgaveAccount(input: $input) {
    account_id
    connection {
      type
      __typename
    }
    created_at
    linked_company {
      id
      source_id
      name
      __typename
    }
    linked_user {
      id
      source_id
      name
      email
      __typename
    }
    reference_id
    source_system {
      name
      slug
      __typename
    }
    source_system_environment {
      name
      slug
      __typename
    }
    status
    supports_cross_account_uuid
    supports_cross_company_requests
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAgaveAccountQueryVariables,
  APITypes.GetAgaveAccountQuery
>;
