import { Box, Button, Typography } from '@mui/material';
import AddUserForm from 'app/pages/team/users/AddUserForm';
import ContainerComponent from 'app/shared-components/container-component/container-component';
import { useTranslation } from 'react-i18next';

interface AddTeamMembersFormProps {
  handleNext: () => void;
}

function AddTeamMembersForm({ handleNext }: Readonly<AddTeamMembersFormProps>) {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <ContainerComponent>
        <Typography variant="body1" mt={4}>
          {t('addTeamMembersDescription')}
        </Typography>
        <img
          src={`${process.env.PUBLIC_URL}/assets/illustrations/humans/human-team-members.svg`}
          alt={t('illustration.humanTeamMembers')}
        />
      </ContainerComponent>
      <Box
        sx={{
          marginTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <AddUserForm
          renderButtons={({ isLoading, isValid, addPaidolUser }) => (
            <>
              <Button onClick={() => handleNext()} size="small">
                {t('illDoThisLater')}
              </Button>
              <Button variant="outlined" size="small" disabled={isLoading}>
                {t('inviteTeamMember')}
              </Button>
              <Button variant="contained" size="small" disabled={isLoading} onClick={addPaidolUser}>
                {t('addAnother')}
              </Button>
            </>
          )}
        />
      </Box>
    </Box>
  );
}

export default AddTeamMembersForm;
