import { Menu, MenuOpen } from '@mui/icons-material';
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import CompanySwitcher from 'app/shared-components/layout/CompanySwitcher';
import BrowserView from 'app/shared-components/util/BrowserView';
import MobileView from 'app/shared-components/util/MobileView';
import { useAppDispatch } from 'app/store';
import { toggleDrawer, useNavigationState } from 'app/store/navigationSlice';
import theme from 'app/theme';
import { ReactComponent as SpeedchainLogo } from 'assets/White-SpeedChain-Logo.svg';
import SpeedchatButton from './app-header/SpeedchatButton';
import UserMenu from './app-header/UserMenu';

function AppHeader(): JSX.Element {
  const dispatch = useAppDispatch();
  const { drawerOpen } = useNavigationState();

  return (
    <AppBar
      position="static"
      sx={{
        background: `linear-gradient(to right, ${theme.palette.primary.darker} 0%, ${theme.palette.primary.main} 100%)`,
      }}
    >
      <Toolbar disableGutters sx={{ px: { xs: 1, md: 2 } }}>
        <IconButton color="inherit" aria-label="menu" sx={{ mr: 4 }} onClick={() => dispatch(toggleDrawer())}>
          {drawerOpen ? <MenuOpen /> : <Menu />}
        </IconButton>
        <Box sx={{ mr: 1, flexGrow: 1, display: 'flex' }}>
          <SpeedchainLogo />
        </Box>
        <BrowserView>
          <CompanySwitcher sx={{ mr: 2, minWidth: 256 }} />

          <UserMenu />
        </BrowserView>
        <MobileView>
          <SpeedchatButton />
        </MobileView>
      </Toolbar>
    </AppBar>
  );
}

export default AppHeader;
