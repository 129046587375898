import { MobileStepper } from '@mui/material';
import { Box } from '@mui/system';
import { FundingFormValues } from 'app/pages/review/FundingForm';
import Permission from 'app/pages/review/Permission';
import StepOne from 'app/pages/review/StepOne';
import StepTwo from 'app/pages/review/StepTwo';
import {
  initiateFundsDepositTransfer,
  selectReviewOnboardSlice,
  setIsOnboardedToPcards,
  updatePaidolHighnoteIntegration,
} from 'app/pages/store/reviewOnboardSlice';
import { useAuth } from 'app/shared-components/auth/AuthProvider';
import Loading from 'app/shared-components/util/Loading';
import { useAppDispatch, useAppSelector } from 'app/store';
import { selectUserCompanies } from 'app/store/userCompaniesSlice';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { Route, Routes, useNavigate } from 'react-router-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';
import AdditionalDocuments from './AdditionalDocuments';
import { useSnackbar } from 'notistack';
import { HighnoteProductType, HNExternalFinancialBankAccount, HNUserError } from 'API';
import { selectFundingSlice } from 'app/pages/store/fundingSlice';

function ReviewOnboard(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { selectedPaidol } = useAppSelector(selectUserCompanies);
  const { fundingBankAccount } = useAppSelector(selectFundingSlice);
  const [hasConnectedFundingBankAccount, setHasConnectedFundingBankAccount] = useState(false);
  const { integration, application, hasSubmittedApplication, hasExternalAccountId, isOnboardedToPcards } =
    useAppSelector(selectReviewOnboardSlice);
  const [step, setStep] = useState(0);
  const [waitForNextStep, setWaitForNextStep] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const [showProgressDialog, setShowProgressDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const isDebitProduct = useMemo(() => {
    return integration?.highnoteProduct?.type === HighnoteProductType.DEBIT;
  }, [integration]);

  useEffect(() => {
    setWaitForNextStep(false);
  }, [selectedPaidol]);

  const afterTryingToConnectFundingBankAccount = useCallback(() => {
    if (!hasConnectedFundingBankAccount) {
      setIsWorking(true);

      const paidolId = selectedPaidol?.id;
      const businessAccountHolderId = integration?.businessAccountHolderId;
      const userId = user?.sub;

      if (!businessAccountHolderId || !userId || !paidolId) {
        throw new Error('Missing required IDs: HN business account holder ID, user ID, or Paidol ID');
      }

      const errors = (fundingBankAccount as HNUserError).errors;
      if (errors && errors.length > 0) {
        const errorDescription = errors[0].description
          ?.split('.')
          .map((sentence) => {
            const trimmedSentence = sentence.trim();
            return (
              trimmedSentence.charAt(0).toUpperCase() +
              trimmedSentence.slice(1) +
              (trimmedSentence.slice(-1) === '.' ? '' : '. ')
            );
          })
          .join('');

        enqueueSnackbar(`Error adding bank account. ${errorDescription}`, {
          variant: 'error',
          autoHideDuration: 10000,
        });
        setIsWorking(false);
        return;
      }

      if (!integration.hasInitialFunding && isDebitProduct) {
        setShowProgressDialog(true);
        setHasConnectedFundingBankAccount(true);
        setIsWorking(false);
        return;
      }

      if ((fundingBankAccount as HNExternalFinancialBankAccount).id) {
        setWaitForNextStep(false);
      }
      setHasConnectedFundingBankAccount(true);
      setIsWorking(false);
    }
  }, [
    enqueueSnackbar,
    fundingBankAccount,
    hasConnectedFundingBankAccount,
    integration?.businessAccountHolderId,
    integration?.hasInitialFunding,
    isDebitProduct,
    selectedPaidol?.id,
    user?.sub,
  ]);

  useEffect(() => {
    if (fundingBankAccount && integration) {
      afterTryingToConnectFundingBankAccount();
    }
  }, [afterTryingToConnectFundingBankAccount, fundingBankAccount, integration, isOnboardedToPcards]);

  useDeepCompareEffect(() => {
    if (!waitForNextStep) {
      if (isOnboardedToPcards) {
        navigate('/overview', { replace: true });
      } else if (integration) {
        if (!integration.financialAccountId) {
          if (application?.needDocuments) {
            navigate('/review/additionalDocuments', { replace: true });
          } else {
            setStep(0);
            navigate('/review/permission', { replace: true });
          }
        } else if (!hasExternalAccountId) {
          setStep(1);
          setWaitForNextStep(true);
          navigate('/review/connect', { replace: true });
        } else if (!integration.hasInitialFunding && isDebitProduct) {
          setStep(2);
          setWaitForNextStep(true);
          navigate('/review/fund', { replace: true });
        }
      }
    }
  }, [
    hasExternalAccountId,
    hasSubmittedApplication,
    integration,
    application,
    isOnboardedToPcards,
    navigate,
    waitForNextStep,
    isDebitProduct,
  ]);

  const onDismissConnectDialog = () => {
    setShowProgressDialog(false);

    if (!isDebitProduct) {
      dispatch(setIsOnboardedToPcards(true));
    }
    setWaitForNextStep(false);
  };

  const onSubmitTransferAmounts: SubmitHandler<FundingFormValues> = (data) => {
    if (user && integration && selectedPaidol) {
      setIsWorking(true);

      Promise.all([
        dispatch(
          initiateFundsDepositTransfer({
            user,
            integration,
            value: data.initialAmount,
          })
        ),
      ])
        .then(() => {
          return dispatch(
            updatePaidolHighnoteIntegration({
              input: {
                id: selectedPaidol.id,
                hasInitialFunding: true,
              },
            })
          );
        })
        .then(() => {
          setIsWorking(false);
        });
    }
  };

  const onOnboardingCompletion = () => {
    dispatch(setIsOnboardedToPcards(true));
    setWaitForNextStep(false);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        padding: 4,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Routes>
          <Route index element={<Loading />} />
          <Route path="permission" element={<Permission />} />
          <Route path="additionalDocuments" element={<AdditionalDocuments />} />
          <Route
            path="connect"
            element={
              <StepOne
                isWorking={isWorking}
                showProgressDialog={showProgressDialog}
                onDismissProgressDialog={onDismissConnectDialog}
              />
            }
          />
          <Route
            path="fund"
            element={
              <StepTwo
                onSubmit={onSubmitTransferAmounts}
                isWorking={isWorking}
                onComplete={onOnboardingCompletion}
              />
            }
          />
        </Routes>
      </Box>
      <MobileStepper
        sx={{ alignSelf: 'center' }}
        variant="dots"
        position="static"
        steps={isDebitProduct ? 3 : 2}
        activeStep={step}
        backButton={undefined}
        nextButton={undefined}
      />
    </Box>
  );
}

export default ReviewOnboard;
