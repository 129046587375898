/* eslint-disable prettier/prettier */
import { useState } from 'react';
import { OnboardSteps } from './stepper-utils/models';
import { OnboardStepsWithPhrases } from './stepper-utils/constants';
import { Box, Step, StepLabel, Stepper, Typography, styled } from '@mui/material';
import ConnectAccounting from 'app/pages/first-run/stepper/ConnectToAccountingSystemForm';
import AddTeamMembersForm from './AddTeamMembersForm';
import CreateSpendRule from './CreateSpendRuleForm';
import ConnectBankForm from './ConnectBankForm';
import IssueCardForm from './IssueCardForm';
import Page from 'app/shared-components/layout/Page';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const OnboardReviewNew = () => {
  const [activeStep, setActiveStep] = useState<number>(0);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleLastStep = () => {
    navigate('/overview');
  };

  const StyledStepperBox = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
  }));

  return (
    <Page id={'onboard-review'}>
      <StyledStepperBox>
        <Typography sx={{ textAlign: 'center', mb: 4 }} variant="h2">
          {t(OnboardStepsWithPhrases[activeStep].phrase)}
        </Typography>
        <Box className="max-w-lg mb-0 MuiBox-root css-1d5n5z6 md:w-512" sx={{ pb: 3 }}>
          <Stepper activeStep={activeStep}>
            {OnboardStepsWithPhrases.map((step) => (
              <Step key={step.id}>
                <StepLabel
                  sx={{
                    p: 0,
                    '& .MuiStepLabel-iconContainer': { p: 0 },
                    '& .MuiStepIcon-root': {
                      fontSize: '3rem',
                    },
                  }}
                ></StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        <>
          {activeStep === OnboardSteps.Accounting && <ConnectAccounting handleNext={() => handleNext()} />}
          {activeStep === OnboardSteps.TeamMembers && <AddTeamMembersForm handleNext={() => handleNext()} />}
          {activeStep === OnboardSteps.SpendRules && <CreateSpendRule handleNext={() => handleNext()} />}
          {activeStep === OnboardSteps.IssueCards && <IssueCardForm handleNext={() => handleNext()} />}
          {activeStep === OnboardSteps.ConnectBank && <ConnectBankForm handleNext={() => handleLastStep()} />}
        </>
      </StyledStepperBox>
    </Page>
  );
};

export default OnboardReviewNew;
