export const paidolUserByUserId = /* GraphQL */ `
  query PaidolUserByUserId(
    $user_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPaidolUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    paidolUserByUserId(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        paidol_id
        paidol {
          id
          name
          company_address {
            address1
            city
            state
            postal_code
          }
          ein
          business_owner_ssn
          dwolla_business_industry_classification
          enablePayables
          enablePCards
          low_balance_alert_threshold
          low_balance_auto_funding_amount
          low_balance_auto_funding_threshold
          isConstructionType
          agaveAccountToken
          isAgaveClient
          reimbursementSettings {
            isEnabled
            isCheckrunEnabled
            rhythm
            day
            weekday
            ordinal
          }
          jobCodeSettings {
            isEnabled
          }
        }
        user {
          first_name
          last_name
          email
          username
          address
          birthdate
          ssn
          dwollaCustomerId
          finicityCustomerId
        }
        user_id
        email
        position
        phone
        roles
        is_test_fixture
        paymentCards(limit: 100) {
          items {
            highnotePaymentCardID
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
