import { Box, Drawer, PaperProps } from '@mui/material';

export interface SideSheetProps {
  open: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
  width?: number | string;
  PaperProps?: Partial<PaperProps<'div'>>;
}

function SideSheet({ open = false, onClose, children, width = '75vw', PaperProps }: SideSheetProps) {
  return (
    <Drawer anchor="right" open={open} onClose={onClose} PaperProps={PaperProps}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: { xs: '100vw', md: width },
          height: '100vh',
        }}
      >
        {children}
      </Box>
    </Drawer>
  );
}

export default SideSheet;
